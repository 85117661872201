import { FlowStepActions } from "./FlowStepActions";
import type { FlowStepProps } from "./types";
import { Loader } from "../surface/Loader";
import React from "react";
import { Title1 } from "../typography";

interface InnerFlowStepProps extends FlowStepProps {
    children: React.ReactElement;
}

export const FlowStep = (props: Readonly<InnerFlowStepProps>) => {
    const { actionsProps, loading, title, children, description, onNavigate } = props;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 390,
                maxHeight: "100%",
                width: "100%",
            }}
        >
            {title && (<Title1 style={{ marginBottom: 4 }}>{title}</Title1>)}
            {description && (<p style={{ marginTop: 0 }}>{description}</p>)}
            <Loader loading={loading}>
                <div style={{ overflowY: "auto"}}>
                    {children}
                </div>
                <div style={{ marginTop: "auto" }}>
                    <FlowStepActions
                        {...actionsProps}
                        onNavigate={onNavigate}
                    />
                </div>
            </Loader>
        </div>
    );
};