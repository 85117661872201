import type { CollectionResponse, OrganizationVerificationRequest } from "../../models";

import RequestBuilder from "../RequestBuilder";
import { VerificationRequestRequestBuilder } from "./VerificationRequestRequestBuilder";

export class VerificationRequestsCollectionRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<CollectionResponse<OrganizationVerificationRequest>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public verificationRequest(requestId: string) {
        return new VerificationRequestRequestBuilder(this._appendUrlSegment(requestId), this.client);
    }
}