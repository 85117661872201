import { StringUtils } from "../utils";
import type { AuthenticationProvider } from "./AuthenticationProvider";
import { isErrorResponse, isValidationErrorResponse, JsonPatchDocument, type ApiObjectResponse, type ApiResponse, type ErrorDetail, type ErrorResponse } from "./models";
import RequestFailedError from "./RequestFailedError";
import { HttpHeader } from "./types";

const handleError = (error: unknown, statusCode: number = -1): ErrorResponse => {
	let errorDetail: ErrorDetail;
	if (error instanceof Error) {
		errorDetail = {
			code: error.name,
			message: error.message,
		};
	} else if (isValidationErrorResponse(error)) {
		let errorMessage: string;
        if (error?.errors) {
            const errorKeys = Object.keys(error.errors);
            if (errorKeys.length > 0) {
                errorMessage ??= "";
                let sep = "";
                for (const errorKey of errorKeys) {
                    errorMessage += sep + error.errors[errorKey];
                    sep = "\n";
                }
            } else {
                errorMessage = error.title;
            }
        } else {
            errorMessage = error.title;
        }

		errorDetail = {
			code: "validation_failed",
			message: errorMessage,
		};
	} else if (isErrorResponse(error)) {
		return error;
	} else if (statusCode === 401) {
		errorDetail = {
			code: "not_authenticated",
			message: "Action failed, you may need to sign-in again.",
		};
	} else if (statusCode === 403) {
		errorDetail = {
			code: "forbidden",
			message: "You are not allowed to perform this action.",
		};
	} else if (typeof error === "string") {
		errorDetail = {
			code: error,
			message: "Uh-oh! An unexpected error occurred. Please try again or contact support.",
		};
	} else {
		errorDetail = {
			code: "unknown_error",
			message: "Uh-oh! An unexpected error occurred. Please try again or contact support.",
		};
	}

	return {
		statusCode,
		error: errorDetail,
	};
};

const handleErrorResponse = (response: Response): Promise<ErrorResponse> => {
	return new Promise((resolve) => {
		response.text().then((responseBody) => {
			let errorObj;
			try {
				errorObj = JSON.parse(responseBody);
			} catch {
				errorObj = {
					statusCode: response.status,
					error: {
						code: response.status,
						message: "An unexpected error occurred. Please try again.",
					},
				};
			}

			const error = handleError(errorObj);
			error.statusCode = response.status;
			resolve(error);
		}).catch((readErr) => {
			const error = handleError(readErr);
			error.statusCode = response.status;
			resolve(error);
		});
	});
};

export class BaseClient {
	private _authenticationProvider?: AuthenticationProvider;
	private _baseUrl?: string;

	constructor(baseUrl?: string, authenticationProvider?: AuthenticationProvider) {
		this._authenticationProvider = authenticationProvider;
		this._baseUrl = baseUrl;
	}

	public get baseUrl(): string | undefined {
		return this._baseUrl;
	}

	public get<T>(requestUrl: string | URL, options?: RequestInit, _authenticated = true): Promise<ApiResponse | ApiObjectResponse<T>> {
		const headers = {
			[HttpHeader.Accept]: "application/json",
		};

		const request = new Request(StringUtils.stripTrailingSlash(requestUrl.toString()), {
			headers,
			method: "GET",
			...options,
		});

		return this._send<T>(request);
	}

	public post<T>(requestUrl: string | URL, serializableObject?: object | string, options?: RequestInit): Promise<ApiResponse | ApiObjectResponse<T>> {
		const requestInit = BaseClient._prepareRequestBody(serializableObject, options);
		const request = new Request(requestUrl, {
			...requestInit,
			method: "POST",
		});

		return this._send<T>(request);
	}

	public put<T>(requestUrl: string | URL, serializableObject?: object | string, options?: RequestInit): Promise<ApiResponse | ApiObjectResponse<T>> {
		const requestInit = BaseClient._prepareRequestBody(serializableObject, options);
		const request = new Request(requestUrl, {
			...requestInit,
			method: "PUT",
			...options,
		});

		return this._send<T>(request);
	};

	public patch<T>(requestUrl: string | URL, serializableObject?: object | string, options?: RequestInit): Promise<ApiResponse | ApiObjectResponse<T>> {
		const requestInit = BaseClient._prepareRequestBody(serializableObject, options);
		const request = new Request(requestUrl, {
			...requestInit,
			method: "PATCH",
		});

		return this._send<T>(request);
	}

	public delete<T>(requestUrl: string,
		serializableObject?: object | string,
		options?: RequestInit): Promise<ApiResponse | ApiObjectResponse<T>> {
		let contentType: string;
		let body: string;
		try {
			contentType = "application/json";
			body = JSON.stringify(serializableObject);
		} catch {
			contentType = "text/plain";
			body = serializableObject as string;
		}

		const headers = {
			[HttpHeader.Accept]: "application/json",
			[HttpHeader.ContentType]: contentType,
		};

		const request = new Request(requestUrl, {
			headers,
			method: "DELETE",
			body: serializableObject ? body : null,
			...options,
		});

        return this._send<T>(request);
	}

	protected async _send<T>(request: Request): Promise<ApiResponse> {
        let url = request.url;
		if (url?.startsWith("/")) {
			const _url = new URL(url);
			url = `${_url.protocol}://${_url.host}${url};`;
		}

		const timeoutInMs = 1000 * 120;
		const abortController = new AbortController();
		const timeout = setTimeout(() => {
			console.warn("Request exceeded timeout, cancelling...");
			abortController.abort("request_timeout");
			console.warn("Request cancelled due to timeout.");
		}, timeoutInMs);

		if (this._authenticationProvider) {
			await this._authenticationProvider.authenticate(request);
		}

		let response: Response;
		try {
			response = await fetch(request, { signal: abortController.signal });
		} catch (reason) {
			const errorResponse = handleError(reason);
			throw new RequestFailedError(errorResponse);
		} finally {
			clearTimeout(timeout);
		}

		if (response.status === 207) {
			let responses: Response[];
			try {
				responses = await response.json();
			} catch (error) {
				console.error("Failed to read batch response", error, response);
				throw error;
			}
			const errorResponse = responses.find((r) => r.status >= 400);
			if (errorResponse) {
				const errorResponseObject = await handleErrorResponse(errorResponse);
				throw new RequestFailedError(errorResponseObject);
			} else {
				return this._makeApiResponse<T>(response);
			}
		} else if (response.status < 400) {
			try {
			const data = await this._handleResponse<T>(response);

			let apiResponse;
			if (typeof data === "string" || data instanceof Blob) {
				apiResponse = this._makeApiResponse<Blob | string>(response, data);
			} else {
				apiResponse = this._makeApiResponse<T>(response, data);
			}

			return apiResponse;
			} catch (error) {
				console.error("Response handler failed", error, response);
				throw error;
			}
		} else {
			const errorResponse = await handleErrorResponse(response);
			throw new RequestFailedError(errorResponse);
		}
	}

	private async _handleResponse<T>(response: Response): Promise<string | Blob | T> {
		const json = [
			"application/json",
			"application/x-javascript",
			"text/javascript",
			"text/x-javascript",
			"text/x-json",
		];
		const text = [
			"text/plain",
			"text/html",
			"text/css",
			"application/xhtml+xml",
			"application/xml",
		];
		let contentType = response.headers.get("Content-Type");
		if (contentType) {
			contentType = contentType.toLowerCase();
			contentType = contentType.split(";")[0];
		}

		let result: Blob | string | T;
		if (response.status === 204) {
			result = {} as T;
		} else if (contentType && json.indexOf(contentType) !== -1) {
			result = await this._handleJsonResponse<T>(response);
		} else if (contentType && text.indexOf(contentType) !== -1) {
			result = await this._handleTextResponse(response);
		} else {
			result = await this._handleBlobResponse(response);
		}

		return result;
	};

	private _makeApiResponse<T>(response: Response, data?: T): ApiObjectResponse<T> | ApiResponse {
		const headers: Record<string, string> = {};

		response.headers.forEach((value: string, key: string) => {
			headers[key] = value;
		});

		return {
			data,
			headers,
			statusCode: response.status,
		};
	}

	private async _handleBlobResponse(response: Response) {
		try {
			const blob = await response.blob();
			return blob;
		} catch (error) {
			console.error("Failed to read blob response.", error);
			throw error;
		}
	}

	private async _handleJsonResponse<T>(response: Response) {
		try {
			const json = await response.json();
			return json as T;
		} catch (error) {
            console.error("Failed to read JSON response.", error);
			throw error;
		}
	}

	private async _handleTextResponse(response: Response) {
		try {
			const text = await response.text();
			return text;
		} catch (error) {
			console.error("Failed to read text response.", error);
			throw error;
		}
	}


	private static _prepareRequestBody(serializableObject?: object | string, options?: RequestInit): RequestInit {
		let body: string | FormData;
		let contentType: string | undefined;

		if (serializableObject instanceof FormData) {
			contentType = undefined;
			body = serializableObject;
		} else {
			try {
				contentType = JsonPatchDocument.isJsonPatchDocument(serializableObject) ? "application/json-patch+json" : "application/json";
				body = JSON.stringify(serializableObject);
			} catch {
				contentType = "text/plain";
				body = serializableObject as string;
			}
		}

		const baseHeaders = {
			[HttpHeader.Accept]: "application/json",
		};

		let contentTypeHeaders;
		if (contentType) {
			contentTypeHeaders = {
				[HttpHeader.ContentType]: contentType,
			};
		}

		const headers = {
			...baseHeaders,
			...contentTypeHeaders,
		};

		return {
			headers,
			body,
			...options,
		}
	}
}

export default BaseClient;
