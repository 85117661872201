import { AddCredentialDialog, Breadcrumb, Button, DeleteOrganizationConfirmationDialog, Flexbox, OrgCodeLabel, VerificationStatusLabel } from "../../components";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { OrganizationCertificateCard, OrganizationCredentialsCard, OrganizationDetailsCard } from "../../components/organization/cards";
import React, { useCallback, useEffect, useState } from "react";
import { organizationActions, selectSelectedOrganization } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";

import type { DialogCloseReason } from "../../components/ui/types";
import { OrganizationOwnersCard } from "../../components/organization/cards/OrganizationOwnersCard";
import { OrganizationVerificationRequestCard } from "../../components/organization/cards/OrganizationVerificationRequestCard";
import { Page } from "../../layouts";
import { Title1 } from "@fluentui/react-components";
import routePaths from "../../router/routePaths";

export const OrganizationDetailPage = () => {
    const { organizationId } = useParams<{ organizationId: string }>();
    const organization = useAppSelector(selectSelectedOrganization);
    const [addCredentialDialogOpen, setAddCredentialDialogOpen] = useState<boolean>(false);
    const [getCredentialsInProgress, setGetCredentialsInProgress] = useState<boolean>(!organization?.credentials || organization.credentials.length === 0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleDeleteDialogClose = useCallback((reason: DialogCloseReason) => {
        if (reason === "completed") {
            navigate(routePaths.organizations.list);
        } else {
            setDeleteDialogOpen(false);
        }
    }, [navigate]);

    const handleAddCredentialDialogDismiss = React.useCallback(() => {
        setAddCredentialDialogOpen(false);
    }, []);

    useEffect(() => {
        let action: any;
        if (organizationId) {
            dispatch(organizationActions.setSelectedOrganization(organizationId));
            action = dispatch(organizationActions.getById(organizationId));
        }

        return () => {
            action?.abort("unmount");
        };
    }, [dispatch, organizationId]);

    useEffect(() => {
        let action: any;
        if (organizationId) {
            action = dispatch(organizationActions.getCredentials({ organizationId }));
            action.finally(() => {
                setGetCredentialsInProgress(false);
            });
        }

        return () => {
            action?.abort("unmount");
        };
    }, [dispatch, organizationId]);

    if (!organizationId || organizationId.length === 0) {
        return <Navigate to={routePaths.organizations.list} />;
    }

    const loading = !Boolean(organization);
    return (
        <Page disableHorizontalGutters>
            <div
                style={{
                    backgroundColor: "#F8F8FC",
                    position: "sticky",
                    top: 0,
                    paddingBottom: 16,
                    zIndex: 100,
                }}
            >
                <Breadcrumb
                    items={[{
                        text: "Dashboard",
                        href: routePaths.dashboard,
                    }, {
                        current: true,
                        text: organization?.name ?? "...",
                        href: window.location.href,
                    }]}
                />
                <Flexbox horizontalAlign="space-between" style={{ maxWidth: 768, marginLeft: "auto", marginRight: "auto" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 4,
                        }}
                    >
                        <Title1>{organization?.name}</Title1>
                        {organization?.lookupKey && (
                            <OrgCodeLabel orgCode={organization?.lookupKey} />
                        )}
                    </div>
                    <div>
                        {organization && (
                            <VerificationStatusLabel
                                organization={organization}
                            />
                        )}
                    </div>
                </Flexbox>
            </div>
            <Flexbox
                direction="column"
                fullHeight
                fullWidth
                rowGap={16}
                style={{
                    maxWidth: 768,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                {organization?.verification && (
                    <OrganizationCertificateCard
                        organizationId={organization.id}
                        verification={organization.verification}
                    />
                )}
                <OrganizationDetailsCard
                    hideName={true}
                    loading={loading}
                    organization={organization ?? undefined}
                />
                <OrganizationOwnersCard
                    loading={loading}
                    organization={organization ?? undefined}
                />
                {!organization?.verification && (
                    <OrganizationVerificationRequestCard
                        loading={loading}
                        organizationId={organizationId}
                    />
                )}
                <OrganizationCredentialsCard
                    loading={getCredentialsInProgress}
                    organization={organization}
                />
                {organization && (!organization?.verification) && (
                    <Flexbox direction="column">
                        <Button
                            text="Delete organization"
                            variant="danger"
                            onClick={() => setDeleteDialogOpen(true)}
                        />
                    </Flexbox>
                )}
            </Flexbox>
            {(organization && deleteDialogOpen) && (
                <DeleteOrganizationConfirmationDialog
                    open={deleteDialogOpen}
                    organization={organization}
                    onClose={handleDeleteDialogClose}
                />
            )}
            <AddCredentialDialog
                open={addCredentialDialogOpen}
                organizationId={organizationId}
                onDismiss={handleAddCredentialDialogDismiss}
            />
        </Page>
    );
};

export default OrganizationDetailPage;