import { organizationActions, selectOrganizationsByIds } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";

import { AddOrganizationDialog } from "../../components";
import { Button } from "@fluentui/react-components";
import type { Organization } from "../../client";
import OrganizationCardList from "../../components/OrganizationCardList";
import React from "react";
import routePaths from "../../router/routePaths";
import { useNavigate } from "react-router-dom";

export const OrganizationsPage = () => {
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const nextLink = useAppSelector(s => s.organizations.list.nextLink);
    const organizations = useAppSelector(s => selectOrganizationsByIds(s, s.organizations.list.ids));
    const navigate = useNavigate();

    const handleLoadMoreBtnClick = () => {
    };

    const handleAddBtnClick = () => {
        setAddDialogOpen(true);
    };

    const handleAddDialogDismiss = React.useCallback(() => {
        setAddDialogOpen(false);
    }, []);

    const handleListItemClick = React.useCallback((_e: React.MouseEvent, organization: Organization) => {
        const to = routePaths.organizations.view.replace(":organizationId", organization.id);
        navigate(to);
    }, [navigate]);

    useEffect(() => {
        const action = dispatch(organizationActions.get());

        return () => {
            action.abort("unmount");
        };
    }, [dispatch]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <Button
                    appearance="primary"
                    onClick={handleAddBtnClick}
                >
                    Add
                </Button>
            </div>
            <OrganizationCardList
                organizations={organizations}
                onListItemClick={handleListItemClick}
            />
            {nextLink && (
                <Button onClick={handleLoadMoreBtnClick}>Load more</Button>
            )}
            <AddOrganizationDialog
                open={addDialogOpen}
                onDismiss={handleAddDialogDismiss}
            />
        </div>
    );
};

export default OrganizationsPage;