import { FormRequestBuilder } from "./FormRequestBuilder";
import RequestBuilder from "../RequestBuilder";
import { CollectionResponse, type Form } from "../../models";
import type { ODataCollectionQueryOptions } from "../../models/ODataCollectionQueryOptions";

export class FormCollectionRequestBuilder extends RequestBuilder {
    public async get(nextLinkOrQueryOptions?: string | ODataCollectionQueryOptions) {
        const requestUrl = typeof(nextLinkOrQueryOptions) === "string" ? nextLinkOrQueryOptions : this.baseUrl;
        const response = await this.client.get<CollectionResponse<Form>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public form(formId: string) {
        return new FormRequestBuilder(this._appendUrlSegment(formId), this.client);
    }
}

export default FormCollectionRequestBuilder;