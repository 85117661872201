import type { CheckoutSession, CreateCheckoutSessionRequest, OrganizationVerificationRequest } from "../../models";

import { RequestBuilder } from "../RequestBuilder";
import { VerificationDocumentCollectionRequestBuilder } from "../verifications/VerificationDocumentCollectionRequestBuilder";

export class OrganizationVerificationRequestRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<OrganizationVerificationRequest>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async submit() {
        const requestUrl = this._appendUrlSegment("submit");
        const response = await this.client.post(requestUrl);
        return response;
    }

    public async createCheckoutSession(request: CreateCheckoutSessionRequest) {
        const requestUrl = this._appendUrlSegment("checkout");
        const response = await this.client.post<CheckoutSession>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }

    public get documents() {
        return new VerificationDocumentCollectionRequestBuilder(this._appendUrlSegment("documents"), this.client);
    }
}