import { Combobox, Option, type OptionOnSelectData, type SelectionEvents } from "@fluentui/react-components";
import { useCallback, useEffect, useRef, useState, type ChangeEvent } from "react";
import { useScript } from "../../../hooks/useScript";
import { PlacesClient, type PlacePrediction } from "../../../libs";
import { Skeleton } from "../../ui/surface/Skeleton/Skeleton";
import { StringUtils } from "../../../utils";
import type { Address } from "../../../client";
import { useTextFieldStyles } from "../../ui";

const searchDebounceDelay = 250;
const placesApiKey = "AIzaSyBaHpc3WGhY0eG09BJ9XKrD6oeCsxH7wh8";

export interface SuggestedLocation {
    formattedAddress?: string;
    address?: Address;
    primaryText?: string;
    secondaryText?: string;
    externalProvider?: string;
    externalProviderIssuedId?: string;
}

interface LocationAutocompleteProps {
    regions?: string[];
    onSearchTextChange?: (event: ChangeEvent<HTMLInputElement>, newValue?: string) => void;
    onSelect?: (event: SelectionEvents, data: SuggestedLocation) => void;
}

export const LocationAutocomplete = (props: Readonly<LocationAutocompleteProps>) => {
    const { regions, onSearchTextChange, onSelect } = props;
    const textFieldClasses = useTextFieldStyles();
    const [suggestions, setSuggestions] = useState<ReadonlyArray<PlacePrediction>>([]);
    const mapsScriptStatus = useScript(`https://maps.googleapis.com/maps/api/js?key=${placesApiKey}&libraries=places`);
    const placesClientRef = useRef<PlacesClient | undefined>(undefined);
    const [, setPlacesClientReady] = useState(false);
    const debouncedSearchHandler = useRef<NodeJS.Timeout>();
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    const fetchAutocompleteSuggestions = useCallback((searchText: string) => {
        if (!placesClientRef.current) {
            throw new Error("Places client is not ready yet");
        }

        placesClientRef.current.autocomplete({
            input: searchText,
            includedRegionCodes: regions,
        }).then((response) => {
            setSuggestions(response.suggestions.map((suggestion) => suggestion.placePrediction));
        }).catch((error) => {
            console.error("Error fetching autocomplete suggestions", error);
        });
    }, [regions]);

    const handleSearchTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { value: searchText } = event.target ?? {};
        onSearchTextChange?.(event, event.target.value);

        if (!placesClientRef.current) {
            return;
        }

        if (debouncedSearchHandler.current) {
            clearTimeout(debouncedSearchHandler.current);
        }

        if (StringUtils.isNullOrWhitespace(searchText)) {
            setSuggestions([]);
            return;
        }

        debouncedSearchHandler.current = setTimeout(() => {
            fetchAutocompleteSuggestions(searchText);
        }, searchDebounceDelay);
    }, [fetchAutocompleteSuggestions, onSearchTextChange]);

    const handleOptionSelect = async (event: SelectionEvents, data: OptionOnSelectData) => {
        const selectedSuggestion = suggestions.find((suggestion) => suggestion.placeId === data.optionValue);
        if (!selectedSuggestion) {
            return;
        }

        if (data.optionValue === "raw_input") {
            onSelect?.(event, {
                address: {
                    streetAddress: data.optionText,
                },
            });

            return;
        }

        const placeDetails = await placesClientRef.current?.getPlaceDetails(selectedSuggestion.placeId);

        const streetNumber = placeDetails?.addressComponents?.find((component) => component.types.includes("street_number"))?.longText;
        const route = placeDetails?.addressComponents?.find((component) => component.types.includes("route"))?.shortText;
        const streetAddress = `${streetNumber} ${route}`;

        onSelect?.(event, {
            address: {
                countryCode: placeDetails?.addressComponents?.find((component) => component.types.includes("country"))?.shortText ?? "",
                locality: placeDetails?.addressComponents?.find((component) => component.types.includes("locality"))?.longText ?? "",
                postalCode: placeDetails?.addressComponents?.find((component) => component.types.includes("postal_code"))?.shortText ?? "",
                region: placeDetails?.addressComponents?.find((component) => component.types.includes("administrative_area_level_1"))?.shortText ?? "",
                streetAddress: streetAddress.trim(),
            },
            formattedAddress: selectedSuggestion?.text.text ?? "",
            primaryText: selectedSuggestion?.structuredFormat.mainText.text,
            secondaryText: selectedSuggestion?.structuredFormat.secondaryText?.text,
            externalProvider: "google",
            externalProviderIssuedId: selectedSuggestion?.placeId ?? "",
        });
    };

    useEffect(() => {
        if (mapsScriptStatus === "ready") {
            placesClientRef.current = new PlacesClient(placesApiKey);
            setPlacesClientReady(true);
        }
    }, [mapsScriptStatus]);

    return (
        <Skeleton loading={mapsScriptStatus === "loading"}>
            <Combobox
                className={textFieldClasses.root}
                expandIcon={null}
                freeform={true}
                input={{
                    className: textFieldClasses.input,
                    onChange: handleSearchTextChange,
                }}
                ref={searchInputRef}
                onOptionSelect={handleOptionSelect}
            >
                {!StringUtils.isNullOrWhitespace(searchInputRef.current?.value) && (
                    <Option
                        text={searchInputRef.current!.value}
                        value="raw_input"
                    >
                        <em>Use "{searchInputRef.current!.value}"</em>
                    </Option>
                )}
                {suggestions.map((prediction) => (
                    <Option
                        key={prediction.placeId}
                        text={prediction.structuredFormat.mainText.text}
                        value={prediction.placeId}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>{prediction.structuredFormat.mainText.text}</b>
                            {!StringUtils.isNullOrWhitespace(prediction.structuredFormat.secondaryText?.text) && (
                                <span>{prediction.structuredFormat.secondaryText!.text}</span>
                            )}
                        </div>
                    </Option>
                ))}
            </Combobox>
        </Skeleton>
    );
};

export default LocationAutocomplete;