import { Delete16Regular, Document32Regular } from "@fluentui/react-icons";

import { ButtonWithLoader } from "../buttons";
import type { DocumentVersion } from "../../../client";
import { FileUtils } from "../../../utils";
import { Tag } from "../../ui";

interface DocumentDetailCardProps {
    document: DocumentVersion;
    statusText?: string;
    actionInProgress?: boolean;
    onDelete?: (e: React.MouseEvent, document: DocumentVersion) => void;
}

export const DocumentDetailCard = (props: Readonly<DocumentDetailCardProps>) => {
    const { actionInProgress, document, statusText, onDelete } = props;

    const handleDeleteBtnClick = (e: React.MouseEvent) => {
        onDelete?.(e, document);
    };

    return (
        <div
            className="hides-descendants"
            style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                boxSizing: "border-box",
                borderRadius: "6px",
                width: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    padding: "8px",
                    width: "100%",
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: 8,
                            }}
                        >
                            <Document32Regular />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <span>{document.name}</span>
                                {(document.properties?.contentLength !== undefined && document.properties?.contentLength !== null) && (
                                    <span>{FileUtils.getFileSizeText(document.properties.contentLength)}</span>
                                )}
                            </div>
                        </div>
                        {onDelete && (
                            <ButtonWithLoader
                                appearance="transparent"
                                className="hidden-descendant"
                                loading={actionInProgress}
                                icon={<Delete16Regular />}
                                onClick={handleDeleteBtnClick}
                            />
                        )}
                    </div>
                </div>
                {(!actionInProgress && statusText) && (
                    <Tag shape="pill">{statusText}</Tag>
                )}
            </div>
        </div>
    );

};