import type { PropsWithChildren } from "react";

interface PageProps extends PropsWithChildren {
    children: React.ReactNode;
    disableHorizontalGutters?: boolean;
    disableVerticalGutters?: boolean;
}

export const Page = (props: Readonly<PageProps>) => {
    const { children, disableHorizontalGutters, disableVerticalGutters } = props;
    return (
        <div
            style={{
                boxSizing: "border-box",
                paddingTop: disableHorizontalGutters === true ? undefined : 30,
                paddingBottom: 16,
                paddingLeft: disableVerticalGutters === true ? undefined : 24,
                paddingRight: disableVerticalGutters === true ? undefined : 24,
                height: "100%",
                overflowY: "auto",
            }}
        >
            {children}
        </div>
    )
};

export default Page;