import { Navigate } from "react-router-dom";
import routePaths from "../router/routePaths";
import { useAppSelector } from "../hooks";

export const AccountSetupGuard: React.FC = () => {
    const setupRequired = useAppSelector(s => s.me.setupRequired === true);

    return setupRequired ? <Navigate to={routePaths.setup.account} /> : null;
}

export default AccountSetupGuard;