import type { PropsWithChildren } from "react";
import { SkeletonItem } from "@fluentui/react-components";

export interface SkeletonProps extends PropsWithChildren {
    loading?: boolean;
};

export const Skeleton = (props: Readonly<SkeletonProps>) => {
    const { children, loading } = props;

    return loading ? (
        <Skeleton>
            <SkeletonItem />
        </Skeleton>
    ) : (
        <>
            {children}
        </>
    );
};

export default Skeleton;