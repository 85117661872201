import type { AddOrganizationRequest, CollectionResponse, Organization } from "../../models";

import type { ODataCollectionQueryOptions } from "../../models/ODataCollectionQueryOptions";
import { OrganizationRequestBuilder } from "./OrganizationRequestBuilder";
import RequestBuilder from "../RequestBuilder";
import { StringUtils } from "../../../utils";

export class OrganizationCollectionRequestBuilder extends RequestBuilder {
    public async get(_nextLinkOrQueryOptions?: ODataCollectionQueryOptions | string) {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<CollectionResponse<Organization>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async count(_queryOptions?: ODataCollectionQueryOptions) {
        const requestUrl = this._appendUrlSegment("$count");
        const response = await this.client.get<{ count: number }>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public organization(organizationId: string) {
        if (StringUtils.isNullOrWhitespace(organizationId)) {
            throw new Error("organizationId is required.");
        }

        return new OrganizationRequestBuilder(this._appendUrlSegment(organizationId), this.client);
    }

    public async add(request: AddOrganizationRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.post<Organization>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }
}