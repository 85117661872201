import React from "react";
import { isRequirementGroup, type RequirementType } from "../../../client";

interface CredentialRequirementListProps {
    requirements: RequirementType[];
    style?: React.CSSProperties;
};

export const CredentialRequirementList = React.forwardRef<HTMLUListElement, Readonly<CredentialRequirementListProps>>((props, forwardedRef) => {
    const { requirements, style } = props;

    function render(requirement: RequirementType) {
        if (isRequirementGroup(requirement)) {
            return (requirement.members?.length > 0) ? (
                <li key={requirement.id}>
                    <ul>
                        {requirement.members.map((child) => render(child))}
                    </ul>
                </li>
            ) : null;
        } else {
            return <li key={requirement.id}>{requirement.description}</li>;
        }
    }

    return (
        <ul ref={forwardedRef} style={style}>
            {requirements.map((requirement) => render(requirement))}
        </ul>
    );
});

export default CredentialRequirementList;