import type { ApiResponse, Organization } from "../../models";

import { OrganizationCredentialCollectionRequestBuilder } from "./credentials/OrganizationCredentialCollectionRequestBuilder";
import { OrganizationCredentialRequestCollectionRequestBuilder } from "./credentialRequests/OrganizationCredentialRequestCollectionRequestBuilder";
import { OrganizationCredentialRequestRequestBuilder } from "./credentialRequests";
import { OrganizationOwnerCollectionRequestBuilder } from "./owner";
import OrganizationVerificationRequestCollectionRequestBuilder from "./OrganizationVerificationRequestCollectionRequestBuilder";
import RequestBuilder from "../RequestBuilder";

export class OrganizationRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<Organization>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async patch(request: Partial<Organization>): Promise<ApiResponse> {
        const requestUrl = this.baseUrl;
        const response = await this.client.patch<void>(requestUrl, request);
        return response;
    }

    public async delete() {
        const requestUrl = this.baseUrl;
        const response = await this.client.delete<void>(requestUrl);
        return response;
    }

    public get credentials() {
        return new OrganizationCredentialCollectionRequestBuilder(this._appendUrlSegment("credentials"), this.client);
    }

    public get credentialRequest() {
        return new OrganizationCredentialRequestRequestBuilder(this._appendUrlSegment("credentialRequest"), this.client);
    }

    public get credentialRequests() {
        return new OrganizationCredentialRequestCollectionRequestBuilder(this._appendUrlSegment("credentialRequests"), this.client);
    }

    public get owners() {
        return new OrganizationOwnerCollectionRequestBuilder(this._appendUrlSegment("owners"), this.client);
    }

    public get verificationRequests() {
        return new OrganizationVerificationRequestCollectionRequestBuilder(this._appendUrlSegment("verificationRequests"), this.client);
    }
}