interface RequirementBase {
    id: string;
    requirementType?: string;
}

export interface Requirement extends RequirementBase {
    id: string;
    description: string;
    satisfiedBy: string;
}

export interface RequirementGroup extends RequirementBase {
    id: string;
    members: Requirement[];
}

export type RequirementType = Requirement | RequirementGroup;

export function isRequirementGroup(requirement: RequirementType): requirement is RequirementGroup {
    return (requirement as RequirementGroup).members !== undefined || (requirement.requirementType === "group" || requirement.requirementType === "Group");
}

export interface Credential {
    id: string;
    name: string;
    description?: string;
    requirements: RequirementType[];
}