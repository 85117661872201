import RequestBuilder from "../../RequestBuilder";

interface PutCredentialResponseRequest {
    draft?: boolean;
    values: Record<string, any>;
}

export class CredentialRequestResponseRequestBuilder extends RequestBuilder {
    public async put(request: PutCredentialResponseRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.put(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }
}

export default CredentialRequestResponseRequestBuilder;