import { Dropdown, Field, Option, Persona, ProgressBar, type OptionOnSelectData, type SelectionEvents } from "@fluentui/react-components";

import { Flexbox } from "../ui";
import type { OrganizationOwner } from "../../client";
import { useMemo, useState } from "react";

interface OwnerTagSelectionProps {
    defaultValue?: ReadonlyArray<string>;
    label?: string;
    name?: string;
    owners: ReadonlyArray<OrganizationOwner>;
    readOnly?: boolean;
    required?: boolean;
    onChange?: (e: SelectionEvents, value: string[]) => void;
}

const createOwnerName = (owner: OrganizationOwner) => `${owner.givenName} ${owner.surname}`;

export const OwnerTagSelection = (props: Readonly<OwnerTagSelectionProps>) => {
    const {
        defaultValue,
        label,
        owners,
        readOnly,
        required,
        onChange,
    } = props;
    const [selectedIds, setSelectedIds] = useState<ReadonlyArray<string>>(defaultValue ?? []);

    const qualifiedOwnership = useMemo(() => {
        const selectedOwners = owners.filter((owner) => selectedIds.includes(owner.id));
        const ownershipTotal = selectedOwners.reduce((acc, owner) => acc + owner.ownership, 0);
        return ownershipTotal;
    }, [owners, selectedIds]);

    const handleDropdownChange = (event: SelectionEvents, data: OptionOnSelectData) => {
        setSelectedIds(data.selectedOptions);
        onChange?.(event, data.selectedOptions);
    };

    const majorityRequirementMet = qualifiedOwnership > 0.5;

    return (
        <Flexbox direction="column" rowGap>
            <Field
                label={label}
                required={required}
                validationMessage={!majorityRequirementMet ? "At least 51% ownership is required" : undefined}
                validationState={!majorityRequirementMet ? "error" : "success"}
            >
                <ProgressBar
                    shape="square"
                    value={qualifiedOwnership}
                />
            </Field>
            <Dropdown
                defaultValue={owners.filter((owner) => selectedIds.includes(owner.id)).map((owner) => createOwnerName(owner)).join(", ")}
                defaultSelectedOptions={Array.from(selectedIds)}
                disabled={readOnly}
                multiselect={true}
                onOptionSelect={handleDropdownChange}
            >
                {owners.map((owner) => (
                    <Option
                        key={owner.id}
                        text={createOwnerName(owner)}
                        value={owner.id}
                    >
                        <Persona
                            primaryText={createOwnerName(owner)}
                            secondaryText={owner.emailAddress}
                            tertiaryText={`${(owner.ownership * 100)}%`}
                        />
                    </Option>
                ))}
            </Dropdown>
        </Flexbox>
    );
}

export default OwnerTagSelection;