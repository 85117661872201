import { OrganizationDetailsCard, OrganizationVerificationDocumentsCard } from "../../cards";

import { Flexbox, type FlowNavigationDirection } from "../../../ui";
import { FlowStep } from "../../../ui/flow/FlowStep";
import { OrganizationOwnersCard } from "../../cards/OrganizationOwnersCard";
import type { VerificationStepProps } from "./types";
import { isPayloadAction, organizationActions } from "../../../../store";
import { useAppDispatch, useToaster } from "../../../../hooks";
import { useCallback, useEffect, useState } from "react";
import type { ApiResponse } from "../../../../client";
import { isRequestFailedError } from "../../../../client/RequestFailedError";

export const OrganizationVerificationReviewFlowStep = (props: Readonly<VerificationStepProps>) => {
    const { organization, verificationRequest, onNavigate, ...stepProps } = props;
    const dispatch = useAppDispatch();
    const toaster = useToaster();
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const submit = useCallback(async (organizationId: string, requestId: string) => {
        const actionResult = await dispatch(organizationActions.submitVerificationRequest({ organizationId, requestId }));

        if (isPayloadAction<ApiResponse>(actionResult) && actionResult.error) {
            if (isRequestFailedError(actionResult.error) && (actionResult.error.statusCode === 409 || actionResult.error.code === "conflict")) {
                return await dispatch(organizationActions.getById(organizationId));
            } else {
                throw actionResult.error;
            }
        }

        return actionResult.payload as ApiResponse;
    }, [dispatch]);

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        if (direction !== "forward" || verificationRequest?.submittedAt) {
            onNavigate?.(direction);
            return;
        }

        if (!verificationRequest?.id) {
            toaster.push("Verification request not found.", { type: "error" });
            return;
        }

        setSubmitInProgress(true);
        submit(organization?.id, verificationRequest?.id).then(() => {
            setTimeout(() => {
                onNavigate?.(direction);
            }, 1800);
        }).catch((error) => {
            toaster.push((error as any)?.message, { type: "error", title: "There was a problem submitting your verification request" });
        }).finally(() => {
            setSubmitInProgress(false);
        });
    }, [onNavigate, organization?.id, submit, toaster, verificationRequest?.id, verificationRequest?.submittedAt]);

    useEffect(() => {
        if (organization?.id) {
            dispatch(organizationActions.getById(organization.id));
        }
    }, [organization?.id, dispatch]);

    return (
        <FlowStep
            {...stepProps}
            actionsProps={{
                actionInProgress: submitInProgress,
            }}
            description="Please review the information that has been provided before proceeding to payment. Incorrect information may result in a failed verification."
            onNavigate={handleStepNavigation}
        >
            <Flexbox direction="column" rowGap>
                <OrganizationDetailsCard
                    organization={organization}
                />
                <OrganizationOwnersCard
                    organization={organization}
                />
                <OrganizationVerificationDocumentsCard
                    organization={organization}
                />
            </Flexbox>
        </FlowStep>
    );
};

export default OrganizationVerificationReviewFlowStep;