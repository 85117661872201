import { Body1 } from "@fluentui/react-components";
import { CheckmarkCircle16Filled } from "@fluentui/react-icons";
import type { Organization } from "../../../client";
import { useMemo } from "react";

interface VerificationStatusLabelProps {
    organization: Organization;
}

export const VerificationStatusLabel = (props: Readonly<VerificationStatusLabelProps>) => {
    const { organization } = props;

    const statusText = useMemo(() => {
        const verification = organization?.verification;
        const verificationRequests = organization?.verificationRequests;
        const hasOpenRequests = verificationRequests?.some(request => request.state?.toUpperCase() === "OPEN");
        if (verification?.status?.toUpperCase() === "ACTIVE") {
            return "Verified";
        } else if (verification?.status?.toUpperCase() === "EXPIRED" && !hasOpenRequests) {
            return "Verification expired";
        } else {
            return null;
        }
    }, [organization]);

    const textColor = useMemo(() => {
        switch (statusText) {
            case "Verified":
                return "#1C5F1D";
            case "Verification expired":
                return "#FF0000";
            default:
                return "#000";
        }
    }, [statusText]);

    return (statusText !== null) ? (
        <Body1
            style={{
                alignItems: "center",
                color: textColor,
                columnGap: 4,
                display: "flex",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 600,
            }}
        >
            <CheckmarkCircle16Filled />
            {statusText}
        </Body1>
    ) : null;
}