import type { PropsWithChildren } from "react";
import classNames from "classnames";

interface BoxProps extends PropsWithChildren {
    className?: string;
    fullWidth?: true;
    style?: React.CSSProperties;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const Box = (props: Readonly<BoxProps>) => {
    const { children, className, fullWidth, style, onClick } = props;

    return (
        <div
            className={classNames({
                "full-width": fullWidth,
            }, className)}
            style={{
                backgroundColor: "#FFF",
                border: "0.5px solid #B5BDDE",
                borderRadius: 16,
                boxSizing: "border-box",
                padding: 16,
                ...style,
            }}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default Box;