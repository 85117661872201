import { Link, Outlet } from "react-router-dom";

import { ArrowExitRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { Flexbox } from "../components";
import { ModalLayoutContext, type ModalExitCallback } from "./ModalLayoutContext";
import type { PropsWithChildren } from "react";
import React from "react";
import routePaths from "../router/routePaths";

export const ModalLayout: React.FC<PropsWithChildren> = () => {
    const subscribers = React.useRef<ModalExitCallback[]>([]);

    const handleExitButtonClick = () => {
        subscribers.current.forEach(callback => callback());
    };

    const subscribe = (callback: ModalExitCallback): (() => void) => {
        subscribers.current.push(callback);
        // Return an unsubscribe function.
        return () => {
            subscribers.current = subscribers.current.filter(cb => cb !== callback);
        };
    };

    return (
        <>
            <header>
                <div
                    style={{
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.10)",
                        borderBottom: "1px solid #A797FD",
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px 56px 12px 48px",
                    }}
                >
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <h3>
                            <Link to={routePaths.dashboard} style={{ color: "white", textDecoration: "none" }}>
                                <img src="/logo-color-transparent.svg" alt="Logo" />
                            </Link>
                        </h3>
                    </div>
                    <Flexbox direction="column" verticalAlign="center" style={{ minHeight: 48 }}>
                        <Button
                            aria-label="Close"
                            icon={<ArrowExitRegular />}
                            shape="circular"
                            size="large"
                            title="Close"
                            onClick={handleExitButtonClick}
                        />
                    </Flexbox>
                </div>
            </header>
            <main>
                <div style={{ boxSizing: "border-box", height: "calc(100vh - 78px)", overflowY: "hidden" }}>
                    <ModalLayoutContext.Provider value={{ subscribe }}>
                        <Outlet />
                    </ModalLayoutContext.Provider>
                </div>
            </main>
        </>
    );
};

export default ModalLayout;