import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { ButtonWithLoader } from "../../components";
import { Field } from "@fluentui/react-components";
import { Navigate } from "react-router-dom";
import type { PutUserRequest } from "../../client";
import { StringUtils } from "../../utils";
import { TextField } from "../../components/ui";
import { meActions } from "../../store/me";
import routePaths from "../../router/routePaths";
import { useState } from "react";

interface FormValues {
    givenName?: string;
    surname?: string;
}

const isFormReady = (formValues: Partial<FormValues>): boolean => {
    return !StringUtils.isNullOrWhitespace(formValues.givenName)
        && !StringUtils.isNullOrWhitespace(formValues.surname);
}

export const AccountSetupPage: React.FC = () => {
    const formValuesRef = useRef<FormValues>({});
    const [submitReady, setSubmitReady] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const me = useAppSelector(s => s.me.me);
    const dispatch = useAppDispatch();
    const mountedRef = useRef<boolean>(false);

    const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        const input = e.target ?? e.currentTarget;
        if (!input.name) {
            return;
        }

        formValuesRef.current = {
            ...formValuesRef.current,
            [input.name]: newValue,
        };

        setSubmitReady(isFormReady(formValuesRef.current));
    }, []);

    const handleContinueBtnClick = React.useCallback(() => {
        const request: PutUserRequest = {
            givenName: formValuesRef.current.givenName ?? "",
            surname: formValuesRef.current.surname ?? "",
        };

        setInProgress(true);
        const action = dispatch(meActions.set(request));
        action
            .then(() => {
                setCompleted(true);
            })
            .catch((error) => {
                setInProgress(false);
                console.error(error);
            })
    }, [dispatch]);

    useEffect(() => {
        dispatch(meActions.get());
    }, [dispatch]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    if (me?.id || completed) {
        return <Navigate to={routePaths.dashboard} />;
    }

    return (
        <div style={{ height: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div>Welcome, let's get your account setup.</div>
            <div>Tell us who you are</div>
            <div style={{ display: "flex", flexDirection: "column", columnGap: 8, }}>
                <Field label="First name">
                    <TextField
                        name="givenName"
                        required={true}
                        onChange={handleInputChange}
                    />
                </Field>
                <Field label="Last name">
                    <TextField
                        name="surname"
                        required={true}
                        onChange={handleInputChange}
                    />
                </Field>
                <ButtonWithLoader appearance="primary" disabled={!submitReady} loading={inProgress} onClick={handleContinueBtnClick}>Continue</ButtonWithLoader>
            </div>
            </div>
        </div>
    );
};

export default AccountSetupPage;