import { createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, TableCellLayout, type TableColumnDefinition } from "@fluentui/react-components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import type { OrganizationVerificationRequest } from "../../client";
import React, { useCallback, useEffect, useMemo } from "react";
import { selectAllVerificationRequests, verificationRequestActions } from "../../store/verifications";
import { Link, useNavigate } from "react-router-dom";
import routePaths from "../../router/routePaths";

interface VerificationsTableItem extends OrganizationVerificationRequest {
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const tableColumnDefs: TableColumnDefinition<VerificationsTableItem>[] = [
    createTableColumn<VerificationsTableItem>({
        columnId: "name",
        renderHeaderCell() {
            return "Organization";
        },
        renderCell(item) {
            return (
                <TableCellLayout>
                    {item.onClick ? (
                        <Link to={`${routePaths.backOffice.verifications.details.replace(":verificationId", item.id)}`} onClick={item.onClick}>{item.organization?.name}</Link>
                    ) : item.organization?.name}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<OrganizationVerificationRequest>({
        columnId: "status",
        renderHeaderCell() {
            return "Status";
        },
        renderCell(item) {
            return <TableCellLayout>{item.status}</TableCellLayout>;
        },
    }),
]

export const VerificationsListPage = () => {
    const verifications = useAppSelector(selectAllVerificationRequests);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleTableItemClick = useCallback((item: OrganizationVerificationRequest) => {
        navigate(`${routePaths.backOffice.verifications}/${item.id}`);
    }, [navigate]);

    const tableItems: VerificationsTableItem[] = useMemo(() => {
        return verifications.map((item) => ({
            ...item,
            onClick: () => handleTableItemClick(item),
        }));
    }, [handleTableItemClick, verifications]);

    useEffect(() => {
        dispatch(verificationRequestActions.get());
    }, [dispatch]);

    return (
        <DataGrid
            columns={tableColumnDefs}
            items={tableItems}
            sortable
            getRowId={(item) => item.id}
        >
            <DataGridHeader>
                <DataGridRow>
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                    )}
                </DataGridRow>
            </DataGridHeader>
            <DataGridBody<OrganizationVerificationRequest>>
                {({ item, rowId }) => (
                <DataGridRow<OrganizationVerificationRequest>
                    key={rowId}
                >
                    {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                    )}
                </DataGridRow>
                )}
            </DataGridBody>
        </DataGrid>
    );
};

export default VerificationsListPage;