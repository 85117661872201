import { Field, RadioGroup as FuiRadioGroup, Radio, type RadioGroupOnChangeData } from "@fluentui/react-components";
import React, { useCallback, useMemo, type RefAttributes } from "react";

import type { InputFieldProps, SelectOption, SelectOptionValueType } from "../types";

interface ChoiceGroupProps<TValue extends SelectOptionValueType> extends InputFieldProps, RefAttributes<HTMLDivElement> {
    defaultValue?: TValue;
    layout?: "horizontal" | "vertical";
    multiple?: boolean;
    name?: string;
    options: ReadonlyArray<SelectOption<TValue>>;
    value?: TValue;
    onChange?: (e: React.ChangeEvent<HTMLDivElement>, value: TValue) => void;
}

export const ChoiceGroup = <TValue extends SelectOptionValueType>(props: Readonly<ChoiceGroupProps<TValue>>) => {
    const {
        defaultValue,
        label: labelOrLabelProps,
        layout = "horizontal",
        name,
        options,
        readOnly,
        ref: forwardedRef,
        required,
        onChange,
    } = props;

    let valueType = "undefined";
    const hasOptions = options.length > 0;
    if (hasOptions) {
        valueType = typeof(options[0]?.value ?? undefined);
    }

    const radioElements = useMemo(() => {
        return options.map((option) => {
            const strValue = option.value.toString();
            return (
                <Radio key={strValue} readOnly={readOnly} value={strValue} label={option.text ?? strValue} />
            );
        });
    }, [options, readOnly]);

    let labelText;
    let labelRequired;
    if (typeof(labelOrLabelProps) === "string") {
        labelText = labelOrLabelProps;
    } else if (labelOrLabelProps) {
        labelRequired = labelOrLabelProps.required;
        labelText = labelOrLabelProps.text;
    }

    const handleRadioGroupChange = useCallback((ev: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
        if (valueType === "number") {
            onChange?.(ev as React.ChangeEvent<HTMLDivElement>, parseInt(data.value) as TValue);
        } else if (valueType === "boolean") {
            onChange?.(ev as React.ChangeEvent<HTMLDivElement>, (data.value.toUpperCase() === "TRUE") as TValue);
        } else {
            onChange?.(ev as React.ChangeEvent<HTMLDivElement>, data.value as TValue);
        }

    }, [onChange, valueType]);

    labelRequired = labelRequired ?? required;
    return (
        <Field
            label={labelText}
            required={required ?? labelRequired}
        >
            <FuiRadioGroup
                defaultValue={defaultValue?.toString()}
                disabled={readOnly}
                aria-readonly={readOnly}
                layout={layout}
                name={name}
                ref={forwardedRef}
                onChange={handleRadioGroupChange}
            >
                {radioElements}
            </FuiRadioGroup>
        </Field>
    );
};

export default ChoiceGroup;