import { Link } from "react-router-dom";
import type React from "react";
import { routePaths } from "../router/routePaths";

export const Header = (props: { children: React.ReactNode }) => {
    const { children } = props;

    return (
        <header>
            <div
                style={{
                    backgroundColor: "rgba(255, 255, 255, 0.10)",
                    borderBottom: "1px solid #A797FD",
                    boxSizing: "border-box",
                    display: "flex",
                    padding: "12px 56px 12px 48px",
                }}
            >
                <div
                    style={{
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <h3>
                        <Link to={routePaths.dashboard} style={{ color: "white", textDecoration: "none" }}>
                            <img src="/logo-color-transparent.svg" alt="Logo" />
                        </Link>
                    </h3>
                </div>
                <div className="full-width">
                    {children}
                </div>
            </div>
        </header>
    )
}