import { FluentProvider } from "@fluentui/react-components";
import "./theme.css";

import React, { useState, type PropsWithChildren } from "react";
import { brandThemeLight } from "./theme/brand";

interface ThemeContextProps {
    mode?: "auto" | "light" | "dark";
}

const ThemeContext = React.createContext<ThemeContextProps>({ mode: "auto" });

interface ThemeProviderProps extends PropsWithChildren {}

export const ThemeProvider = (props: Readonly<ThemeProviderProps>) => {
    const { children } = props;
    const [themeContextState,] = useState<ThemeContextProps>({ mode: "auto" });

    return (
        <ThemeContext.Provider value={themeContextState}>
            <FluentProvider theme={brandThemeLight} style={{ backgroundColor: "#F8F8FC", height: "100%" }}>
                {children}
            </FluentProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;