import { Button, Flexbox, Loader } from "../ui";
import { EmbeddedCheckout as StripeEmbeddedCheckout, EmbeddedCheckoutProvider as StripeEmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";

import { Body1 } from "@fluentui/react-components";
import { EmojiSadRegular } from "@fluentui/react-icons";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(window.stripeKey);

interface EmbeddedCheckoutProps {
    getSessionSecret: () => Promise<string>;
    onComplete?: () => void;
}

export const EmbeddedCheckout: React.FC<EmbeddedCheckoutProps> = (props: Readonly<EmbeddedCheckoutProps>) => {
    const { getSessionSecret, onComplete } = props;
    const [error, setError] = useState<string | null>(null);
    const [checkoutResetKey, setCheckoutResetKey] = useState<string>("0");
    const [checkoutCompleted, setCheckoutCompleted] = useState<boolean>(false);
    const [loadingSecret, setLoadingSecret] = useState<boolean>(true);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const loadClientSecret = useCallback(async () => {
        setLoadingSecret(true);
        try {
            const sessionSecret = await getSessionSecret();
            setClientSecret(sessionSecret);
        } catch (reason) {
            setError("Failed to fetch checkout session");
            throw reason;
        } finally {
            setLoadingSecret(false);
        }
    }, [getSessionSecret]);

    const handleCheckoutComplete = useCallback(() => {
        setCheckoutCompleted(true);
    }, []);

    const handleRetryClick = useCallback(() => {
        setError(null);
        setCheckoutResetKey((prev) => (parseInt(prev) + 1).toString());
    }, []);

    useEffect(() => {
        loadClientSecret();
    }, [loadClientSecret]);

    useEffect(() => {
        if (checkoutCompleted) {
            onComplete?.();
        }
    }, [checkoutCompleted, onComplete]);

    return (
        <Flexbox direction="column" horizontalAlign="center" rowGap>
            {Boolean(error) ? (
                <>
                    <EmojiSadRegular fontSize={48} />
                    <Body1>{error}</Body1>
                    <Button
                        variant="primary"
                        text="Reload"
                        onClick={handleRetryClick}
                    />
                </>
            ) : (
                <div id="checkout" key={checkoutResetKey} style={{ width: "100%" }}>
                    <Loader loading={loadingSecret}>
                        <StripeEmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={{
                                clientSecret: clientSecret,
                                onComplete: handleCheckoutComplete,
                            }}
                        >
                            <StripeEmbeddedCheckout />
                        </StripeEmbeddedCheckoutProvider>
                    </Loader>
                </div>
            )}
        </Flexbox>
    );
};