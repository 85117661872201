/// <reference types="google.maps" />

import type { AutocompleteRequest, AutocompleteResponse } from "./types";

export type PlaceResult = google.maps.places.Place;

export class PlacesClient {
    private _apiKey: string;

    constructor(apiKey: string) {
        this._apiKey = apiKey;
    }

    public async getPlaceDetails(placeId: string, fields?: ReadonlyArray<string> | string): Promise<PlaceResult> {
        let fieldMask: string;
        if (typeof fields === "string") {
            fieldMask = fields;
        } else if (Array.isArray(fields)) {
            fieldMask = fields.join(",");
        } else {
            fieldMask = "id,addressComponents,adrFormatAddress,displayName,googleMapsUri,types,location";
        }

        const response = await fetch(`https://places.googleapis.com/v1/places/${placeId}`, {
            method: "GET",
            headers: {
                "X-Goog-Api-Key": this._apiKey,
                "X-Goog-FieldMask": fieldMask,
            },
        });

        if (!response.ok) {
            throw new Error("Failed to fetch place details");
        }

        const data = await response.json();
        return data;
    }

    public async autocomplete(request: AutocompleteRequest): Promise<AutocompleteResponse> {
        const response = await fetch(`https://places.googleapis.com/v1/places:autocomplete`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Goog-Api-Key": this._apiKey,
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error("Failed to fetch places");
        }

        const data = await response.json();
        return data;
    }
}

export default PlacesClient;