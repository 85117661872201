import { Dropdown, Option, type DropdownProps, type OptionOnSelectData, type SelectionEvents } from "@fluentui/react-components";
import countries from "../../../data/countries.json";
import React, { useCallback } from "react";

type CountryType = typeof countries[number];

interface CountrySelectProps extends Omit<DropdownProps, "options" | "children" | "onChange" | "onOptionSelect"> {
    defaultValue?: string;
    label?: string;
    valueType?: "isoAlpha2" | "isoAlpha3";
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>, value?: string) => void;
}

export const CountrySelect = (props: Readonly<CountrySelectProps>) => {
    const {
        valueType = "isoAlpha2",
        onChange,
        ...selectProps
    } = props;

    const handleOptionSelect = useCallback((e: SelectionEvents, data: OptionOnSelectData) => {
        onChange?.(e as any, data.optionValue);
    }, [onChange]);

    return (
        <Dropdown
            {...selectProps}
            onOptionSelect={handleOptionSelect}
        >
            {countries.map((country: CountryType) => (
                <Option
                    key={country.isoAlpha3}
                    value={valueType === "isoAlpha2" ? country.isoAlpha2 : country.isoAlpha3}
                >
                    {country.shortName ?? country.name}
                </Option>
            ))}
        </Dropdown>
    )
};

export default CountrySelect;