import { useCallback, useState, type HTMLInputAutoCompleteAttribute } from "react";
import { Field, Input, type InputOnChangeData } from "@fluentui/react-components";
import type { InputFieldProps } from "../types";
import type React from "react";
import { Skeleton } from "../../../surface/Skeleton";
import { useTextFieldStyles } from "./styles";

type MaskToken =
    | { type: "placeholder"; symbol: "#" | "_" }
    | { type: "literal"; value: string };

const parseMask = (mask: string): MaskToken[] => {
    const tokens: MaskToken[] = [];
    for (let i = 0; i < mask.length; i++) {
        if (mask[i] === "\\" && i + 1 < mask.length) {
            tokens.push({ type: "literal", value: mask[i + 1] });
            i++; // Skip the escaped character.
        } else if (mask[i] === "#" || mask[i] === "_") {
            tokens.push({ type: "placeholder", symbol: mask[i] as "#" | "_" });
        } else {
            tokens.push({ type: "literal", value: mask[i] });
        }
    }
    return tokens;
};

const unmaskValue = (input: string, tokens: MaskToken[]): string => {
    const literalSet = new Set<string>();
    tokens.forEach(token => {
        if (token.type === "literal") {
            literalSet.add(token.value);
        }
    });
    let result = "";
    for (const ch of input) {
        if (!literalSet.has(ch)) {
            result += ch;
        }
    }
    return result;
};

const applyMask = (raw: string, tokens: MaskToken[]): string => {
    let output = "";
    let rawIndex = 0;
    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        if (token.type === "placeholder") {
            if (rawIndex >= raw.length) break;
            const currentChar = raw[rawIndex];
            if (token.symbol === "#") {
                if (/\d/.test(currentChar)) {
                    output += currentChar;
                    rawIndex++;
                } else {
                    while (rawIndex < raw.length && !/\d/.test(raw[rawIndex])) {
                        rawIndex++;
                    }
                    if (rawIndex < raw.length) {
                        output += raw[rawIndex];
                        rawIndex++;
                    } else {
                        break;
                    }
                }
            } else if (token.symbol === "_") {
                output += currentChar;
                rawIndex++;
            }
        } else if (token.type === "literal") {
            if (i + 1 < tokens.length && tokens[i + 1].type === "placeholder") {
                if (rawIndex < raw.length) {
                    output += token.value;
                } else {
                    break;
                }
            } else {
                if (rawIndex < raw.length) {
                    output += token.value;
                }
            }
        }
    }
    return output;
};

interface TextFieldProps extends InputFieldProps {
    autoComplete?: HTMLInputAutoCompleteAttribute;
    defaultValue?: string;
    inputMask?: string;
    placeholder?: string;
    value?: string | null;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void;
}

export const TextField = (props: Readonly<TextFieldProps>) => {
    const {
        defaultValue,
        disabled,
        inputMask,
        label,
        loading,
        name,
        placeholder,
        required,
        readOnly,
        status,
        statusText,
        value,
        onBlur,
        onChange,
    } = props;
    const classes = useTextFieldStyles();

    const [internalRawValue, setInternalRawValue] = useState(defaultValue ?? "");
    const rawValue = value !== undefined && value !== null ? value : internalRawValue;

    let tokens: MaskToken[] = [];
    if (inputMask) {
        tokens = parseMask(inputMask);
    }

    const maxLength = tokens.filter(token => token.type === "placeholder").length;
    const clampedRawValue = inputMask ? rawValue.substring(0, maxLength) : rawValue;
    const unmasked = inputMask ? unmaskValue(clampedRawValue, tokens) : clampedRawValue;
    const displayedValue = inputMask ? applyMask(unmasked, tokens) : (clampedRawValue === null ? "" : clampedRawValue);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        let inputVal = data.value;
        if (inputMask) {
            const tokens = parseMask(inputMask);
            inputVal = unmaskValue(inputVal, tokens);
            const maxAllowed = tokens.filter(token => token.type === "placeholder").length;
            if (inputVal.length > maxAllowed) {
                inputVal = inputVal.substring(0, maxAllowed);
            }
        }

        if (inputVal === clampedRawValue) return;

        if (value === undefined || value === null) {
            setInternalRawValue(inputVal);
        }

        onChange?.(e, inputVal);
    }, [inputMask, onChange, value, clampedRawValue]);

    return (
        <Field
            label={typeof label === "string" ? label : label?.text}
            validationMessage={statusText}
            validationState={status === "info" ? "none" : status}
        >
            <Skeleton loading={loading}>
                <Input
                    className={classes.root}
                    defaultValue={displayedValue === undefined ? defaultValue : undefined}
                    disabled={disabled}
                    input={{
                        className: classes.input,
                    }}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    readOnly={readOnly}
                    value={displayedValue}
                    onBlur={onBlur}
                    onChange={handleInputChange}
                />
            </Skeleton>
        </Field>
    );
};

export default TextField;