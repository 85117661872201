import { credentialsEntityAdapter } from "./adapter";
import { createAsyncThunk, createSlice, type EntityState } from "@reduxjs/toolkit";

import type { Credential } from "../../client";
import { serviceClient } from "../../serviceClient";
import { RootState } from "../types";
import { organizationActions } from "../organizations/actions";

interface CredentialsListState {
    items: Array<string>;
    nextLink?: string;
}

interface CredentialsStoreState extends EntityState<Credential, string> {
    selectedId: string | null;
    list: CredentialsListState;
}

const initialState: CredentialsStoreState = credentialsEntityAdapter.getInitialState({
    selectedId: null,
    list: {
        items: [],
    },
    selectedCredential: null,
});

const get = createAsyncThunk(
    "credentials/get",
    async(nextLink?: string) => {
        const response = await serviceClient.credentials.get(nextLink);
        return response.data;
    },
);

const getById = createAsyncThunk(
    "credentials/getById",
    async(credentialId: string) => {
        const response = await serviceClient.credentials.credential(credentialId).get();
        return response.data;
    },
);

export const credentialsSlice = createSlice({
    name: "credentials",
    initialState,
    reducers: {
        setSelectedCredential: (state, action) => {
            state.selectedId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(get.fulfilled, (state, action) => {
            credentialsEntityAdapter.upsertMany(state, action.payload.value);
            state.list.items = action.payload.value.map((credential) => credential.id);
            state.list.nextLink = action.payload["odata.nextLink"];
        });

        builder.addCase(getById.fulfilled, (state, action) => {
            credentialsEntityAdapter.upsertOne(state, action.payload);
        });

        builder.addCase(organizationActions.get.fulfilled, (state, action) => {
            const organizations = action.payload.value;

            const credentials = organizations.reduce((acc, organization) => {
                if (organization?.credentialRequests) {
                    return [...acc, ...organization.credentialRequests.map((cr) => cr.credential).filter(Boolean)];
                }
                return acc;
            }, [] as Credential[]);
            credentialsEntityAdapter.upsertMany(state, credentials);
        });

        builder.addCase(organizationActions.getById.fulfilled, (state, action) => {
            const organization = action.payload;
            if (organization?.credentialRequests) {
                const credentials = organization.credentialRequests.map((cr) => cr.credential).filter(Boolean);
                credentialsEntityAdapter.upsertMany(state, credentials);
            }
        });
    },
});

export const {
    selectAll: selectAllCredentials,
    selectById: selectCredentialById,
} = credentialsEntityAdapter.getSelectors<RootState>((state) => state.credentials);

export const credentialActions = {
    get,
    getById,
};