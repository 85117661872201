import { useCallback, useRef, useState, type ChangeEvent } from "react";
import type { AddOrganizationFormValuesChangeHandler } from "../../types";
import { FlowStep } from "../../../ui/flow/FlowStep";
import { StringUtils } from "../../../../utils";
import { hasErrors, type FormErrorValues } from "../../../common/types";
import type { Address } from "client";
import { CountrySelect, Field } from "../../../common";
import { Flexbox, type FlowNavigationDirection, type FlowStepProps } from "../../../ui";
import { Body2 } from "@fluentui/react-components";
import countries from "data/countries.json";
import { useAppSelector } from "../../../../hooks";

interface AddOrganizationCountryFlowStepProps extends FlowStepProps {
    values?: Address;
    onValuesChange?: AddOrganizationFormValuesChangeHandler;
}

export const AddOrganizationCountryFlowStep = (props: Readonly<AddOrganizationCountryFlowStepProps>) => {
    const { values, onNavigate, onValuesChange } = props;
    const me = useAppSelector((state) => state.me.me);
    const [formErrors, setFormErrors] = useState<FormErrorValues<Address>>({});
    const [unsupportedCountry, setUnsupportedCountry] = useState(false);
    const formValuesRef = useRef<Partial<Address>>(values ?? {});

    const countryName = () => {
        const formValues = formValuesRef.current;
        const country = countries.find((country) => country.isoAlpha2 === formValues?.countryCode || country.isoAlpha3 === formValues?.countryCode);
        return country?.name ?? formValues?.countryCode;
    };

    const handleSubmit = useCallback((values?: Address) => {
        const errors: FormErrorValues<Address> = {};
        if (StringUtils.isNullOrWhitespace(values?.countryCode)) {
            errors.countryCode = "You must select a country";
        }

        setFormErrors(errors);
        return !hasErrors(errors);
    }, []);

    const handleNavigation = useCallback((option: FlowNavigationDirection) => {
        if (option === "forward" && values?.countryCode && values?.countryCode !== "US") {
            setUnsupportedCountry(true);
            return;
        } else if (option === "forward" && !handleSubmit(values)) {
            return;
        } else if (option === "backward" && unsupportedCountry) {
            onNavigate?.("exit");
        } else {
            onNavigate?.(option);
        }
    }, [handleSubmit, onNavigate, unsupportedCountry, values]);

    const handleCountrySelectChange = useCallback((_ev: ChangeEvent<HTMLSelectElement>, data?: string) => {
        formValuesRef.current = {
            ...formValuesRef.current,
            countryCode: data,
        };
        onValuesChange?.(prev => ({
            ...prev,
            ...formValuesRef.current,
        }));
    }, [onValuesChange]);

    return (
        <FlowStep
            {...props}
            title="What country is your organization registered in?"
            actionsProps={{
                ...props.actionsProps,
                hidePrimaryAction: unsupportedCountry === true ? true : props.actionsProps?.hidePrimaryAction,
                hideSecondaryAction: unsupportedCountry === true ? false : props.actionsProps?.hideSecondaryAction,
                secondaryActionText: unsupportedCountry ? "Close" : props.actionsProps?.secondaryActionText,
            }}
            onNavigate={handleNavigation}
        >
            {unsupportedCountry ? (
                <Flexbox direction="column" rowGap>
                    <Body2>We're sorry, OneCredential is not yet available for {countryName()}. We will contact you at your email address{me?.emailAddress && ` ${me.emailAddress}`} once it is available.</Body2>
                </Flexbox>
            ) : (
            <div>
                <Field
                    label="Country"
                    name="countryCode"
                    statusText={formErrors?.countryCode}
                >
                    <CountrySelect
                        defaultValue={values?.countryCode}
                        onChange={handleCountrySelectChange}
                    />
                </Field>
            </div>
            )}
        </FlowStep>
    );
};