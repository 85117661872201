import type { ApprovalResult, CreateApprovalResultRequest } from "../../models";

import RequestBuilder from "../RequestBuilder";

export class ApprovalResultRequestBuilder extends RequestBuilder {
    public async put(request: CreateApprovalResultRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.put<ApprovalResult>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }
}