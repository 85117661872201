import routePaths from "../../router/routePaths";

export const BackOfficeRootPage = () => {
    return (
        <div>
            <ul>
                <li>
                    <a href={routePaths.backOffice.verifications.root}>Verifications</a>
                </li>
            </ul>
        </div>
    );
};

export default BackOfficeRootPage;