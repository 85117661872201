import { StringUtils } from "../../../utils";
import { Tag } from "../../ui";
import { useMemo } from "react";

interface DocumentVerificationTagProps {
    status?: string;
    shape?: "pill" | "square";
}

export const DocumentVerificationTag = (props: Readonly<DocumentVerificationTagProps>) => {
    const { shape = "pill", status } = props;

    const displayText = useMemo(() => {
        switch (status) {
            case "pending":
                return "Pending review";
            default:
                return status ? StringUtils.toTitleCase(status) : status;
        }
    }, [status]);

    if (displayText === undefined || displayText === null) {
        return null;
    }

    return <Tag shape={shape}>{displayText}</Tag>;
};

export default DocumentVerificationTag;