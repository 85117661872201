import { Button as FuiButton, Body1, Body1Strong, Checkbox, type CheckboxOnChangeData } from "@fluentui/react-components";
import { Credential } from "../../../client";
import { Box, Flexbox } from "../../ui";
import { useEffect, useState, type ChangeEvent } from "react";
import { useAppDispatch } from "../../../hooks";
import { credentialActions } from "../../../store";
import { ChevronDown28Regular, ChevronUp28Regular } from "@fluentui/react-icons";
import { Collapse } from "@fluentui/react-motion-components-preview";
import { CredentialRequirementList } from "../lists/CredentialRequirementList";

interface CredentialCompactCardProps {
    credential: Credential;
    defaultSelected?: boolean;
    disabled?: boolean;
    expand?: boolean;
    loading?: boolean;
    selected?: boolean;
    onSelectChange?: (credential: Credential, selected: boolean) => void;
}

export const CredentialCompactCard = (props: Readonly<CredentialCompactCardProps>) => {
    const {
        credential,
        defaultSelected,
        disabled,
        loading,
        selected,
        onSelectChange,
    } = props;
    const dispatch = useAppDispatch();
    const credentialPropCount = Object.keys(credential).length;
    const hasRequirements = credential.requirements && credential.requirements.length > 0;
    const [fetchInProgress, setFetchInProgress] = useState(false);
    const [expand, setExpand] = useState(props.expand ?? false);
    const [expanded, setExpanded] = useState(props.expand ?? false);

    const handleCheckboxChange = (_ev: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        onSelectChange?.(credential, data.checked === true);
    };

    const handleCollapseMotionStart = (_ev: null, data: { direction: "enter" | "exit"; }) => {
        setExpanded(prev => {
            if (data.direction === "enter") {
                return true;
            } else {
                return prev;
            }
        });
    };

    const handleCollapseMotionFinished = (_ev: null, data: { direction: "enter" | "exit"; }) => {
        setExpanded(data.direction === "enter");
    };

    useEffect(() => {
        let action;
        if (!loading && !fetchInProgress && credential.id && credentialPropCount === 1) {
            setFetchInProgress(true);
            action = dispatch(credentialActions.getById(credential.id));
            action.finally(() => {
                setFetchInProgress(false);
            });
        }
    }, [credential.id, credentialPropCount, dispatch, fetchInProgress, loading]);

    return (
        <Box>
            <Flexbox
                fullWidth
                verticalAlign="start"
            >
                <Flexbox
                    direction="column"
                    fullWidth
                    rowGap={expanded ? true : undefined}
                >
                    <Flexbox
                        fullWidth
                        verticalAlign="start"
                    >
                        {Boolean(onSelectChange) && (
                            <Checkbox
                                defaultChecked={defaultSelected}
                                checked={selected}
                                disabled={disabled}
                                onChange={handleCheckboxChange}
                            />
                        )}
                        <Flexbox direction="column" fullWidth>
                            <Body1Strong>{credential.name}</Body1Strong>
                            <Body1>{credential.description}</Body1>
                        </Flexbox>
                        <FuiButton
                            aria-description={expand ? "Collapse requirements" : "Expand requirements"}
                            aria-expanded={expand}
                            appearance="transparent"
                            icon={expand ? <ChevronUp28Regular /> : <ChevronDown28Regular />}
                            style={{
                                visibility: hasRequirements ? "visible" : "hidden",
                            }}
                            title={expand ? "Hide requirements" : "Show requirements"}
                            onClick={() => setExpand((prev) => !prev)}
                        />
                    </Flexbox>
                    {(hasRequirements) && (
                        <Collapse
                            visible={expand}
                            unmountOnExit={false}
                            onMotionStart={handleCollapseMotionStart}
                            onMotionFinish={handleCollapseMotionFinished}
                        >
                            <CredentialRequirementList
                                requirements={credential.requirements}
                            />
                        </Collapse>
                    )}
                </Flexbox>
            </Flexbox>
        </Box>
    )
};

export default CredentialCompactCard;