import { CredentialCollectionRequestBuilder, OrganizationCollectionRequestBuilder, VerificationRequestsCollectionRequestBuilder } from "./requests";

import type { AuthenticationProvider } from "./AuthenticationProvider";
import BaseClient from "./BaseClient";
import { CatalogRequestBuilder } from "./requests/catalog";
import { FormCollectionRequestBuilder } from "./requests/forms";
import { MeRequestBuilder } from "./requests/me";
import { SearchRequestBuilder } from "./requests/search";
import { ShoppingRequestBuilder } from "./requests/shop";

export class ServiceClient {
    private _baseUrl: string;
    private _httpClient: BaseClient;

    constructor(baseUrl = "https://onecredential.io/api", authenticationProvider: AuthenticationProvider) {
        this._baseUrl = baseUrl;
        this._httpClient = new BaseClient(this._baseUrl, authenticationProvider);
    }

    public get catalog() {
        return new CatalogRequestBuilder(`${this._baseUrl}/catalog`, this._httpClient);
    }

    public get credentials() {
        return new CredentialCollectionRequestBuilder(`${this._baseUrl}/credentials`, this._httpClient);
    }

    public get me() {
        return new MeRequestBuilder(`${this._baseUrl}/me`, this._httpClient);
    }

    public get forms() {
        return new FormCollectionRequestBuilder(`${this._baseUrl}/forms`, this._httpClient);
    }

    public get organizations() {
        return new OrganizationCollectionRequestBuilder(`${this._baseUrl}/organizations`, this._httpClient);
    }

    public get search() {
        return new SearchRequestBuilder(`${this._baseUrl}/search`, this._httpClient);
    }

    public get shopping() {
        return new ShoppingRequestBuilder(`${this._baseUrl}/shop`, this._httpClient);
    }

    public get verificationRequests() {
        return new VerificationRequestsCollectionRequestBuilder(`${this._baseUrl}/verificationRequests`, this._httpClient);
    }
}

export default ServiceClient;