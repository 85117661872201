import { Breadcrumb, Loader } from "../../components";
import { organizationActions, selectSelectedOrganization } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { DigitalCertificate } from "../../components/organization/DigitalCertificate";
import { Page } from "../../layouts";
import { routePaths } from "../../router/routePaths";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const OrganizationCertificatePage = () => {
    const { organizationId } = useParams<{ organizationId: string }>();
    const organization = useAppSelector(selectSelectedOrganization);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (organizationId && organizationId !== organization?.id) {
            dispatch(organizationActions.setSelectedOrganization(organizationId));
        }
    }, [dispatch, organization?.id, organizationId]);

    useEffect(() => {
        if (organizationId) {
            dispatch(organizationActions.getById(organizationId));
        }
    }, [dispatch, organizationId]);

    return (
        <Page disableHorizontalGutters>
            <Breadcrumb
                items={[{
                    href: routePaths.dashboard,
                    text: "Dashboard"
                }, {
                    href: routePaths.organizations.view.replace(":organizationId", organization?.id ?? ""),
                    text: organization?.name ?? "...",
                }, {
                    current: true,
                    text: "Certificate",
                },
            ]}
            />
            <Loader loading={!organization}>
                <DigitalCertificate
                    organization={organization!}
                />
            </Loader>
        </Page>
    );
}