import type BaseClient from "../BaseClient";
import type { ODataCollectionQueryOptions } from "../models/ODataCollectionQueryOptions";
import { StringUtils } from "../../utils";
import { isApiObjectResponse, type ApiObjectResponse, type ApiResponse } from "../models";

export class RequestBuilder {
	private _client: BaseClient;
	private _baseUrl: string;

	constructor(baseUrl: string, client: BaseClient, scopes?: string[]) {
		this._baseUrl = baseUrl;
		this._client = client;
	}

	public get baseUrl(): string {
		return this._baseUrl;
	}

	protected get client(): BaseClient {
		return this._client;
	}

	protected _appendSegmentToUrl(url: string, urlSegment: string): string {
		const baseUrl = StringUtils.stripTrailingSlash(url.trim());
		urlSegment = StringUtils.stripLeadingSlash(urlSegment.trim());

		return baseUrl + "/" + urlSegment;
	}

	protected _appendUrlSegment(...urlSegment: (string | null | undefined)[]): string {
		const baseUrl = this.baseUrl;
		if (!urlSegment) {
			return baseUrl;
		} else {
			const path = urlSegment.filter(s => s != null).join("/");
			return this._appendSegmentToUrl(baseUrl, path);
		}
	}

	protected buildQueryString(parameters: Record<string, unknown>, encodeValues = true): string | null {
		const paramNames = Object.keys(parameters);
		if (paramNames.length === 0) {
			return null;
		}

		const encodeValue = (input: unknown): string | null => {
			if (Array.isArray(input)) {
				return input.map(v => encodeValue(v)).join(",");
			} else if (typeof input === "boolean" || typeof input === "number" || typeof input === "string") {
				return encodeURIComponent(input);
			} else {
				return null;
			}
		};

		let queryString = "";
		let sep = "";
		for (const paramName of paramNames) {
			const rawValue = parameters[paramName];
			const encodedValue = encodeValues ? encodeValue(rawValue) : rawValue;

			if (encodedValue === undefined
				|| encodedValue === null
				|| (typeof encodedValue === "string" && StringUtils.isNullOrWhitespace(encodedValue))
				|| (Array.isArray(encodedValue) && encodedValue.length > 0)) {
				continue;
			}

			queryString += sep;
			queryString += `${paramName}=${encodedValue}`;
			sep = "&";
		}

		return queryString;
	}

	protected buildOdataQueryString(options?: ODataCollectionQueryOptions): string | undefined {
		if (options === undefined || options === null) {
			return undefined;
		}

		return this.buildQueryString(options, false) ?? undefined;
	}

	protected getResponseObjectOrThrow<T>(response: ApiResponse | ApiObjectResponse<T>): ApiObjectResponse<T> {
		if (isApiObjectResponse<T>(response)) {
			return response;
		}

		throw new Error("Response body is not expected object.");
	}
}

export default RequestBuilder;
