import type { ReactNode } from "react";

interface Title1Props {
    children: ReactNode;
    color?: string;
    style?: React.CSSProperties;
    textAlignment?: "left" | "center" | "right";
}

export const Title1 = (props: Readonly<Title1Props>) => {
    const { children, color, textAlignment } = props;

    return (
        <h1
            style={{
                color,
                textAlign: textAlignment,
                fontSize: 32,
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "36px",
                letterSpacing: -0.64,
                marginTop: "unset",
                marginBottom: "unset",
            }}
        >
            {children}
        </h1>
    );
}