import type { AddOrganizationOwnerRequest, CollectionResponse, OrganizationOwner } from "../../../models";

import { OrganizationOwnerRequestBuilder } from "./OrganizationOwnerRequestBuilder";
import RequestBuilder from "../../RequestBuilder";

export class OrganizationOwnerCollectionRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<CollectionResponse<OrganizationOwner>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async put(request: ReadonlyArray<AddOrganizationOwnerRequest>) {
        const requestUrl = this.baseUrl;
        const response = await this.client.put(requestUrl, request);
        return response;
    }

    public owner(ownerId: string) {
        return new OrganizationOwnerRequestBuilder(this._appendUrlSegment(ownerId), this.client);
    }
}

export default OrganizationOwnerCollectionRequestBuilder;