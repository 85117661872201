import type { Organization, OrganizationVerificationRequest, VerificationDocument } from "../client";

export function isDocumentPending(doc: VerificationDocument) {
    return !doc.approvalResult;
}

export function isDocumentApproved(doc: VerificationDocument) {
    return doc.approvalResult?.value?.toUpperCase() === "APPROVED";
}

export function isDocumentPendingOrApproved(doc: VerificationDocument) {
    return isDocumentPending(doc) || isDocumentApproved(doc);
}

export function selectPendingOrApprovedDocuments(organizationOrOrganizationVerification?: Partial<Organization | OrganizationVerificationRequest>) {
    if (!organizationOrOrganizationVerification) {
        return null;
    }

    if ("verificationRequests" in organizationOrOrganizationVerification) {
        const openVerificationRequests = organizationOrOrganizationVerification.verificationRequests?.filter((request) => request.state?.toUpperCase() === "OPEN");
        return openVerificationRequests?.flatMap((request) => request.documents?.filter(isDocumentPendingOrApproved)).filter(doc => doc !== undefined && doc !== null) ?? null;
    } else if ("documents" in organizationOrOrganizationVerification) {
        return organizationOrOrganizationVerification.documents?.filter(isDocumentPendingOrApproved) ?? null;
    }
}