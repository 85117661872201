type ComparableType = boolean | string | number;

export class EqualityUtils {
	public static arrayCompare(leftOperand: unknown, rightOperand: unknown, ignoreOrder = false) {
		if (!Array.isArray(leftOperand) || !Array.isArray(rightOperand) || leftOperand.length !== rightOperand.length) {
			return false;
		}

		let arrayLeft;
		let arrayRight;
		if (ignoreOrder === true) {
			arrayLeft = leftOperand.concat().sort(this._compare);
			arrayRight = rightOperand.concat().sort(this._compare);
		} else {
			arrayLeft = leftOperand;
			arrayRight = rightOperand;
		}

		for (let i = 0; i < arrayLeft.length; i++) {
			if (arrayLeft[i] !== arrayRight[i]) {
				return false;
			}
		}

		return true;
	}

	public static deepEquals<T extends object>(objectA: T | null, objectB: T | null, ignoreArrayOrder = false): boolean {
		if (!objectA && !objectB) {
			return true;
		} else if (objectA ? !objectB : objectB) {
			return false;
		}

		const aProps = Object.getOwnPropertyNames(objectA);
		const bProps = Object.getOwnPropertyNames(objectB);

		if (!this.arrayCompare(aProps, bProps, true) || (objectA instanceof Date && objectB instanceof Date && objectA.getTime() !== objectB.getTime())) {
			return false;
		}

		for (const propName of aProps) {
			const propA: unknown = objectA?.[propName as keyof typeof objectA];
			const propB: unknown = objectB?.[propName as keyof typeof objectB];

			if (typeof propA === "object" && typeof propB === "object") {
				if (Array.isArray(propA) && Array.isArray(propB) && this.arrayCompare(propA, propB, ignoreArrayOrder)) {
					continue;
				} else if (!this.deepEquals(propA, propB, ignoreArrayOrder)) {
					return false;
				}
			} else if (propA !== propB) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Checks whether two operands are equal.
	 * Operands of different types will return false.
	 *
	 * @static
	 * @param {unknown} a
	 * @param {unknown} b
	 * @param {boolean} [deepEquals=false]
	 * @param {boolean} [ignoreArrayOrder=false]
	 * @return {*}  {boolean}
	 * @memberof EqualityUtils
	 */
	public static strictEquals(a: unknown, b: unknown, deepEquals: boolean = false, ignoreArrayOrder: boolean = false): boolean {
		if (typeof a === "object" && typeof b === "object" && deepEquals) {
			return this.deepEquals(a, b, ignoreArrayOrder);
		}

		return a === b;
	}

	/**
	 * Checks whether two operands are equal.
	 * Operands of different types will attempt to convert and compare.
	 *
	 * @static
	 * @param {unknown} a
	 * @param {unknown} b
	 * @param {boolean} [deepEquals=false]
	 * @param {boolean} [ignoreArrayOrder=false]
	 * @return {*}  {boolean}
	 * @memberof EqualityUtils
	 */
	public static equals(a: unknown, b: unknown, deepEquals: boolean = false, ignoreArrayOrder: boolean = false): boolean {
		if (typeof a === "object" && typeof b === "object" && deepEquals) {
			return this.deepEquals(a, b, ignoreArrayOrder);
		}

		// eslint-disable-next-line eqeqeq
		return a == b;
	}

	private static _compare(a: ComparableType, b: ComparableType): number {
		if (a < b) {
			return -1;
		 } else if (a > b) {
			return 1;
		 } else {
			return 0;
		 }
	}
}

export default EqualityUtils;