import { createAsyncThunk } from "@reduxjs/toolkit";
import { serviceClient } from "../../serviceClient";

const get = createAsyncThunk(
    "acceptableDocuments/get",
    async(nextLink?: string) => {
        const response = await serviceClient.catalog.acceptableDocuments.get(nextLink);
        return response.data;
    },
);

export const acceptableDocumentActions = {
    get,
};