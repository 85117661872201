import type { PutUserRequest, User } from "../../models";

import RequestBuilder from "../RequestBuilder";

export class MeRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<User>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async patch(request: Partial<PutUserRequest>) {
        const requestUrl = this.baseUrl;
        const response = await this.client.patch<void>(requestUrl, request);
        return response;
    }

    public async put(request: PutUserRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.put<User>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }
}