import { InfoRegular } from "@fluentui/react-icons";
import React from "react";
import { Tooltip } from "@fluentui/react-components";

interface InfoLabelProps {
    children: React.ReactNode;
    info: string | (() => React.JSX.Element);
    openOnHover?: boolean;
}

export const InfoLabel = (props: Readonly<InfoLabelProps>) => {
    const { children, info } = props;
    const rootRef = React.useRef<HTMLSpanElement>(null);

    return (
        <span
            ref={rootRef}
            style={{ display: "inline-flex", alignItems: "center", columnGap: 2 }}
        >
            {children}
            <Tooltip
                content={{
                    children: typeof info === "string" ? info : info(),
                }}
                relationship="description"
                withArrow
            >
                <InfoRegular
                    style={{
                        cursor: "pointer",
                    }}
                />
            </Tooltip>
        </span>
    );
};

export default InfoLabel;