import { Box, Button, Flexbox, Loader } from "components/ui";

import { FeatureLocked } from "components/common";
import type { Organization } from "client";
import React from "react";
import { Title3 } from "@fluentui/react-components";
import routePaths from "../../../router/routePaths";
import { useNavigate } from "react-router-dom";

interface OrganizationCredentialsCardProps {
    loading?: boolean;
    organization?: Organization | null;
}

export const OrganizationCredentialsCard = (props: Readonly<OrganizationCredentialsCardProps>) => {
    const { loading, organization } = props;
    const navigate = useNavigate();

    const handleAddCredentialButtonClick = React.useCallback(() => {
        if (organization?.id) {
            navigate(routePaths.organizations.credential.replace(":organizationId", organization.id));
        }
    }, [navigate, organization?.id]);

    return (
        <Flexbox direction="column" rowGap={8}>
            <Title3>Credentials</Title3>
            <Box>
                <div style={{ display: "flex", flexDirection: "column", rowGap: 8 }}>
                    <Loader loading={loading}>
                        <FeatureLocked
                            locked={!organization?.verification}
                            message="Your organization must be verified before a credential can be requested."
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    columnGap: 8,
                                    rowGap: 12,
                                    flexGrow: 1,
                                }}
                            >
                                {(organization?.credentials?.length && organization.credentials.length > 0) ? (
                                    <Flexbox direction="column" rowGap>
                                        {organization.credentials.map((credential) => (
                                            <Box key={credential.credentialId}>
                                                <div><b>{credential.credential?.name}</b></div>
                                                <div>{credential.state}</div>
                                            </Box>
                                        ))}
                                    </Flexbox>
                                ) : (
                                    <Flexbox horizontalAlign="center">
                                        <span>There are no credentials assigned to this organization.</span>
                                    </Flexbox>
                                )}
                                <Button
                                    variant="primary"
                                    onClick={handleAddCredentialButtonClick}
                                >
                                    Add credential
                                </Button>
                            </div>
                        </FeatureLocked>
                    </Loader>
                </div>
            </Box>
        </Flexbox>
    );
};

export default OrganizationCredentialsCard;