import type { CollectionResponse, Credential } from "../../models";

import { CredentialRequestBuilder } from "./CredentialRequestBuilder";
import type { ODataCollectionQueryOptions } from "../../models/ODataCollectionQueryOptions";
import RequestBuilder from "../RequestBuilder";

export class CredentialCollectionRequestBuilder extends RequestBuilder {
    public async get(nextLinkOrQueryOptions?: string | ODataCollectionQueryOptions) {
        const requestUrl = typeof(nextLinkOrQueryOptions) === "string" ? nextLinkOrQueryOptions : this.baseUrl;
        const response = await this.client.get<CollectionResponse<Credential>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public credential(credentialId: string) {
        return new CredentialRequestBuilder(this._appendUrlSegment(credentialId), this.client);
    }
}

export default CredentialCollectionRequestBuilder;