import { Box, Button, Flexbox } from "../../ui";

import { Body1 } from "@fluentui/react-components";
import { DateUtils } from "../../../utils";
import type { OrganizationVerification } from "../../../client";
import routePaths from "../../../router/routePaths";
import { useNavigate } from "react-router-dom";

interface OrganizationCertificateCardProps {
    organizationId: string;
    verification: OrganizationVerification;
}

export const OrganizationCertificateCard = (props: Readonly<OrganizationCertificateCardProps>) => {
    const { organizationId, verification } = props;
    const navigate = useNavigate();

    const handleViewBtnClick = () => {
        if (organizationId) {
            navigate(routePaths.organizations.viewCertificate.replace(":organizationId", organizationId));
        } else {
            throw new Error("Organization ID is required to view certificate");
        }
    };

    return (
        <Box>
            <Flexbox
                horizontalAlign="space-between"
                verticalAlign="center"
            >
                <Flexbox direction="column" rowGap>
                    <Body1>Certificate valid until <b>{DateUtils.formatV2(verification.expirationDate, "MMMM Do, YYYY")}</b></Body1>
                </Flexbox>
                <Flexbox
                    columnGap={8}
                    verticalAlign="center"
                >
                    <Button
                        text="View"
                        variant="primary"
                        onClick={handleViewBtnClick}
                    />
                </Flexbox>
            </Flexbox>
        </Box>
    );
};

export default OrganizationCertificateCard;