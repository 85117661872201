import { useCallback, useEffect } from "react";

import { Divider } from "@fluentui/react-components";
import type { FlowNavigationDirection } from "../../../ui";
import { FlowStep } from "components/ui/flow/FlowStep";
import type { OrganizationSetupStepProps } from "./types";
import { StringUtils } from "../../../../utils";
import { VerificationStepsListItem } from "../../VerificationStepListItem";
import routePaths from "../../../../router/routePaths";
import { serviceClient } from "../../../../serviceClient";
import { useNavigate } from "react-router-dom";

export const AddOrganizationCompletedFlowStep = (props: Readonly<OrganizationSetupStepProps>) => {
    const { values, onNavigate } = props;
    const navigate = useNavigate();

    const handleStepNavigation = useCallback((option: FlowNavigationDirection) => {
        if (option !== "forward") {
            navigate(routePaths.dashboard);
            return;
        }

        const organizationId = values?.organizationId;
        if (StringUtils.isNullOrWhitespace(organizationId)) {
            throw new Error("Organization ID is not provided.");
        }

        serviceClient.organizations
            .organization(organizationId).verificationRequests
            .create()
            .then((response) => {
                if (response.statusCode < 400 || response.statusCode === 409) {
                    navigate(routePaths.organizations.verify.replace(":organizationId", organizationId!));
                }
            });

    }, [navigate, values?.organizationId]);

    useEffect(() => {
        if (StringUtils.isNullOrWhitespace(values?.organizationId)) {
            onNavigate?.("backward");
        }
    }, [values?.organizationId, onNavigate]);

    return (
        <FlowStep
            {...props}
            title="Get verified"
            actionsProps={{
                secondaryActionText: "Complete later",
                primaryActionText: "Get started",
            }}
            onNavigate={handleStepNavigation}
        >
            <div>
                <p>Great! The next step is for <b>{values?.organizationName}</b> to become verified. Verified organizations are eligible to be credentialed and be exposed to our many corporate partners for business opportunities. Ready?</p>
                <p>For verification, you'll need to show that <b>{values?.organizationName ?? "your organization"}</b> is real and you have a legitimate connection to it.</p>
                <h4>What's involved</h4>
                <Divider />
                <ul>
                    <VerificationStepsListItem
                        description="Confirm your connection"
                        details="Select a way for us to get in touch to confirm your connection to the business."
                    />
                    <VerificationStepsListItem
                        description="Provide documentation"
                        details="You will need to upload accepted documents to confirm these details of your organization."
                    />
                </ul>
            </div>
        </FlowStep>
    );
};