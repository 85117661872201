import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";

import { Flexbox } from "../components";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";
import type { PropsWithChildren } from "react";
import { useMsal } from "@azure/msal-react";

export const FullscreenLayout: React.FC<PropsWithChildren> = () => {
    const { instance: msal } = useMsal();

    const handleSignOutClick = () => {
        msal.logoutRedirect();
    };

    const account = msal.getActiveAccount();

    return (
        <div>
            <div>
                <Header>
                    <Flexbox horizontalAlign="end" fullHeight grow>
                        {account && (
                            <div
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <Menu>
                                    <MenuTrigger>
                                        <MenuButton>{account.name === "unknown" ? account.username : account.name}</MenuButton>
                                    </MenuTrigger>
                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </div>
                        )}
                    </Flexbox>
                </Header>
            </div>
            <div>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default FullscreenLayout;