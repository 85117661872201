import type { OrganizationOwner } from "../../../models";
import RequestBuilder from "../../RequestBuilder";

export class OrganizationOwnerRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<OrganizationOwner>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async patch(request: any) {
        const requestUrl = this.baseUrl;
        const response = await this.client.patch(requestUrl, request);
        return response;
    }

    public async delete() {
        const requestUrl = this.baseUrl;
        const response = await this.client.delete(requestUrl);
        return response;
    }
}

export default OrganizationOwnerRequestBuilder;