import { Tree, TreeItem } from "@fluentui/react-components";
import React, { useCallback, useEffect, useRef, useState, type SetStateAction } from "react";
import type { FlowNavigationDirection, FlowStepActionsProps, FlowStepItem, FlowStepItemProps, FlowStepItemState, FlowStepProps, FlowStepSection } from "./types";
import { Flexbox } from "../layout";
import { CheckmarkCircleFilled, ClockRegular } from "@fluentui/react-icons";

interface NavigationItem {
    icon?: React.ReactNode;
    label: string;
    isSection: boolean;
    stepIndex?: number;
    children?: NavigationItem[];
    sectionKey?: string;
    state?: FlowStepItemState;
}

export type FlowValuesType = Record<string, any>;

interface FlowProps<TValues extends FlowValuesType> {
    loading?: boolean;
    items: ReadonlyArray<FlowStepItem>;
    propsToPass?: Record<string, any>;
    values?: TValues;
    onValuesChange?: (values: SetStateAction<TValues>) => void;
    onExit?: () => void;
}

export function Flow<TValues extends FlowValuesType>(props: Readonly<FlowProps<TValues>>) {
    const { loading, items, values: initialValues, propsToPass, onExit, onValuesChange } = props;
    const [steps, setSteps] = useState<Array<FlowStepProps & { name: string; element: any }>>([]);
    const [navigationItems, setNavigationItems] = useState<NavigationItem[]>([]);

    const [sectionCollapse, setSectionCollapse] = useState<Record<string, boolean>>({});

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const activeStep = steps[activeStepIndex];

    const [values, setValues] = useState<TValues>(initialValues as TValues);
    const railRef = useRef<HTMLDivElement>(null);

    const buildNavigation = useCallback(() => {
        let navigableSteps: Array<FlowStepProps & { name: string; element: any }> = [];
        let navItems: NavigationItem[] = [];
        let sectionStates: Record<string, boolean> = {};
        let stepIndexCounter = 0;

        items.forEach((item, idx) => {
            const { state, icon } = item;
            if ("steps" in item) {
                const section = item as FlowStepSection;
                const sectionKey = `section-${idx}-${section.title}`;
                sectionStates[sectionKey] = section.collapsed ?? false;
                const children: NavigationItem[] = [];
                section.steps.forEach((step) => {
                    if (step.hidden) {
                        return;
                    }

                    children.push({
                        label: step.name,
                        isSection: false,
                        stepIndex: stepIndexCounter,
                        icon: icon,
                        state: state,
                    });
                    navigableSteps.push(step as FlowStepItemProps);
                    stepIndexCounter++;
                });
                navItems.push({
                    label: section.title,
                    isSection: true,
                    sectionKey,
                    children,
                    icon: icon,
                    state: state,
                });
            } else {
                const step = item as FlowStepItemProps;
                if ("hidden" in step && step.hidden) {
                    return;
                }

                navItems.push({
                    label: step.name,
                    isSection: false,
                    stepIndex: stepIndexCounter,
                    icon: icon,
                    state: state,
                });
                navigableSteps.push(step);
                stepIndexCounter++;
            }
        });

        setSteps(navigableSteps);
        setNavigationItems(navItems);
        setSectionCollapse(sectionStates);
    }, [items]);

    const renderNavigationItems = (items: NavigationItem[]) => {
        return items.map((item) => {
            let stateIcon: React.ReactNode = null;
            if (!item.isSection && typeof item.stepIndex === "number") {
                if (item.stepIndex < activeStepIndex || item.state === "completed") {
                    stateIcon = <CheckmarkCircleFilled style={{ color: "green" }} />;
                } else if (item.stepIndex === activeStepIndex || item.state === "active") {
                    stateIcon = <ClockRegular style={{ color: "#D6740A" }} />;
                }
            }

            if (item.isSection) {
                let allCompleted;
                let hasActive;
                if (item.children && item.children.length > 0) {
                    const childIndices = item.children.map(child => child.stepIndex ?? -1);
                    hasActive = childIndices.includes(activeStepIndex);
                    allCompleted = childIndices.length > 0 && childIndices.every(idx => idx < activeStepIndex);
                }

                if (item.state === "active" || hasActive) {
                    stateIcon = <ClockRegular style={{ color: "#D6740A" }} />;
                } else if (item.state === "completed" || allCompleted) {
                    stateIcon = <CheckmarkCircleFilled style={{ color: "green" }} />;
                }
            }

            const content = (
                <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ width: 24, display: "inline-block", marginRight: 8 }}>
                            {item.icon ? item.icon : null}
                        </span>
                        <span>{item.label}</span>
                    </span>
                    <span style={{ width: 24, display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "flex-end", textAlign: "right" }}>
                        {stateIcon}
                    </span>
                </span>
            );

            if (item.isSection) {
                const collapsed = sectionCollapse[item.sectionKey!];
                return (
                    <TreeItem
                        key={item.sectionKey}
                        itemType="branch"
                        aria-label={item.label}
                        style={{
                            cursor: "default",
                            fontSize: "16px",
                            paddingBottom: 8,
                            paddingTop: 8,
                        }}
                    >
                        {content}
                        {!collapsed && item.children && item.children.length > 0 && (
                            <>{renderNavigationItems(item.children)}</>
                        )}
                    </TreeItem>
                );
            } else {
                return (
                    <TreeItem
                        key={item.stepIndex}
                        aria-label={item.label}
                        itemType="leaf"
                    >
                        {content}
                    </TreeItem>
                );
            }
        });
    };

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        if (direction === "forward") {
            setActiveStepIndex(prev => prev >= (steps.length - 1) ? prev : prev + 1);
        } else if (direction === "backward") {
            setActiveStepIndex(prev => prev === 0 ? prev : prev - 1);
        } else if (typeof direction === "number") {
            if (direction < 0 || direction >= steps.length) {
                throw new Error(`Invalid step index: ${direction}`);
            }

            setActiveStepIndex(direction);
        } else if (direction === "exit") {
            onExit?.();
        } else {
            let stepIndex = items.findIndex((step) => "name" in step && step.name === direction);
            if (stepIndex !== -1) {
                setActiveStepIndex(stepIndex);
            }
        }
    }, [steps.length, onExit, items]);

    const handleValuesChange = useCallback((values: SetStateAction<TValues>) => {
        setValues((prev) => {
            let nextValues;
            if (typeof values === "function") {
                nextValues = values(prev);
            } else {
                nextValues = values;
            }

            onValuesChange?.(nextValues);

            return nextValues;
        });
    }, [onValuesChange]);

    useEffect(() => {
        buildNavigation();
    }, [items, buildNavigation]);

    return (
        <Flexbox
            fullHeight
            fullWidth
            columnGap
            style={{ overflowY: "hidden" }}
        >
            <div style={{ minWidth: 218 }}>
                <div style={{ marginLeft: "auto", marginRight: "auto", width: "min-content" }}>
                    <Tree
                        aria-label="Steps"
                    >
                        {renderNavigationItems(navigationItems)}
                    </Tree>
                </div>
            </div>
            <div className="full-height" style={{ width: "100%", maxWidth: 740, }}>
                <div className="full-height" style={{ padding: "0px 24px", width: "100%" }}>
                    {(activeStep?.element) && (
                        React.createElement(activeStep.element, {
                            ...activeStep,
                            ...propsToPass,
                            loading,
                            actionsProps: {
                                hideSecondaryAction: activeStepIndex === 0,
                                primaryActionText: activeStepIndex === (steps.length - 1) ? "Close" : "Continue",
                                // ...actionsProps,
                            } satisfies FlowStepActionsProps,
                            railRef: railRef,
                            values: values,
                            onNavigate: handleStepNavigation,
                            onValuesChange: handleValuesChange,
                        })
                    )}
                </div>
            </div>
            <div ref={railRef} style={{ flexGrow: 1, position: "relative", maxWidth: "calc(100% - 740px - 218px)", overflowX: "clip", overflowY: "auto" }}>
            </div>
        </Flexbox>
    );
};

export default Flow;