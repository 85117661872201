import { createDarkTheme, createLightTheme, type BrandVariants, type Theme } from "@fluentui/react-components";

const brandTheme: BrandVariants = {
    10: "#020306",
    20: "#121725",
    30: "#182542",
    40: "#1B315A",
    50: "#1C3D73",
    60: "#1B498E",
    70: "#1856A9",
    80: "#1064C5",
    90: "#1D71DB",
    100: "#497FE0",
    110: "#658DE4",
    120: "#7C9BE8",
    130: "#92A9EC",
    140: "#A7B8F0",
    150: "#BBC7F3",
    160: "#CED7F7"
};

export const brandThemeLight: Theme = {
    ...createLightTheme(brandTheme),
};

export const brandThemeDark: Theme = {
    ...createDarkTheme(brandTheme),
};

brandThemeDark.colorBrandForeground1 = brandTheme[110];
brandThemeDark.colorBrandForeground2 = brandTheme[120];