import { CredentialRequestResponseRequestBuilder } from "./CredentialRequestResponseRequestBuilder";
import DocumentCollectionRequestBuilder from "../../documents/DocumentCollectionRequestBuilder";
import type { OrganizationCredentialRequestItem } from "../../../models";
import RequestBuilder from "../../RequestBuilder";

export class OrganizationCredentialRequestItemBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<OrganizationCredentialRequestItem>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async patch(request: Partial<OrganizationCredentialRequestItem>) {
        const requestUrl = this.baseUrl;
        const response = await this.client.patch<OrganizationCredentialRequestItem>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }

    public get documents() {
        return new DocumentCollectionRequestBuilder(this._appendUrlSegment("documents"), this.client);
    }

    public get response() {
        return new CredentialRequestResponseRequestBuilder(this._appendUrlSegment("response"), this.client);
    }
}