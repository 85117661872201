import React from "react";

export type ModalExitCallback = () => void;

interface ModalLayoutContextProps {
    subscribe: (callback: ModalExitCallback) => () => void;
}

export const ModalLayoutContext = React.createContext<ModalLayoutContextProps>({
    subscribe: () => () => {},
});