import { useCallback, useEffect, useRef, useState } from "react";

import { EmbeddedCheckout } from "components/payments";
import { Loader, type FlowNavigationDirection } from "components/ui";
import { FlowStep } from "components/ui/flow/FlowStep";
import type { VerificationStepProps } from "./types";
import { serviceClient } from "../../../../serviceClient";
import { useAppDispatch } from "../../../../hooks";
import { organizationActions } from "../../../../store";

export const OrganizationVerificationPaymentFlowStep = (props: Readonly<VerificationStepProps>) => {
    const { organization, verificationRequest, onNavigate, ...stepProps } = props;
    const [checkoutCompleted, setCheckoutCompleted] = useState<boolean>(false);
    const lastReloadDateRef = useRef<Date|undefined>(undefined);
    const dispatch = useAppDispatch();

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        if (direction === "forward" && !checkoutCompleted) {
            return;
        }

        onNavigate?.(direction);
    }, [checkoutCompleted, onNavigate]);

    const getSessionSecret = useCallback(async () => {
        if (!verificationRequest?.id) {
            throw new Error("Verification request is not available.");
        }

        const response = await serviceClient.organizations
            .organization(organization.id).verificationRequests
            .verificationRequest(verificationRequest.id)
            .createCheckoutSession({});
        return response.data.sessionSecret;
    }, [organization?.id, verificationRequest?.id]);

    const handleCheckoutComplete = useCallback(() => {
        setCheckoutCompleted(true);
    }, []);

    useEffect(() => {
        const reloadOrganization = async () => {
            lastReloadDateRef.current = new Date();
            dispatch(organizationActions.getById(organization.id));
        };

        if (!verificationRequest?.id || !verificationRequest?.submittedAt) {
            if (lastReloadDateRef.current) {
                onNavigate?.("backward");
            } else {
                reloadOrganization();
            }
        }
    }, [verificationRequest?.id, onNavigate, verificationRequest?.submittedAt, dispatch, organization.id]);

    return (
        <FlowStep
            {...stepProps}
            description="The verification fee helps cover the cost of securely validating your organization information across multiple trusted sources. This process ensures accuracy, prevents fraud, and maintains the integrity of our credentialing system, giving you and your organization greater credibility and trust in the marketplace."
            actionsProps={{
                disablePrimaryAction: !checkoutCompleted,
            }}
            onNavigate={handleStepNavigation}
        >
            <Loader loading={!verificationRequest?.id}>
                <EmbeddedCheckout
                    getSessionSecret={getSessionSecret}
                    onComplete={handleCheckoutComplete}
                />
            </Loader>
        </FlowStep>
    );
};

export default OrganizationVerificationPaymentFlowStep;