import { Button, Flexbox } from "../components/ui";
import React, { useCallback, useEffect } from "react";
import { organizationActions, selectAllOrganizations } from "../store";
import { useAppDispatch, useAppSelector } from "../hooks";

import { Loader } from "../components";
import type { Organization } from "../client";
import OrganizationCardList from "../components/OrganizationCardList";
import OrganizationListEmptyState from "../components/OrganizationListEmptyState";
import ReadMoreAboutCredentialing from "../components/ReadMoreAboutCredentialing";
import { Title1 } from "@fluentui/react-components";
import routePaths from "../router/routePaths";
import { useNavigate } from "react-router-dom";

export const DashboardPage: React.FC = () => {
    const organizations = useAppSelector(selectAllOrganizations);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const me = useAppSelector(s => s.me.me);
    const [loading, setLoading] = React.useState<boolean>(!organizations || organizations.length === 0);

    const handleAddOrganizationBtnClick = () => {
        navigate(routePaths.organizations.add);
    };

    const handleListItemClick = useCallback((_e: React.MouseEvent<HTMLElement>, organization: Organization) => {
        navigate(routePaths.organizations.view.replace(":organizationId", organization.id));
    }, [navigate]);

    useEffect(() => {
        const action = dispatch(organizationActions.get());
        action.finally(() => {
            setLoading(false);
        });
    }, [dispatch]);

    return (
        <Flexbox
            direction="column"
            fullHeight
            rowGap
            style={{
                boxSizing: "border-box",
                paddingLeft: 48,
                paddingRight: 48,
                marginLeft: "auto",
                marginRight: "auto",
                overflowY: "hidden",
                maxWidth: 1048,
            }}
        >
            <Flexbox horizontalAlign="space-between" style={{ paddingTop: "0.84em", paddingBottom: "0.84em" }}>
                <Title1>Welcome, {me?.givenName ?? me?.emailAddress}</Title1>
                <Flexbox verticalAlign="end">
                    <Button
                        size="large"
                        text="Add organization"
                        variant="primary"
                        onClick={handleAddOrganizationBtnClick}
                    />
                </Flexbox>
            </Flexbox>
            <Flexbox
                columnGap
                horizontalAlign="center"
                verticalAlign="start"
            >
                <Flexbox
                    horizontalAlign="center"
                    grow
                    style={{
                        height: "100%",
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        minWidth: "min-content",
                    }}
                >
                    <Loader loading={loading}>
                        {organizations?.length > 0 ? (
                            <OrganizationCardList
                                organizations={organizations}
                                onListItemClick={handleListItemClick}
                            />
                        ) : (
                            <OrganizationListEmptyState />
                        )}
                    </Loader>
                </Flexbox>
                <Flexbox
                    direction="column"
                    fullHeight
                    rowGap
                    style={{
                        flexBasis: 0,
                        minWidth: 294,
                    }}
                    verticalAlign="start"
                >
                    <ReadMoreAboutCredentialing />
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};

export default DashboardPage;