import type { Credential } from "../../models";
import RequestBuilder from "../RequestBuilder";

export class CredentialRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<Credential>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }
}

export default CredentialRequestBuilder;