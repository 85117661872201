import { Flexbox, Title2 } from "../../../ui";

import { FlowStep } from "../../../ui/flow/FlowStep";
import type { OrganizationSetupStepProps } from "./types";
import type React from "react";
import { useMemo } from "react";

const sectionRowGap = 8;

interface InstructionStepProps {
    icon?: string | React.ReactNode;
    primaryText: string;
    secondaryText?: string;
}

export const InstructionStep = (props: Readonly<InstructionStepProps>) => {
    const {
        primaryText,
        secondaryText,
        icon,
    } = props;

    const iconContent = useMemo(() => {
        if (typeof icon === "string") {
            return <img src={icon} alt={primaryText} />;
        } else {
            return icon;
        }
    }, [icon, primaryText]);

    return (
        <Flexbox columnGap={16}>
            <div
                style={{
                    boxSizing: "border-box",
                    height: 48,
                    width: 48,
                }}
            >
                {iconContent && (
                    <div
                        style={{
                            background: "var(--Interactive-sc-interactive, #CDE5FE)",
                            borderRadius: 100,
                            boxSizing: "border-box",
                            padding: 12,
                            height: "100%",
                            width: "100%",
                        }}>
                        {iconContent}
                    </div>
                )}
            </div>
            <Flexbox direction="column" rowGap={4}>
                <b>{primaryText}</b>
                {secondaryText && <span>{secondaryText}</span>}
            </Flexbox>
        </Flexbox>
    )
}

export const AddOrganizationIntroFlowStep = (props: Readonly<OrganizationSetupStepProps>) => {
    const { values, onValuesChange, ...stepProps } = props;

    return (
        <FlowStep
            {...stepProps}
            title="Get verified with OneCredential"
            description="Eliminate paperwork and seamlessly verify identities and businesses, ensuring automated, compliant, and instant certification!"
        >
            <Flexbox direction="column" rowGap>
                <Flexbox direction="column" rowGap={sectionRowGap}>
                    <Title2>Verify your business</Title2>
                    <Flexbox direction="column" rowGap={24}>
                        <InstructionStep
                            primaryText="Get verified with OneCredential"
                            secondaryText="Add the legal name, registered address, and other details about the business."
                            icon="/static/imgs/add.svg"
                        />
                        <InstructionStep
                            primaryText="Provide Business owner details"
                            secondaryText="Share the names of all owners of the business, their ownership percentage, and email address."
                            icon="/static/imgs/add.svg"
                        />
                        <InstructionStep
                            primaryText="Upload documents"
                            secondaryText="Upload your documents for secure verification, ensuring accuracy and fraud prevention."
                            icon="/static/imgs/upload.svg"
                        />
                        <InstructionStep
                            primaryText="Summary & Payment"
                            secondaryText="Complete your business verification for $149 to proceed."
                            icon="/static/imgs/wallet.svg"
                        />
                    </Flexbox>
                </Flexbox>
                <Flexbox direction="column" rowGap={sectionRowGap}>
                    <Title2>Verification</Title2>
                    <span>Your verification is processed instantly, giving buyers and suppliers confidence that they are working with a credible and trustworthy business. To proceed with credentialing, your company must first be verified. Once verified, you can obtain as many credentials as needed to enhance your business's credibility and opportunities.</span>
                </Flexbox>
                <Flexbox direction="column" rowGap={sectionRowGap}>
                    <Title2>Verify your business</Title2>
                    <Flexbox direction="column" rowGap={24}>
                        <InstructionStep
                            primaryText="Apply for credentials"
                            secondaryText="Select the type of credentialing that applies to your business. Choose from Veteran-Owned, Disability-Owned, Women-Owned, or Ethnic Minority-Owned business certifications. These credentials help showcase your business's unique qualifications and open doors to new opportunities."
                            icon="/static/imgs/add.svg"
                        />
                        <InstructionStep
                            primaryText="Upload supporting evidence"
                            secondaryText="Depending on the credential you are applying for, you may need to provide supporting documentation to verify your eligibility."
                            icon="/static/imgs/upload.svg"
                        />
                        <InstructionStep
                            primaryText="Notarization"
                            secondaryText="Sign and notarize your application to confirm the information you provided is accurate."
                            icon="/static/imgs/verified.svg"
                        />
                    </Flexbox>
                </Flexbox>
                <Flexbox direction="column" rowGap={sectionRowGap}>
                    <Title2>Instant digital notarization also known as Remote Online Notarization (RON)</Title2>
                    <span>Our secure digital notarization process verifies identities in real time, adding an extra layer of fraud prevention. By officially certifying documents, we ensure that only authorized individuals complete the credentialing process. Complete your notarization instantly online or schedule it for a later time to finalize your application.</span>
                </Flexbox>
                <Flexbox direction="column" rowGap={sectionRowGap}>
                    <Title2>Legal notice</Title2>
                    <span style={{ color: "#52525B" }}>By submitting documents or information for business verification, you acknowledge that this information will be used to help us comply with legal obligations and prevent misuse, misrepresentation, and fraud. This process is designed to protect business owners, their business information, and the broader ecosystem.All submitted documents will be securely stored for up to one year, and in rare cases, for a longer period as required by regulatory or compliance needs. Additionally, certain verified Transparency Information may be displayed on your publicly available business page. You will have the option to manage the visibility of specific details. For more information, please review our <a href="https://onecredential.io/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://onecredential.io/terms" target="_blank" rel="noreferrer">Terms of Service</a>.</span>
                </Flexbox>
            </Flexbox>
        </FlowStep>
    );
};

export default AddOrganizationIntroFlowStep;