import { Copyable, InfoLabel } from "../../ui";

import { Body1Strong } from "@fluentui/react-components";

interface OrgCodeLabelProps {
    orgCode?: string;
}

export const OrgCodeLabel = (props: Readonly<OrgCodeLabelProps>) => {
    const { orgCode } = props;

    return (
        <span style={{ display: "inline-flex" }}>
            <InfoLabel
                info={"The Org Code is a unique identifier for your organization that can be shared with your customers and partners to view your digital certificate."}
                openOnHover={true}
            >
                <Body1Strong>
                    Org Code
                </Body1Strong>
            </InfoLabel>:
            <Copyable>
                <Body1Strong style={{ paddingLeft: 2 }}>{orgCode}</Body1Strong>
            </Copyable>
        </span>
    )
}