import { Box, Flexbox, Tag } from "./ui";
import { Button, MessageBar, MessageBarBody, Title3 } from "@fluentui/react-components";
import { useCallback, useMemo } from "react";

import { ChevronRight48Regular } from "@fluentui/react-icons";
import { OrgCodeLabel } from "./organization/data";
import { Organization } from "../client";

interface OrganizationCardListItemProps {
    organization: Organization;
    onDetailsBtnClick?: (e: React.MouseEvent<HTMLButtonElement>, organization: Organization) => void;
}

const OrganizationCardListItem = (props: Readonly<OrganizationCardListItemProps>) => {
    const { organization, onDetailsBtnClick } = props;

    const openVerificationRequest = useMemo(() => {
        return organization?.verificationRequests?.find(request => request.state?.toUpperCase() === "OPEN");
    }, [organization?.verificationRequests]);

    const openVerificationRequestStatus = useMemo(() => openVerificationRequest?.status?.toUpperCase(), [openVerificationRequest?.status]);

    const showVerifiedTag = useMemo(() => organization.verification, [organization.verification]);

    const showVerificationInProgressTag = useMemo(() => {
        switch (openVerificationRequestStatus) {
            case "PENDING":
            case "PAYMENTREQUIRED":
            case "PROCESSINGPAYMENT":
            case "AWAITINGPAYMENT":
                return true;
            default:
                return false;
        }
    }, [openVerificationRequestStatus]);

    const verificationStatusMessage = useMemo(() => {
        switch (openVerificationRequestStatus) {
            case "PENDING":
                return "";
            default:
                return null;
        }
    }, [openVerificationRequestStatus]);

    const handleDetailsBtnClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        onDetailsBtnClick?.(e, organization);
    }, [onDetailsBtnClick, organization]);

    const handleBoxClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onDetailsBtnClick?.(e as any, organization);
    }, [onDetailsBtnClick, organization]);

    return (
        <Box
            className="hides-descendants"
            fullWidth
            style={{
                maxWidth: 784,
            }}
        >
            <Flexbox direction="column" rowGap>
                <Flexbox
                    columnGap={16}
                    horizontalAlign="space-between"
                    style={{ cursor: "pointer" }}
                    onClick={handleBoxClick}
                >
                    {verificationStatusMessage && (
                        <div>
                            <MessageBar intent="success">
                                <MessageBarBody>{verificationStatusMessage}</MessageBarBody>
                            </MessageBar>
                        </div>
                    )}
                    <Flexbox direction="column">
                        <Title3>{organization.name}</Title3>
                        {organization.lookupKey && (
                            <Flexbox verticalAlign="center">
                                <OrgCodeLabel orgCode={organization?.lookupKey} />
                            </Flexbox>
                        )}
                    </Flexbox>
                    <Flexbox>
                        {showVerifiedTag ? (
                            <Tag shape="pill">Verified</Tag>
                        ) : showVerificationInProgressTag && (
                            <Tag shape="pill">Verification in progress</Tag>
                        )}
                        <Button
                            appearance="transparent"
                            icon={<ChevronRight48Regular />}
                            style={{
                                alignSelf: "center",
                            }}
                            onClick={handleDetailsBtnClick}
                        />
                    </Flexbox>
                </Flexbox>
                {(openVerificationRequestStatus === "INCOMPLETE") && (
                    <Flexbox
                        className="hidden-descendant"
                        columnGap
                    >
                        <Button appearance="primary">Continue application</Button>
                    </Flexbox>
                )}
            </Flexbox>
        </Box>
    )
};

interface OrganizationCardListProps {
    organizations: ReadonlyArray<Organization>;
    onListItemClick?: (e: React.MouseEvent<HTMLElement>, organization: Organization) => void;
}

export const OrganizationCardList = (props: Readonly<OrganizationCardListProps>) => {
    const { organizations, onListItemClick } = props;

    const handleListItemDetailsBtnClick = useCallback((e: React.MouseEvent<HTMLButtonElement>, organization: Organization) => {
        onListItemClick?.(e, organization);
    }, [onListItemClick]);

    return (
        <Flexbox direction="column" rowGap fullWidth>
            {organizations.map((organization) => (
                <OrganizationCardListItem
                    key={organization.id}
                    organization={organization}
                    onDetailsBtnClick={handleListItemDetailsBtnClick}
                />
            ))}
        </Flexbox>
    );
};

export default OrganizationCardList;