import type { Form } from "../../models";
import { FormVersionCollectionRequestBuilder } from "./FormVersionCollectionRequestBuilder";
import RequestBuilder from "../RequestBuilder";

export class FormRequestBuilder extends RequestBuilder {
    public async get() {
        const response = await this.client.get<Form>(this.baseUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public get versions() {
        return new FormVersionCollectionRequestBuilder(this._appendUrlSegment("versions"), this.client);
    }
}

export default FormRequestBuilder;