import type { ApiObjectResponse, User } from "../../client";
import { Box, Button, Flexbox, TextField } from "../../components/ui";
import { isPayloadAction, meActions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useCallback, useEffect, useRef, useState } from "react";

import { ButtonWithLoader } from "../../components";
import { EqualityUtils } from "../../utils";
import { Page } from "../../layouts";
import { Title1 } from "@fluentui/react-components";

interface UserFormValues {
    givenName?: string;
    surname?: string;
}

export const UserAccountSettingsPage = () => {
    const me = useAppSelector(s => s.me.me);
    const [loading, setLoading] = useState<boolean>(!me);
    const [actionInProgress, setActionInProgress] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const initialValuesRef = useRef<Partial<UserFormValues>>(me ?? {});
    const [changedValues, setChangedValues] = useState<Partial<UserFormValues>>(initialValuesRef.current);

    const hasChanges = !EqualityUtils.deepEquals(initialValuesRef.current, {
        ...initialValuesRef.current,
        ...changedValues,
    });

    const handleDiscardBtnClick = () => {
        setChangedValues({});
    };

    const handleSaveBtnClick = () => {
        setActionInProgress(true);
        const action = dispatch(meActions.update(changedValues));
        action.then((result) => {
            if (isPayloadAction<ApiObjectResponse<User>>(result) && !result.error) {
                initialValuesRef.current = result.payload.data;
                setChangedValues({});
            }
        }).finally(() => {
            setActionInProgress(false);
        });
    };

    const handleTextFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        const { name } = e.target ?? e.currentTarget;
        if (!name) {
            return;
        }

        setChangedValues(prev => ({
            ...prev,
            [name]: newValue,
        }));
    }, []);

    useEffect(() => {
        const action = dispatch(meActions.get());
        action.then((result) => {
            if (isPayloadAction<ApiObjectResponse<User>>(result) && !result.error) {
                initialValuesRef.current = result.payload.data;
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [dispatch]);

    return (
        <Page>
            <div className="center" style={{ maxWidth: 780 }}>
            <Title1>Account settings</Title1>
            <Box>
                <Flexbox direction="column" rowGap>
                    <TextField
                        defaultValue={initialValuesRef.current?.givenName}
                        label="First name"
                        loading={loading}
                        name="givenName"
                        placeholder="First Name"
                        required
                        onChange={handleTextFieldChange}
                    />
                    <TextField
                        defaultValue={initialValuesRef.current?.surname}
                        label="Last name"
                        loading={loading}
                        name="surname"
                        placeholder="Last Name"
                        required
                        onChange={handleTextFieldChange}
                    />
                    <Flexbox columnGap horizontalAlign="end">
                        {hasChanges && (
                            <ButtonWithLoader disabled={actionInProgress} onClick={handleDiscardBtnClick}>Discard</ButtonWithLoader>
                        )}
                        <Button
                            disabled={!hasChanges || loading}
                            loading={actionInProgress}
                            text="Save"
                            variant="primary"
                            onClick={handleSaveBtnClick}
                        />
                    </Flexbox>
                </Flexbox>
            </Box>
            </div>
        </Page>
    );
};

export default UserAccountSettingsPage;