import { Toast, ToastBody, ToastTitle, useToastController } from "@fluentui/react-components";
import { useCallback, useRef } from "react";

import { StringUtils } from "../utils";
import { v4 as uuidv1 } from 'uuid';

interface ToastOptions {
    duration?: number;
    title?: string;
    type?: "info" | "success" | "warning" | "error";
}

export function useToaster() {
    const { dispatchToast } = useToastController();

    const push = useCallback((message: string, options?: ToastOptions) => {
        const toastId = uuidv1();
        const dispatchToastOptions = {
            intent: options?.type ?? "info",
            timeout: options?.duration ?? 3000,
            pauseOnHover: true,
            toastId,
        };

        dispatchToast(
            <Toast>
                <ToastTitle>{options?.title ?? message}</ToastTitle>
                {!StringUtils.isNullOrWhitespace(options?.title) && (
                    <ToastBody>{message}</ToastBody>
                )}
            </Toast>,
            dispatchToastOptions,
        );
        return toastId;
    }, [dispatchToast]);

    const toaster = useRef({ push });
    return toaster.current;
}