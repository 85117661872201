import { Divider, Subtitle1 } from "@fluentui/react-components";
import { useCallback, useState } from "react";

import type { FlowNavigationDirection } from "../../../ui";
import { FlowStep } from "../../../ui/flow/FlowStep";
import { isRequestFailedError } from "../../../../client/RequestFailedError";
import type { VerificationStepProps } from "./types";
import { useAppDispatch, useToaster } from "../../../../hooks";
import { isPayloadAction, organizationActions } from "store";
import { type ApiObjectResponse, type OrganizationVerificationRequest } from "../../../../client";
import { VerificationStepsListItem } from "../../VerificationStepListItem";

export const OrganizationVerificationStartFlowStep = (props: Readonly<VerificationStepProps>) => {
    const { organization, verificationRequest, onNavigate, ...stepProps } = props;
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const dispatch = useAppDispatch();
    const toaster = useToaster();

    const createVerificationRequest = useCallback(async () => {
        const actionResult = await dispatch(organizationActions.createVerificationRequest({ organizationId: organization.id }));

        if (isPayloadAction<ApiObjectResponse<OrganizationVerificationRequest>>(actionResult) && actionResult.error) {
            if (isRequestFailedError(actionResult.error) && (actionResult.error.statusCode === 409 || actionResult.error.code === "conflict")) {
                return await dispatch(organizationActions.getById(organization.id));
            } else {
                throw actionResult.error;
            }
        }

        return actionResult.payload as ApiObjectResponse<OrganizationVerificationRequest>;
    }, [dispatch, organization.id]);

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        if (direction !== "forward" || verificationRequest?.id) {
            onNavigate?.(direction);
            return;
        }

        setSubmitInProgress(true);

        createVerificationRequest().then(() => {
            onNavigate?.(direction);
        }).catch((error) => {
            if (isRequestFailedError(error) && (error.statusCode === 409 || error.code === "conflict")) {
                onNavigate?.(direction);
            } else {
                console.error(error);
                toaster.push((error as any)?.message, { type: "error", title: "There was a problem creating your verification request" });
            }
        }).finally(() => {
            setSubmitInProgress(false);
        });
    }, [createVerificationRequest, onNavigate, toaster, verificationRequest?.id]);

    return (
        <FlowStep
            {...stepProps}
            actionsProps={{
                actionInProgress: submitInProgress,
            }}
            onNavigate={handleStepNavigation}
        >
            <div>
                <p>Verified organizations are eligible to be credentialed and be exposed to our many corporate partners for business opportunities. Ready?</p>
                <p>For verification, you'll need to show that <b>{organization?.name ?? "your organization"}</b> is real and you have a legitimate connection to it.</p>
                <Subtitle1>What's involved</Subtitle1>
                <Divider />
                <ul>
                    <VerificationStepsListItem
                        description="Confirm your connection"
                        details="Select a way for us to get in touch to confirm your connection to the business."
                    />
                    <VerificationStepsListItem
                        description="Provide documentation"
                        details="You will need to upload accepted documents to confirm these details of your organization."
                    />
                </ul>
            </div>
        </FlowStep>
    );
}

export default OrganizationVerificationStartFlowStep;