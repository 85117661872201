import { Flexbox, FlowStep, type FlowNavigationDirection } from "components/ui";
import {
    useAppDispatch,
    useToaster,
} from "hooks";
import { useCallback, useEffect, useState } from "react";
import { type OrganizationCredentialRequestItem } from "client";
import { CredentialRequestEvidenceCompactCard } from "../../cards";
import type React from "react";
import type { RequestCredentialFlowStepProps } from "./types";
import { ArrayUtils } from "utils";
import { credentialRequestActions, isPayloadAction, organizationActions } from "store";

export const RequestCredentialEvidenceFlowStep: React.FC<RequestCredentialFlowStepProps> = (props: Readonly<RequestCredentialFlowStepProps>) => {
    const { organization, onNavigate, ...stepProps } = props;
    const toaster = useToaster();
    const openCredentialRequest = organization?.credentialRequests?.find(r => r.state?.toUpperCase() === "OPEN");
    const credentialRequestItems: ReadonlyArray<OrganizationCredentialRequestItem> = openCredentialRequest?.requestItems ?? ArrayUtils.empty;
    const draftRequestExists = credentialRequestItems.some((request) => request.status?.toUpperCase() === "NOTSUBMITTED");
    const dispatch = useAppDispatch();
    const [, setFetchInProgress] = useState(credentialRequestItems.length === 0);
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const submit = useCallback(async () => {
        if (!organization?.id) {
            throw new Error("Organization ID is required to submit credential requests.");
        }

        if (!openCredentialRequest?.id) {
            throw new Error("There is no open credential request to submit.");
        }

        setSubmitInProgress(true);
        let actionResult;
        try {
            actionResult = await dispatch(credentialRequestActions.submit({ organizationId: organization.id, credentialRequestId: openCredentialRequest.id }));
        } finally {
            setSubmitInProgress(false);
        }

        if (isPayloadAction(actionResult)) {
            if (actionResult.error) {
                throw actionResult.error;
            }
        }
    }, [dispatch, openCredentialRequest?.id, organization?.id]);

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        if (direction !== "forward") {
            onNavigate?.(direction);
            return;
        } else if (!draftRequestExists) {
            submit().then(() => {
                onNavigate?.(direction);
            }).catch(() => {
                toaster.push("Something went wrong while submitting credential requests", { type: "error" });
            });
        }
    }, [onNavigate, submit, toaster, draftRequestExists]);

    useEffect(() => {
        if (organization?.id) {
            const action = dispatch(organizationActions.getById(organization?.id));
            action.finally(() => {
                setFetchInProgress(false);
            });
        }
    }, [dispatch, organization?.id]);

    return (
        <FlowStep
            {...stepProps}
            actionsProps={{
                ...stepProps.actionsProps,
                disablePrimaryAction: draftRequestExists,
                actionInProgress: submitInProgress,
            }}
            onNavigate={handleStepNavigation}
        >
            <Flexbox direction="column" rowGap>
                {credentialRequestItems.map((requestItem) => (
                    <CredentialRequestEvidenceCompactCard
                        key={requestItem.id}
                        defaultValues={requestItem.response?.values}
                        organizationId={organization?.id ?? ""}
                        requestId={openCredentialRequest?.id ?? ""}
                        requestItem={requestItem}
                    />
                ))}
            </Flexbox>
        </FlowStep>
    );
};