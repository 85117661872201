import { Field } from "../../common";
import type { FormErrorValues } from "../../common/types";
import React from "react";
import { TextField } from "../../ui";

export interface AddOrganizationFormValues {
    name?: string;
    usTaxpayerId?: string;
}

export type AddOrganizationFormErrors = FormErrorValues<AddOrganizationFormValues>;

interface AddOrganizationFormProps {
    extended?: boolean;
    errors?: AddOrganizationFormErrors;
    values?: Partial<AddOrganizationFormValues>;
    onChange?: (values: Partial<AddOrganizationFormValues>) => void;
}

export const AddOrganizationForm = (props: Readonly<AddOrganizationFormProps>) => {
    const { errors, extended, values, onChange } = props;
    const valuesRef = React.useRef(values);

    const handleTextFieldChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        const inputName = (event.currentTarget ?? event.target).name;
        if (onChange && inputName) {
            onChange({
                ...valuesRef.current,
                [inputName]: newValue,
            });
        }
    }, [onChange]);

    return (
        <form style={{ maxWidth: 740 }}>
            {extended && (
            <Field
                label="Employer Identification Number (EIN)"
                required
                name="usTaxpayerId"
                statusText={errors?.usTaxpayerId}
            >
                <TextField
                    defaultValue={valuesRef.current?.usTaxpayerId}
                    placeholder="12-3456789"
                    inputMask="##-#######"
                    onChange={handleTextFieldChange}
                />
            </Field>
            )}
            <Field
                label="Organization name"
                helperText="The name of your organization as it appears on official documents."
                required
                name="name"
                statusText={errors?.name}
            >
                <TextField
                    defaultValue={valuesRef.current?.name}
                    placeholder="Organization Name, LLC."
                    onChange={handleTextFieldChange}
                />
            </Field>
        </form>
    )
};

export default AddOrganizationForm;