import { acceptableDocumentsReducer } from "./acceptableDocuments/reducers";
import { configureStore } from "@reduxjs/toolkit";
import { credentialRequestReducer } from "./credentialRequests";
import { credentialsReducer } from "./credentials/reducers";
import { formsReducer } from "./forms/reducers";
import { meReducer } from "./me";
import { organizationsReducer } from "./organizations/reducers";
import { verificationRequestsReducer } from "./verifications/reducers";

export const store = configureStore({
    reducer: {
        acceptableDocuments: acceptableDocumentsReducer,
        credentials: credentialsReducer,
        credentialRequests: credentialRequestReducer,
        forms: formsReducer,
        me: meReducer,
        organizations: organizationsReducer,
        verificationRequests: verificationRequestsReducer,
    },
});