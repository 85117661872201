import { FormVersionRequestBuilder } from "./FormVersionRequestBuilder";
import { RequestBuilder } from "../RequestBuilder";

export class FormVersionCollectionRequestBuilder extends RequestBuilder {
    public get published() {
        return new FormVersionRequestBuilder(this._appendUrlSegment("published"), this.client);
    }

    public version(versionIdOrNumber: string | number) {
        return new FormVersionRequestBuilder(this._appendUrlSegment(versionIdOrNumber.toString()), this.client);
    }
}