import type { EntitySearchResultItem, SearchGlobalEntitiesRequest } from "../../models/EntitySearchResultItem";

import RequestBuilder from "../RequestBuilder";

export class SearchRequestBuilder extends RequestBuilder {
    public async searchEntities(request: SearchGlobalEntitiesRequest) {
        const requestUrl = this._appendUrlSegment("globalEntities");
        const response = await this.client.post<ReadonlyArray<EntitySearchResultItem>>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }
}