import { Link, Outlet, useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Persona } from "@fluentui/react-components";

import { useMemo, type PropsWithChildren } from "react";
import routePaths from "../router/routePaths";
import { useMsal } from "@azure/msal-react";
import { useAppSelector } from "../hooks";
import { StringUtils } from "../utils";

export const MainLayout: React.FC<PropsWithChildren> = () => {
    const { instance: msal } = useMsal();
    const me = useAppSelector(s => s.me.me);
    const navigate = useNavigate();

    const displayName = useMemo(() => {
        let displayName = null;
        if (!StringUtils.isNullOrWhitespace(me?.givenName) && !StringUtils.isNullOrWhitespace(me?.surname)) {
            displayName = `${me?.givenName} ${me?.surname}`;
        } else if (!StringUtils.isNullOrWhitespace(me?.givenName)) {
            displayName = me?.givenName;
        }

        return displayName;
    }, [me?.givenName, me?.surname]);

    const handleSettingsClick = () => {
        navigate(routePaths.account.settings);
    };

    const handleSignOutClick = () => {
        msal.logoutRedirect();
    };

    const account = msal.getActiveAccount();

    return (
        <>
            <header>
                <div
                    style={{
                        backgroundColor: "rgba(255, 255, 255, 0.10)",
                        borderBottom: "1px solid #A797FD",
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px 56px 12px 48px",
                    }}
                >
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <h3>
                            <Link to={routePaths.dashboard} style={{ color: "white", textDecoration: "none" }}>
                                <img src="/logo-color-transparent.svg" alt="Logo" />
                            </Link>
                        </h3>
                    </div>
                    {account && (
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Menu>
                                <MenuTrigger>
                                    <MenuButton>
                                        <Persona
                                            name={displayName ?? (account.name === "unknown" ? account.username : account.name)}
                                            textAlignment="center"
                                        />
                                    </MenuButton>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
                                        <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </div>
                    )}
                </div>
            </header>
            <main>
                <div style={{ boxSizing: "border-box", height: "calc(100vh - 78px)", overflowY: "hidden" }}>
                    <Outlet />
                </div>
            </main>
        </>
    );
};

export default MainLayout;