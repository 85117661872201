import { Copyable, Skeleton } from "../../ui";

import { useCallback } from "react";

interface DetailTextProps {
    copyable?: boolean;
    label: string;
    value: string | JSX.Element;
    direction?: "row" | "column";
    loading?: boolean
}

export const DetailText = (props: Readonly<DetailTextProps>) => {
    const { copyable, label, value, loading, direction = "column" } = props;

    const renderContent = useCallback((content: JSX.Element) => {
        if (copyable) {
            return <Copyable>{content}</Copyable>;
        } else {
            return content;
        }
    }, [copyable]);

    return (
        <div style={{ display: "flex", flexDirection: direction, rowGap: 2, columnGap: 8 }}>
            <span
                style={{
                    color: "#52525B",
                    fontSize: 16,
                    fontStyle: "normal",
                    fontWeight: 400,
                    letterSpacing: -0.32,
                }}
            >
                {label}
            </span>
            <Skeleton loading={loading}>
                {renderContent(
                    <span
                        style={{
                            color: "#000",
                            fontSize: 16,
                            fontStyle: "normal",
                            fontWeight: "bolder",
                            letterSpacing: -0.32,
                        }}
                    >
                        {value}
                    </span>
                )}
            </Skeleton>
        </div>
    );
};