import { Button as FuiButton, makeStyles, Spinner, tokens, type Slot } from "@fluentui/react-components";
import type { ButtonVariantType } from "./types";
import { useMemo, type PropsWithChildren } from "react";
import classNames from "classnames";

const useOverrides = makeStyles({
    root: {
        backgroundColor: tokens.colorStatusDangerBackground3,
        ":disabled": {
            backgroundColor: tokens.colorNeutralBackgroundDisabled,
        },
        ":hover": {
            backgroundColor: tokens.colorStatusDangerBackground3Hover,
        },
        ":active": {
            backgroundColor: tokens.colorStatusDangerBackground3Pressed,
        },
    }
});

type BaseButtonProps = PropsWithChildren | { text: string; }

type ButtonProps = {
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    icon?: Slot<"span">;
    loading?: boolean;
    size?: "small" | "medium" | "large" | "xLarge";
    text?: string;
    variant?: ButtonVariantType;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & BaseButtonProps;

export const Button = (props: Readonly<ButtonProps>) => {
    const {
        children,
        className,
        disabled,
        icon,
        loading,
        size = "medium",
        text,
        variant = "secondary",
        onClick,
    } = props;
    const overrides = useOverrides();

    const appearance = useMemo(() => {
        switch (variant) {
            case "primary":
            case "danger":
                return "primary";
            case "secondary":
                return "secondary";
        };
    }, [variant]);

    const fuiButtonSize = useMemo(() => {
        switch (size) {
            case "large":
                return "large";
            case "medium":
                return "medium";
            case "small":
                return "small";
            case "xLarge":
                return "large";
        }
    }, [size]);

    return (
        <FuiButton
            appearance={appearance}
            className={classNames({
                [overrides.root]: variant === "danger",
            }, className)}
            disabled={disabled || loading}
            icon={loading ? <Spinner size="tiny" /> : icon}
            iconPosition="before"
            shape="circular"
            size={fuiButtonSize}
            onClick={onClick}
        >
            {text ?? children}
        </FuiButton>
    )
};

export default Button;