import { Box, Button, Flexbox, FlowStep, Loader, Tag, type FlowNavigationDirection } from "components/ui";
import {
    useAppDispatch,
    useAppSelector,
} from "hooks";
import { useCallback, useEffect, useTransition } from "react";
import type React from "react";
import type { RequestCredentialFlowStepProps } from "./types";
import { organizationActions } from "store";
import { Body1, Body1Strong, Title2 } from "@fluentui/react-components";

export const RequestCredentialNotarizationFlowStep: React.FC<RequestCredentialFlowStepProps> = (props: Readonly<RequestCredentialFlowStepProps>) => {
    const { organization, onNavigate, ...stepProps } = props;
    const dispatch = useAppDispatch();
    const [fetchingOrganization, startOrganizationFetch] = useTransition();
    const me = useAppSelector((state) => state.me.me);
    const openCredentialRequest = organization.credentialRequests?.find((request) => request.state?.toUpperCase() === "OPEN");

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        if (direction !== "forward") {
            onNavigate?.(direction);
            return;
        }
    }, [onNavigate]);

    useEffect(() => {
        if (organization?.id) {
            startOrganizationFetch(() => {
                const action = dispatch(organizationActions.getById(organization?.id));
                action.then(() => {
                    // Handle errors.
                });
            });
        }
    }, [dispatch, organization?.id]);

    return (
        <FlowStep
            {...stepProps}
            actionsProps={{
                ...stepProps.actionsProps,
            }}
            onNavigate={handleStepNavigation}
        >
            <Loader loading={fetchingOrganization}>
                <Box>
                    <Flexbox direction="column" rowGap>
                        <Title2>One last step, sign your documents securely online</Title2>
                        <Body1>Connect with a notary online now and sign all required documents in just a few minutes through a secure video call. Once your documents are signed, you'll gain access to your organization's credentials.</Body1>
                        {openCredentialRequest?.signers?.map((signer) => (
                            <Box key={signer.emailAddress}>
                                <Flexbox direction="row" horizontalAlign="space-between" rowGap>
                                    <Flexbox direction="column">
                                        <Body1Strong>{signer.givenName} {signer.surname}</Body1Strong>
                                        <Body1>{signer.emailAddress}</Body1>
                                    </Flexbox>
                                    {(signer.signedAt || signer.signingStatus?.toUpperCase() === "COMPLETED") ? (
                                        <Tag shape="pill">Signed</Tag>
                                    ) : (me?.emailAddress === signer.emailAddress) ? (
                                        <Button variant="primary">Sign</Button>
                                    ) : (
                                        <Tag shape="pill">Not signed</Tag>
                                    )}
                                </Flexbox>
                            </Box>
                        ))}
                    </Flexbox>
                </Box>
            </Loader>
        </FlowStep>
    );
};