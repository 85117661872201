import type { ODataCollectionQueryOptions } from "../../models/ODataCollectionQueryOptions";
import type { OrganizationVerificationRequest } from "../../models";
import { OrganizationVerificationRequestRequestBuilder } from "./OrganizationVerificationRequestRequestBuilder";
import { RequestBuilder } from "../RequestBuilder";

export class OrganizationVerificationRequestCollectionRequestBuilder extends RequestBuilder {
    public async get(nextLinkOrQueryOptions?: string | ODataCollectionQueryOptions) {
        const requestUrl = typeof(nextLinkOrQueryOptions) === "string" ? nextLinkOrQueryOptions : this.baseUrl;
        const response = await this.client.get<OrganizationVerificationRequest>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async create() {
        const requestUrl = this.baseUrl;
        const response = await this.client.post<OrganizationVerificationRequest>(requestUrl);

        return this.getResponseObjectOrThrow(response);
    }

    public verificationRequest(requestId: string) {
        return new OrganizationVerificationRequestRequestBuilder(this._appendUrlSegment(requestId), this.client);
    }
}

export default OrganizationVerificationRequestCollectionRequestBuilder;