import type React from "react";
import { Spinner } from "@fluentui/react-components";
import { useMemo } from "react";

interface LoaderProps extends React.PropsWithChildren {
    loading?: boolean;
    horizontalAlignment?: "center" | "left" | "right";
    verticalAlignment?: "center" | "top" | "bottom";
}

export const Loader: React.FC<LoaderProps> = (props: Readonly<LoaderProps>) => {
    const { children, horizontalAlignment = "center", loading, verticalAlignment } = props;

    const justifyContent: React.CSSProperties["justifyContent"] = useMemo(() => {
        return verticalAlignment === "center" ? "center" : verticalAlignment === "top" ? "flex-start" : "flex-end";
    }, [verticalAlignment]);

    const alignItems: React.CSSProperties["alignItems"] = useMemo(() => {
        return horizontalAlignment === "center" ? "center" : horizontalAlignment === "left" ? "flex-start" : "flex-end";
    }, [horizontalAlignment]);

    return loading ? (
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, justifyContent, alignItems, height: "100%", width: "100%" }}>
            <Spinner />
        </div>
    ) : children as React.ReactElement;
};

export default Loader;