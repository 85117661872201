import { Button, ProgressBar } from "@fluentui/react-components";
import { Dismiss12Filled, Document32Regular } from "@fluentui/react-icons";
import { FileUtils, StringUtils } from "../../../../../utils";
import { useEffect, useMemo, useRef, useState } from "react";

import type { FormFile } from "../../../../form/types";
import type { InputFieldProps } from "../types";
import type React from "react";

interface SelectedFileListItemProps {
    file: FormFile;
    statusText?: string;
    inProgress?: boolean;
    onRemoveClick?: (e: React.MouseEvent, file: FormFile) => void;
}

const SelectedFileListItem = (props: Readonly<SelectedFileListItemProps>) => {
    const { file, inProgress, statusText, onRemoveClick } = props;

    const handleRemoveClick = (e: React.MouseEvent) => {
        onRemoveClick?.(e, file);
    };

    return (
        <li
            style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "6px",
                width: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    padding: "8px",
                    width: "100%",
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: 8,
                            }}
                        >
                            <Document32Regular />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <span>{file.name}</span>
                                {(file.content?.size !== undefined && file.content?.size !== null) && (
                                    <span>{FileUtils.getFileSizeText(file.content.size)}</span>
                                )}
                            </div>
                        </div>
                        {onRemoveClick && (
                            <Button
                                appearance="transparent"
                                disabled={inProgress}
                                icon={<Dismiss12Filled />}
                                onClick={handleRemoveClick}
                            />
                        )}
                    </div>
                </div>
                {inProgress && (
                    <ProgressBar />
                )}
                {(!inProgress && statusText) && (
                    <span>{statusText}</span>
                )}
            </div>
        </li>
    );
}

interface FileInputProps extends Omit<InputFieldProps, "statusText"> {
    accept?: ReadonlyArray<string>;
    defaultValue?: ReadonlyArray<FormFile>;
    maxFiles?: number;
    multiple?: boolean;
    inProgress?: boolean | ReadonlyArray<boolean>;
    statusText?: string | ReadonlyArray<string>;
    onChange?: (files: ReadonlyArray<FormFile>) => void;
}

export const FileInput = (props: Readonly<FileInputProps>) => {
    const {
        accept,
        inProgress,
        maxFiles,
        multiple,
        name,
        readOnly,
        required,
        status,
        statusText,
        onChange,
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedFiles, setSelectedFiles] = useState<ReadonlyArray<FormFile>>([]);

    const acceptString = useMemo(() => {
        if (!accept) {
            return undefined;
        }

        return accept.map(el => el.startsWith('.') ? el : `.${el}`).join(",");
    }, [accept]);

    const remainingFileCount = (multiple === true && maxFiles) ? maxFiles - selectedFiles.length : undefined;

    const handleContainerClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const formFiles: FormFile[] = Array.from(e.target.files).map((file) => ({
                id: file.name,
                name: file.name,
                uri: URL.createObjectURL(file),
                content: file,
            }));

            if (multiple) {
                setSelectedFiles([...selectedFiles, ...formFiles]);
            } else {
                setSelectedFiles(formFiles);
            }
        }
    };

    const handleRemoveFileClick = (e: React.MouseEvent, file: FormFile) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedFiles(selectedFiles.filter((f) => f !== file));
    };

    useEffect(() => {
        if (onChange) {
            onChange(selectedFiles);
        }
    }, [selectedFiles, onChange]);

    return (
        <div
            style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                rowGap: 8,
            }}
        >
            {((multiple !== true && selectedFiles.length === 0) || (multiple && maxFiles && selectedFiles.length < maxFiles)) && (
                <div
                    style={{
                        backgroundColor: "#FFF",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "24px 12px",
                        width: "100%",
                    }}
                    onClick={handleContainerClick}
                >
                    <input
                        accept={acceptString}
                        max={maxFiles}
                        multiple={multiple}
                        name={name}
                        style={{ display: "none" }}
                        ref={inputRef}
                        required={required}
                        type="file"
                        onChange={handleInputChange}
                    />
                    <span>Click here to select a file or drag and drop here</span>
                    {(remainingFileCount !== undefined) && <span>{remainingFileCount} file{remainingFileCount === 1 ? "" : "s"} remaining</span>}
                </div>
            )}
            {(typeof statusText === 'string' && !StringUtils.isNullOrWhitespace(statusText)) && (
                <span
                    style={{
                        color: status === "error" ? "red" : status === "warning" ? "orange" : "inherit",
                    }}
                >
                    {statusText}
                </span>
            )}
            {selectedFiles.length > 0 && (
                <ul
                    style={{
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "column",
                        listStyle: "none",
                        margin: 0,
                        rowGap: 8,
                        padding: 0,
                        width: "100%",
                    }}
                >
                    {selectedFiles.map((file, fileIndex) => (
                        <SelectedFileListItem
                            key={file.name}
                            file={file}
                            inProgress={inProgress instanceof Array ? inProgress[fileIndex] : inProgress}
                            statusText={statusText instanceof Array ? statusText[fileIndex] : undefined}
                            onRemoveClick={readOnly ? undefined : handleRemoveFileClick}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FileInput;