import type { AddOrganizationCredentialRequestItemRequest, CollectionResponse, OrganizationCredentialRequest, OrganizationCredentialRequestItem } from "../../../models";

import { OrganizationCredentialRequestItemBuilder } from "./OrganizationCredentialRequestItemRequestBuilder";
import RequestBuilder from "../../RequestBuilder";

export class OrganizationCredentialRequestItemCollectionBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<CollectionResponse<OrganizationCredentialRequest>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async post(request: AddOrganizationCredentialRequestItemRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.post<OrganizationCredentialRequestItem>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }

    public item(itemId: string) {
        return new OrganizationCredentialRequestItemBuilder(this._appendUrlSegment(itemId), this.client);
    }
}