import { Box, Flexbox, Loader } from "../../ui";

import { DocumentDetailCard } from "../../common/data/DocumentDetailCard";
import type { Organization } from "../../../client";
import { Title3 } from "@fluentui/react-components";
import { selectPendingOrApprovedDocuments } from "../../../data";

interface OrganizationVerificationDocumentsCardProps {
    loading?: boolean;
    organization?: Partial<Organization>;
}

export const OrganizationVerificationDocumentsCard = (props: Readonly<OrganizationVerificationDocumentsCardProps>) => {
    const { organization, loading } = props;
    const pendingOrAcceptedDocuments = selectPendingOrApprovedDocuments(organization);

    return (
        <Flexbox direction="column" rowGap>
            <Title3>Documents</Title3>
            <Box>
                <Flexbox direction="column" rowGap>
                    <Loader loading={loading}>
                        <Flexbox direction="column" rowGap>
                            {pendingOrAcceptedDocuments?.map((doc) => (
                                <DocumentDetailCard
                                    key={doc.id}
                                    document={{
                                        ...doc,
                                        documentId: doc.id,
                                    }}
                                />
                            ))}
                        </Flexbox>
                    </Loader>
                </Flexbox>
            </Box>
        </Flexbox>
    )
};