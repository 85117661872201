export class StringUtils {
	public static compare(a: string, b: string): number {
		if (a > b) {
			return 1;
		} else if (a < b) {
			return -1;
		} else {
			return 0;
		}
	}

	public static isEqual(a: string, b: string, ignoreCase = false): boolean {
		if (ignoreCase === true) {
			return a?.toLowerCase() === b?.toLowerCase();
		} else {
			return a === b;
		}
	}

	public static isWhitespace(input: string) {
		const regex = /^[\s]+$/g;

		return regex.test(input);
	}

	public static isNullOrEmpty(value: string | null | undefined): value is "" | null | undefined {
		return value === null || value === undefined || value?.length === 0;
	}

	public static isNullOrWhitespace(value: string | null | undefined): value is "" | null | undefined {
		if (typeof value === "string" && value !== null) {
			for (const ch of value) {
				if (!this.isWhitespace(ch)) {
					return false;
				}
			}
		}

		return true;
	}

	public static stripLeadingSlash(str: string) {
		if (str.startsWith("/")) {
			return str.slice(1, str.length);
		}

		return str;
	}

	public static stripTrailingSlash(str: string) {
		if (str.endsWith("/")) {
			return str.slice(0, str.length - 1);
		}

		return str;
	}

	public static padLeft(pad: string, length: number, input: number | string) {
		let inputStr;
		if (typeof input === "number") {
			inputStr = input.toString();
		} else {
			inputStr = input;
		}

		let outputStr = "";
		const numCharsToPad = length - inputStr.length;
		for (let i = 0; i < length; i += pad.length) {
			if (i < numCharsToPad) {
				outputStr += pad;
			} else {
				outputStr += inputStr[i - numCharsToPad];
			}
		}

		return outputStr;
	}

	public static copyTextToClipboard(text: string): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			if (navigator?.clipboard) {
				try {
					navigator.clipboard.writeText(text)
						.then(() => {
							resolve(true);
						})
						.catch(reject);
				} catch (error) {
					reject(error);
				}
			} else {
				/* for older version browsers which is not support navigator.clipboard */
				const textArea = document.createElement("textarea");
				textArea.value = text;
				document.body.appendChild(textArea);
				textArea.focus();
				textArea.select();
				try {
					const isSuccess = document.execCommand("copy");
					textArea.remove();
					document.body.removeChild(textArea);
					resolve(isSuccess);
				} catch (error) {
					reject(error);
				}
			}
		});
	}

	public static capitalizeFirstLetter = (text: string): string => {
		return `${text?.charAt(0)?.toUpperCase()}${text?.slice(1)?.toLowerCase()}`;
	};

	/**
	 * All words are capitalized, except non-initial articles like "a, the, and", etc.
	 *
	 * @static
	 * @param {string} text
	 * @memberof StringUtils
	 */
	public static toTitleCase = (text: string): string => {
		if (this.isNullOrWhitespace(text)) {
			return text;
		}

		text = text.replace(/([A-Z])/g, " $1");
		text = `${text.charAt(0)?.toUpperCase()}${text.slice(1)}`;
		return text.trim();
	};

	public static toSnakeCase(str: string): string {
		return str.replace(/[A-Z]/g, char => `_${char.toLowerCase()}`);
	}

	public static lorem(characters: number) {
		// spellchecker: disable-next-line
		const paragraph = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
		return paragraph.slice(0, characters);
	}
}

export default StringUtils;