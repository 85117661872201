import { Body1, Body1Strong, Title3 } from "@fluentui/react-components";
import { Box, Copyable, Flexbox, Skeleton, Tag } from "../../ui";

import type { Organization } from "../../../client";

interface OrganizationOwnersCardProps {
    loading?: boolean;
    organization?: Partial<Organization>;
}

export const OrganizationOwnersCard = (props: Readonly<OrganizationOwnersCardProps>) => {
    const { organization, loading } = props;

    const owners = organization?.owners ?? [];

    const renderLoading = () => {
        return (
            <Skeleton loading={true} />
        );
    };

    return (
        <Flexbox direction="column" rowGap>
            <Title3>Owners</Title3>
            <Box style={{ minWidth: 284, }}>
                <Flexbox direction="column" rowGap={8}>
                    <Flexbox direction="column" rowGap={8}>
                        {loading ? renderLoading() : owners.map((owner) => (
                            <Flexbox key={owner.id} horizontalAlign="space-between" verticalAlign="center">
                                <Flexbox direction="column">
                                    <Body1Strong>{owner.givenName} {owner.surname}</Body1Strong>
                                    <Copyable><Body1>{owner.emailAddress}</Body1></Copyable>
                                </Flexbox>
                                <div>
                                    <Tag shape="pill">
                                        {owner.ownership * 100}%
                                    </Tag>
                                </div>
                            </Flexbox>
                        ))}
                    </Flexbox>
                </Flexbox>
            </Box>
        </Flexbox>
    )
};