import type { FormStoreState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { formActions } from "./actions";
import { formEntityAdapter } from "./adapter";

const initialState: FormStoreState = formEntityAdapter.getInitialState({
    selectedId: null,
    list: {
        items: [],
    },
});

export const formsSlice = createSlice({
    name: "forms",
    initialState,
    reducers: {
        setSelectedForm: (state, action) => {
            state.selectedId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(formActions.get.fulfilled, (state, action) => {
            formEntityAdapter.upsertMany(state, action.payload.value);
            state.list.items = action.payload.value.map((form) => form.id);
            state.list.nextLink = action.payload["odata.nextLink"];
        });

        builder.addCase(formActions.getById.fulfilled, (state, action) => {
            formEntityAdapter.upsertOne(state, action.payload);
        });
    },
});