import type { CollectionResponse, CreateDocumentRequest, DocumentVersion } from "../../models";

import { DocumentRequestBuilder } from "./DocumentRequestBuilder";
import { RequestBuilder } from "../RequestBuilder";

export class DocumentCollectionRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<CollectionResponse<DocumentVersion>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public document(documentId: string) {
        return new DocumentRequestBuilder(this._appendUrlSegment(documentId), this.client);
    }

    public async post(request: CreateDocumentRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.post<DocumentVersion>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }

    public async submit(request: CreateDocumentRequest, file: Blob) {
        const requestUrl = this.baseUrl;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("body", JSON.stringify(request));
        const response = await this.client.post<DocumentVersion>(requestUrl, formData);
        return this.getResponseObjectOrThrow(response);
    }
}

export default DocumentCollectionRequestBuilder;