import type { VerificationsStoreState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { verificationRequestActions } from "./actions";
import { verificationRequestEntityAdapter } from "./adapter";

const initialState: VerificationsStoreState = verificationRequestEntityAdapter.getInitialState({
    selectedId: null,
    list: {
        ids: [],
    },
});

export const verificationRequestSlice = createSlice({
    name: "verificationRequests",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(verificationRequestActions.get.fulfilled, (state, action) => {
            verificationRequestEntityAdapter.upsertMany(state, action.payload.data.value);
            state.list.nextLink = action.payload.data["odata.nextLink"];
        });

        builder.addCase(verificationRequestActions.getById.fulfilled, (state, action) => {
            verificationRequestEntityAdapter.upsertOne(state, action.payload.data);
        });

        builder.addCase(verificationRequestActions.approveDocument.fulfilled, (state, action) => {
            if (state.entities[action.meta.arg.verificationId]) {
                state.entities[action.meta.arg.verificationId].documents = state.entities[action.meta.arg.verificationId].documents.map((document) => {
                    if (document.id === action.meta.arg.documentId) {
                        return {
                            ...document,
                            approvalResult: action.payload,
                        };
                    }

                    return document;
                });
            }
        });

        builder.addCase(verificationRequestActions.rejectDocument.fulfilled, (state, action) => {
            if (state.entities[action.meta.arg.verificationId]) {
                state.entities[action.meta.arg.verificationId].documents = state.entities[action.meta.arg.verificationId].documents.map((document) => {
                    if (document.id === action.meta.arg.documentId) {
                        return {
                            ...document,
                            approvalResult: action.payload,
                        };
                    }

                    return document;
                });
            }
        });
    },
});