import type { Organization } from "../../client";
import type { RootState } from "../types";
import { createSelector } from "@reduxjs/toolkit";
import { organizationsAdapter } from "./adapter";
import { selectCredentialById } from "../credentials/slice";

const {
    selectById,
    selectEntities,
} = organizationsAdapter.getSelectors<RootState>((state) => state.organizations);

export const selectOrganizations = createSelector(
    [selectEntities, (state: RootState) => state],
    (entities, state) => {
        return Object.fromEntries(
            Object.entries(entities).map(([id, organization]) => {
                if (!organization) return [id, organization];
                const credentialRequests = organization.credentialRequests?.map(req => ({
                    ...req,
                    credential: selectCredentialById(state, req.credentialId),
                }));
                return [id, { ...organization, credentialRequests }];
            })
        );
    },
);

export const selectSelectedOrganization = createSelector(
    [selectOrganizations, (state: RootState) => state.organizations.selectedId],
    (organizations, selectedId): Organization | null => selectedId ? organizations[selectedId] ?? null : null,
);

export const selectOrganizationsByIds = createSelector(
    [selectOrganizations, (_state: RootState, ids: Array<string>) => ids],
    (organizations, ids) => ids.map((id) => organizations[id]).filter(Boolean)
);

export const selectAllOrganizations = createSelector(
    [selectOrganizations],
    (organizations) => Object.values(organizations),
);


export const selectOrganizationById = createSelector(
    [
        (state: RootState, orgId: string) => {
            return selectById(state, orgId);
        },
        (_state: RootState, orgId: string) => orgId,
        (state: RootState) => state,
    ],
    (organization, _orgId: string, state: RootState) => {
        if (!organization) {
            return null;
        }

        const credentialRequests = organization.credentialRequests?.map(req => {
            const credential = selectCredentialById(state, req.credentialId);
            return {
                ...req,
                credential,
            };
        });

        return {
            ...organization,
            credentialRequests,
        };
    },
);