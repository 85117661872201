import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { ButtonWithLoader, FileInput } from "../../common";

import type { FormFile } from "../../form/types";
import { selectOrganizationById } from "../../../store";
import { serviceClient } from "../../../serviceClient";
import { useAppSelector } from "../../../hooks";
import { useState } from "react";

interface ResolveDocumentDialogProps {
    open: boolean;
    documentId?: string;
    verificationId?: string;
    organizationId?: string;
    onClose?: (reason: "cancel" | "submit") => void;
}

const ResolveDocumentDialogImpl = (props: Readonly<ResolveDocumentDialogProps>) => {
    const { documentId, organizationId, verificationId, open, onClose } = props;
    const document = useAppSelector(s => organizationId ? selectOrganizationById(s, organizationId)?.verificationRequests?.documents.find(d => d.id === documentId) : undefined);
    const [pendingFile, setPendingFile] = useState<File | undefined>();
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

    if (!organizationId) {
        throw new Error("Organization id is required to resolve a document.");
    }

    if (!verificationId) {
        throw new Error("Verification id is required to resolve a document.");
    }

    if (!documentId) {
        throw new Error("Document id is required to resolve a document.");
    }

    const handleCloseBtnClick = () => {
        onClose?.("cancel");
    }

    const handleSubmitClick = () => {
        if (!pendingFile) {
            console.error("No file selected to upload.");
            return;
        }

        setUploadInProgress(true);
        const docsRequestBuilder = serviceClient.organizations
            .organization(organizationId).verificationRequests
            .documents;

        const fileNameWithoutExtension = pendingFile.name.replace(/\.[^/.]+$/, "");
        docsRequestBuilder
            .post({
                name: fileNameWithoutExtension,
            })
            .then((response) => {
                const newDocumentId = response.data.id;
                docsRequestBuilder
                    .document(newDocumentId).content
                    .put(pendingFile).then(() => {
                        onClose?.("submit");
                    })
                    .catch((error) => {
                        console.error("Failed to upload document content.", error);
                        docsRequestBuilder.document(newDocumentId).delete();
                    })
                    .finally(() => {
                        setUploadInProgress(false);
                    });
            })
            .catch((error) => {
                console.error("Failed to create document.", error);
                setUploadInProgress(false);
            });
    };

    const handleFileInputChange = (files: ReadonlyArray<FormFile>) => {
        if (Array.isArray(files) && files.length > 0) {
            setPendingFile(files[0]);
        } else {
            setPendingFile(undefined);
        }
    }

    return (
        <Dialog
            open={open}
        >
            <DialogSurface>
                <DialogTitle>
                    {document?.name}
                </DialogTitle>
                <DialogBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>Status: {document?.approvalResult?.value}</div>
                        <div>Reason: {document?.approvalResult?.reason}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gridColumn: "1 / -1", rowGap: 8 }}>
                        <div>Upload a new document that meets the outlined requirements.</div>
                        <div style={{ marginLeft: "auto", marginRight: "auto" }} >
                            <FileInput
                                accept={["pdf"]}
                                multiple={false}
                                onChange={handleFileInputChange}
                            />
                        </div>
                    </div>
                    <DialogActions>
                        <ButtonWithLoader
                            appearance="primary"
                            disabled={!Boolean(pendingFile)}
                            loading={uploadInProgress}
                            onClick={handleSubmitClick}
                        >
                            Submit
                        </ButtonWithLoader>
                        <Button disabled={uploadInProgress} onClick={handleCloseBtnClick}>Close</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
};

export const ResolveDocumentDialog = (props: Readonly<ResolveDocumentDialogProps>) => {
    const { open } = props;

    return open ? <ResolveDocumentDialogImpl {...props} /> : null;
};