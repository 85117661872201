import { makeStyles } from "@fluentui/react-components";

export const useTextFieldStyles = makeStyles({
    root: {
        border: "1px solid var(--Interactive-div-interactive, #B5BDDE)",
        borderRadius: "8px",
        height: "auto",
    },
    input: {
        paddingTop: "16px",
        paddingBottom: "16px",
    },
});