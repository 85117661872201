import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, type DialogOpenChangeData } from "@fluentui/react-components";
import { acceptableDocumentActions, selectAcceptableDocumentById } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import type { DialogCloseReason } from "../../ui/types";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Skeleton } from "../../ui";
import { useEffect } from "react";

interface AcceptableDocumentDialogProps {
    open: boolean;
    acceptableDocumentId?: string;
    onClose?: (reason: DialogCloseReason) => void;
}

export const AcceptableDocumentDialog = (props: Readonly<AcceptableDocumentDialogProps>) => {
    const { open, acceptableDocumentId, onClose } = props;
    const acceptableDocument = useAppSelector((state) => acceptableDocumentId ? selectAcceptableDocumentById(state, acceptableDocumentId) : null);
    const dispatch = useAppDispatch();

    const handleDialogOpenStateChange = (_event: unknown, data: DialogOpenChangeData) => {
        onClose?.(data.type === "backdropClick" ? "backdropClick" : data.type === "triggerClick" ? "close" : "escapeKey");
    };

    useEffect(() => {
        if (open && acceptableDocumentId && !acceptableDocument?.id) {
            dispatch(acceptableDocumentActions.get(acceptableDocumentId));
        }
    }, [open, acceptableDocumentId, acceptableDocument?.id, dispatch]);

    return (
        <Dialog
            open={open}
            onOpenChange={handleDialogOpenStateChange}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        <Skeleton loading={!acceptableDocument}>
                            {acceptableDocument?.name ?? "..."}
                        </Skeleton>
                    </DialogTitle>
                    <DialogContent>
                        <Skeleton loading={!acceptableDocument}>
                            <p>{acceptableDocument?.description}</p>
                        </Skeleton>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog >
    )
};