import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle } from "@fluentui/react-components";

import { AddOrganizationForm, type AddOrganizationFormErrors, type AddOrganizationFormValues } from "../forms";
import React from "react";
import { ButtonWithLoader } from "../../common";
import { organizationActions } from "../../../store";
import { useAppDispatch } from "../../../hooks";

interface AddOrganizationDialogProps {
    open: boolean;
    onDismiss?: (reason: "cancel" | "submit") => void;
}

const InnerDialog = (props: Readonly<AddOrganizationDialogProps>) => {
    const { open, onDismiss } = props;
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [formValues, setFormValues] = React.useState<Partial<AddOrganizationFormValues>>({});
    const [formErrors, setFormErrors] = React.useState<AddOrganizationFormErrors>({});
    const dispatch = useAppDispatch();

    const handleSubmitBtnClick = React.useCallback(() => {
        const errors: AddOrganizationFormErrors = {};
        if (!formValues.name || formValues.name.length === 0) {
            errors.name = "Organization name is required";
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        const request = {
            name: formValues.name!,
        };

        setInProgress(true);
        const action = dispatch(organizationActions.add(request));
        action
        .then(() => {
            onDismiss?.("submit");
        })
        .finally(() => {
            setInProgress(false);
        });
    }, [dispatch, formValues, onDismiss]);

    const handleCancelBtnClick = React.useCallback(() => {
        onDismiss?.("cancel");
    }, [onDismiss]);

    const handleFormValues = React.useCallback((values: Partial<AddOrganizationFormValues>) => {
        setFormValues(values);
    }, []);

    return (
        <Dialog
            modalType="modal"
            open={open}
            onOpenChange={() => null}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Add organization</DialogTitle>
                    <AddOrganizationForm
                        errors={formErrors}
                        onChange={handleFormValues}
                    />
                    <DialogActions>
                        <Button
                            disabled={inProgress}
                            onClick={handleCancelBtnClick}
                        >
                            Cancel
                        </Button>
                        <ButtonWithLoader
                            appearance="primary"
                            disabled={!formValues.name || formValues.name?.length === 0}
                            loading={inProgress}
                            onClick={handleSubmitBtnClick}
                        >
                            Add
                        </ButtonWithLoader>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export const AddOrganizationDialog = (props: Readonly<AddOrganizationDialogProps>) => {
    const { open } = props;

    return (open) ? <InnerDialog {...props} /> : null;
};

export default AddOrganizationDialog;