import React from "react";
import { StringUtils } from "../../../utils";

export type FieldStatus = "warning" | "error" | "success" | "info";

interface FieldProps {
    htmlId?: string;
    name?: string;
    children: React.ReactElement;
    label?: string;
    helperText?: string;
    required?: boolean;
    status?: FieldStatus;
    statusText?: string;
}

export const Field = (props: Readonly<FieldProps>) => {
    const { children, label, htmlId, helperText, name, status = "error", statusText } = props;

    const wrap = React.useMemo(() => {
        return !StringUtils.isNullOrWhitespace(label) || !StringUtils.isNullOrWhitespace(helperText) || !StringUtils.isNullOrWhitespace(statusText);
    }, [label, helperText, statusText]);

    const child = React.Children.only(children);

    return wrap ? (
        <div className="field" style={{ display: "flex", flexDirection: "column" }}>
            {label && (
                <div className="field-label">
                    <label htmlFor={name}>
                        <b>{label}</b>
                    </label>
                </div>
            )}
            {helperText && <div className="field-helper-text">{helperText}</div>}
            <div className="field-content">
                {React.cloneElement(child, { id: htmlId, name })}
                {statusText && <div className={`field-status-text ${status}`} style={{ color: status === "error" ? "red" : undefined }}>{statusText}</div>}
            </div>
        </div>
    ) : <>{children}</>
};

export default Field;