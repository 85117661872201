import { SpinButton, type SpinButtonChangeEvent, type SpinButtonOnChangeData } from "@fluentui/react-components";
import { Flexbox, TextField } from "../../ui";

import type { OrganizationOwnerFormValues } from "../types";
import { useRef, useState } from "react";
import type { FormErrorValues } from "../../common/types";

function getOwnershipDisplayValue(ownership?: number | null): string {
    return ((ownership ?? 0) * 100).toFixed(2) + "%";
}

interface OrganizationOwnerFormProps {
    defaultValues?: OrganizationOwnerFormValues;
    errors?: FormErrorValues<OrganizationOwnerFormValues>;
    readOnly?: boolean;
    values?: OrganizationOwnerFormValues;
    onChange?: (values: OrganizationOwnerFormValues) => void;
}

export const OrganizationOwnerForm = (props: Readonly<OrganizationOwnerFormProps>) => {
    const { defaultValues, errors, readOnly, values, onChange } = props
    const valuesRef = useRef(values ?? defaultValues);
    const [ownershipValue, setOwnershipValue] = useState<number | undefined>(values?.ownership ?? valuesRef.current?.ownership);
    const [ownershipDisplayValue, setOwnershipDisplayValue] = useState<string | undefined>(getOwnershipDisplayValue(ownershipValue));

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        const target = (event.currentTarget ?? event.target) as HTMLInputElement;
        if (target) {
            const inputName = target.name;

            if (inputName) {
                valuesRef.current = {
                    ...valuesRef.current,
                    [inputName]: newValue,
                };
                props.onChange?.(valuesRef.current);
            }
        }
    };

    const handleSpinButtonChange = (_event: SpinButtonChangeEvent, data: SpinButtonOnChangeData) => {
        let formattedValue: string | undefined = undefined;
        if (data.value && data.value >= 0 && data.value <= 1) {
            formattedValue = (data.value ?? 0).toFixed(4);
        } else if (data.displayValue) {
            const parsedValue = parseFloat(data.displayValue);
            const floatValue = isNaN(parsedValue) ? 0 : parsedValue;
            const normalizedValue = ((floatValue > 1) ? floatValue / 100 : floatValue).toFixed(4);
            formattedValue = normalizedValue;
        }

        const nextValue = formattedValue ? parseFloat(formattedValue) : undefined;
        if (nextValue || nextValue === 0) {
            setOwnershipValue(nextValue);
            setOwnershipDisplayValue(getOwnershipDisplayValue(nextValue));
            onChange?.({
                ...valuesRef.current,
                ownership: nextValue,
            });
        }
    };

    return (
        <Flexbox
            direction="column"
            rowGap
        >
            <Flexbox rowGap columnGap>
                <TextField
                    defaultValue={defaultValues?.givenName}
                    label="First name"
                    name="givenName"
                    readOnly={readOnly}
                    required={true}
                    status={errors?.givenName ? "error" : undefined}
                    statusText={errors?.givenName}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    defaultValue={defaultValues?.surname}
                    label="Last name"
                    name="surname"
                    readOnly={readOnly}
                    required={true}
                    status={errors?.surname ? "error" : undefined}
                    statusText={errors?.surname}
                    onChange={handleTextFieldChange}
                />
            </Flexbox>
            <TextField
                defaultValue={defaultValues?.emailAddress}
                label="Email address"
                name="emailAddress"
                readOnly={readOnly}
                required={true}
                status={errors?.emailAddress ? "error" : undefined}
                statusText={errors?.emailAddress}
                onChange={handleTextFieldChange}
            />
            <div>
                <SpinButton
                    displayValue={ownershipDisplayValue}
                    min={0}
                    max={1}
                    precision={3}
                    step={0.001}
                    name="ownership"
                    placeholder="Ownership percentage"
                    readOnly={readOnly}
                    value={ownershipValue}
                    onChange={handleSpinButtonChange}
                />
            </div>
        </Flexbox>
    );
};