import type { PutUserRequest, User } from "../../client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { serviceClient } from "../../serviceClient";

interface UserSessionState {
    me: User | null;
    setupRequired?: boolean;
}

const initialState: UserSessionState = {
    me: null,
};

const get = createAsyncThunk(
    "me/get",
    async() => {
        return await serviceClient.me.get();
    },
);

const update = createAsyncThunk(
    "me/update",
    async(request: Partial<PutUserRequest>) => {
        await serviceClient.me.patch(request);
        return await serviceClient.me.get();
    },
);

const set = createAsyncThunk(
    "me/set",
    async(request: PutUserRequest) => {
        await serviceClient.me.put(request);
        return await serviceClient.me.get();
    },
);

export const meSlice = createSlice({
    name: "me",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(get.fulfilled, (state, action) => {
            if (action.payload.statusCode < 400) {
                state.me = action.payload.data;
            }
        });

        builder.addCase(get.rejected, (state) => {
            state.setupRequired = true;
        });

        builder.addCase(update.fulfilled, (state, action) => {
            if (action.payload.statusCode < 400) {
                state.me = {
                    ...action.payload.data,
                };
            }
        });

        builder.addCase(set.fulfilled, (state, action) => {
            if (action.payload.statusCode < 400) {
                state.me = action.payload.data;
            }
        });
    },
});

export const meActions = {
    get,
    set,
    update,
};

export default meSlice.reducer;