import { Button } from "@fluentui/react-components";
import { LockClosed48Regular } from "@fluentui/react-icons";
import type React from "react";

interface FeatureLockedProps extends React.PropsWithChildren {
    locked?: boolean;
    message: string;
    actionButtonProps?: {
        text: string;
        onClick: () => void;
    };
}

export const FeatureLocked: React.FC<FeatureLockedProps> = (props: Readonly<FeatureLockedProps>) => {
    const { children, message, locked, actionButtonProps } = props;

    return locked ? (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", rowGap: 8 }}>
            <LockClosed48Regular />
            <span>{message}</span>
            {actionButtonProps && (
                <Button appearance="primary" onClick={actionButtonProps.onClick}>{actionButtonProps.text}</Button>
            )}
        </div>
    ) : <>{children}</>;
};

export default FeatureLocked;