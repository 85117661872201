export enum JsonPatchOperations {
	/**
	 * Add a property or array element. For existing property: set value.
	 */
	add = "add",

	/**
	 * Remove a property or array element.
	 */
	remove = "remove",

	/**
	 * Same as remove followed by add at same location.
	 */
	replace = "replace",

	/**
	 * Same as add to destination using value from source.
	 */
	copy = "copy",

	/**
	 * 	Same as remove from source followed by add to destination using value from source.
	 */
	move = "move",

	/**
	 * Return success status code if value at path = provided value.
	 */
	test = "test",
}

export type JsonPatchOperationType = "add" | "copy" | "move" | "remove" | "replace" | "remove" | "test";

export class JsonPatchOperation<ObjectType = Record<string, unknown>, ValueType = unknown, OperationType = JsonPatchOperationType> {
	constructor(op: OperationType, path: keyof ObjectType, value: ValueType) {
		this.op = op;
		this.path = `/${String(path)}`;
		this.value = value;
	}

	readonly op: OperationType;

	readonly path: string;

	readonly value: ValueType;
}

export class JsonPatchAddOperation<ObjectType, ValueType> extends JsonPatchOperation<ObjectType, ValueType, "add"> {
	constructor(path: keyof ObjectType, value: ValueType) {
		super("add", path, value);
	}
}

export interface JsonPatchCopyOperation<T> extends JsonPatchOperation<T, "copy"> {
	from: string;
}

export interface JsonPatchMoveOperation<T> extends JsonPatchOperation<T, "move"> {
	from: string;
}

export class JsonPatchRemoveOperation<ObjectType, ValueType> extends JsonPatchOperation<ObjectType, ValueType, "remove"> {
	constructor(path: keyof ObjectType, value: ValueType) {
		super("remove", path, value);
	}
}

export class JsonPatchReplaceOperation<ObjectType, ValueType> extends JsonPatchOperation<ObjectType, ValueType, "replace"> {
	constructor(path: keyof ObjectType, value: ValueType) {
		super("replace", path, value);
	}
}

export interface JsonPatchTestOperation<T> extends JsonPatchOperation<T, "test"> {}

export class JsonPatchDocument<ObjectType> extends Array<JsonPatchOperation<ObjectType, unknown>> {
	public static isJsonPatchDocument(object: unknown): object is JsonPatchDocument<unknown> {
		return Array.isArray(object)
				&& object.length > 0
				&& typeof object[0] === "object"
				&& "op" in object[0]
				&& "path" in object[0]
				&& "value" in object[0];
	}
}