import { Body1, Body1Strong, Divider, Title3 } from "@fluentui/react-components";

import { FeatureLocked } from "../../../common";
import { FlowStep } from "../../../ui";
import type { RequestCredentialFlowStepProps } from "./types";
import routePaths from "../../../../router/routePaths";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RequestCredentialIntroFlowStep = (props: Readonly<RequestCredentialFlowStepProps>) => {
    const navigate = useNavigate();
    const { organization, onNavigate, ...stepProps } = props;
    const verificationStatus = organization?.verificationRequests?.status;

    const handleGetVerified = () => {
        navigate(routePaths.organizations.verify.replace(":organizationId", organization.id));
    };

    useEffect(() => {
        if (verificationStatus && verificationStatus !== "verified") {
        }
    }, [verificationStatus]);

    return (
        <FlowStep
            {...stepProps}
            title={`Credential ${organization?.name}`}
            onNavigate={onNavigate}
        >
            {(verificationStatus && verificationStatus !== "verified") ? (
                <FeatureLocked
                    message="It looks like your organization has not yet been verified, which is a prerequisite to be credentialed."
                    actionButtonProps={{
                        text: "Get Verified",
                        onClick: handleGetVerified,
                    }}
                />
            ) : (
                <div>
                    <Body1>Being a credentialed organization can increase visibility, provide access to a wider network of potential clients, and enhance credibility, ultimately helping businesses grow.</Body1><br />
                    <Title3>How it works</Title3><Divider />
                    <ol>
                        <li>Browse our catalog of available credentials and select the ones that your organization matches the criteria</li>
                        <li>Provide any supporting documentation for the selected credential requests.</li>
                        <li>Connect with an online notary (provided by OneCredential) to attest to the credentialing claims.</li>
                        <li>You're done!</li>
                    </ol>
                    <Body1Strong>Are you ready?</Body1Strong>
                </div>
            )}
        </FlowStep>
    );
};