import { useCallback, useState } from "react";
import type { OrganizationDetailsFormValues, AddOrganizationFormValuesChangeHandler } from "../../types";
import { AddOrganizationForm, type AddOrganizationFormValues } from "../../forms/AddOrganizationForm";
import { OrganizationAddressForm } from "../../forms/OrganizationAddressForm";
import { FlowStep } from "../../../ui/flow/FlowStep";
import { StringUtils } from "../../../../utils";
import { hasErrors, type FormErrorValues } from "../../../common/types";
import type { Address } from "client";
import { Flexbox, type FlowNavigationDirection, type FlowStepProps } from "components/ui";

interface OrganizationDetailsStepProps extends FlowStepProps {
    values?: OrganizationDetailsFormValues;
    onValuesChange?: AddOrganizationFormValuesChangeHandler;
}

export const AddOrganizationDetailsFlowStep = (props: Readonly<OrganizationDetailsStepProps>) => {
    const { values, onNavigate, onValuesChange } = props;
    const [formErrors, setFormErrors] = useState<FormErrorValues<OrganizationDetailsFormValues>>({});

    const handleSubmit = useCallback((values?: OrganizationDetailsFormValues) => {
        const errors: FormErrorValues<OrganizationDetailsFormValues> = {};
        if (StringUtils.isNullOrWhitespace(values?.usTaxpayerId)) {
            errors.usTaxpayerId = "A value is required for Employer Identification Number.";
        }

        if (StringUtils.isNullOrWhitespace(values?.organizationName)) {
            errors.organizationName = "A value is required for organization name.";
        }

        if (StringUtils.isNullOrWhitespace(values?.streetAddress)) {
            errors.streetAddress = "A value is required for street address.";
        }

        if (StringUtils.isNullOrWhitespace(values?.locality)) {
            errors.locality = "A value is required is for city, town or locality.";
        }

        if (StringUtils.isNullOrWhitespace(values?.region)) {
            errors.region = "A value is required for state, province or region.";
        }

        if (StringUtils.isNullOrWhitespace(values?.postalCode)) {
            errors.postalCode = "A value is required for postal code.";
        }

        setFormErrors(errors);
        return !hasErrors(errors);
    }, []);

    const handleNavigation = useCallback((option: FlowNavigationDirection) => {
        if (option === "forward" && !handleSubmit(values)) {
            return;
        }

        onNavigate?.(option);
    }, [handleSubmit, onNavigate, values]);

    const handleDetailsFormChange = useCallback((values: Partial<AddOrganizationFormValues>) => {
        onValuesChange?.(prev => ({
            ...prev,
            organizationName: values.name ?? prev.organizationName,
            usTaxpayerId: values.usTaxpayerId ?? prev.usTaxpayerId,
        }));
    }, [onValuesChange]);

    const handleAddressFormChange = useCallback((values: Partial<Address>) => {
        onValuesChange?.(prev => ({
            ...prev,
            ...values,
        }));
    }, [onValuesChange]);

    return (
        <FlowStep
            {...props}
            title="Let's add your organization's details"
            description="We will use these details to look up an official record of your organization to confirm that it is real."
            onNavigate={handleNavigation}
        >
            <Flexbox direction="column" rowGap={8}>
                <AddOrganizationForm
                    errors={{
                        name: formErrors?.organizationName,
                        usTaxpayerId: formErrors?.usTaxpayerId,
                    }}
                    extended
                    values={{
                        ...values,
                        name: values?.organizationName,
                    }}
                    onChange={handleDetailsFormChange}
                />
                <OrganizationAddressForm
                    errors={formErrors}
                    readOnlyCountry={true}
                    values={values}
                    onChange={handleAddressFormChange}
                />
            </Flexbox>
        </FlowStep>
    );
};
