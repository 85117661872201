import ErrorDetail from "./ErrorDetail";

export type ErrorResponse = {
	correlationId?: string;
	error: ErrorDetail;
	statusCode?: number;
};

export type ValidationErrorResponse = {
	type: string;
	title: string;
	status: number;
	traceId?: string;
	errors?: Record<string, string[]>;
};

export function isErrorResponse(input: unknown): input is ErrorResponse {
	/*
	{
		"correlationId":"9b9c5525-0f88-4e57-9bd6-5027af2addc6",
		"error": {
			"code":"not_entitled",
			"message":"You are not entitled to perform this action.",
			"details":null
		}
	}
	*/
	return typeof input === "object" && input !== null && "error" in input && typeof input.error === "object" && input.error !== null;
}

export function isValidationErrorResponse(input: unknown): input is ValidationErrorResponse {
	/*
	{
		"type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
		"title": "One or more validation errors occurred.",
		"status": 400,
		"traceId": "00-f37e77acbf71905141d89b2cdf68a896-c0ba72da05bc0adf-00",
		"errors": {
			"Contact.Address.CountryCode": [
			"Country code must be a two character uppercase string."
			],
			"CustomFields[0].Type": [
			"'string' is not a valid value.
			]
		}
	}
	*/
	return typeof input === "object" && input !== null
		&& "type" in input
		&& "status" in input && typeof input.status === "number"
		&& "title" in input && typeof input.title === "string" && input.title === "One or more validation errors occurred.";
}

export default ErrorResponse;