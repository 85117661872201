import type { AcceptableDocument, CollectionResponse } from "../../models";

import type { ODataCollectionQueryOptions } from "../../models/ODataCollectionQueryOptions";
import RequestBuilder from "../RequestBuilder";

export class AcceptableDocumentCollectionRequestBuilder extends RequestBuilder {
    public async get(nextLinkOrQueryOptions?: string | ODataCollectionQueryOptions) {
        const requestUrl = typeof(nextLinkOrQueryOptions) === "string" ? nextLinkOrQueryOptions : this.baseUrl;
        const response = await this.client.get<CollectionResponse<AcceptableDocument>>(requestUrl);

        return this.getResponseObjectOrThrow(response);
    }
}