import { FormChoiceInputElementOption, type DocumentVersion, type FormElement, type FormInputElement, type FormSectionElement } from "client";
import React, { useCallback } from "react";
import { ArrayUtils, StringUtils } from "../../utils";
import { ChoiceGroup, TextField } from "../ui";
import { FileInput } from "../common";
import { Body1, Body1Strong, Dropdown, Option, type OptionOnSelectData, type SelectionEvents } from "@fluentui/react-components";
import type { DynamicFormInputElementValueType, FormFile, FormPluginRender } from "./types";
import { DocumentDetailCard } from "../common/data/DocumentDetailCard";

const tokenRegex = /{{([a-zA-Z0-9.]+)}}/gm;

function getDefaultFormFileList(element: FormElement, defaultValue: unknown): ReadonlyArray<FormFile> {
	const emptyFileList = ArrayUtils.emptyV2<FormFile>();
	if (element.elementType.toUpperCase() !== "INPUT") {
		return emptyFileList;
	}

	const inputElement = element as FormInputElement;
	if (inputElement.inputType?.toUpperCase() !== "FILE") {
		return emptyFileList;
	}

	if (!defaultValue) {
		return emptyFileList;
	}

	if (Array.isArray(defaultValue)) {
		return defaultValue.map(value => value as FormFile);
	}

	if (typeof defaultValue === "object") {
		return [defaultValue as FormFile];
	}

	return emptyFileList;
}

interface DynamicFormElementProps<TValue extends DynamicFormInputElementValueType = DynamicFormInputElementValueType> {
	defaultValue?: TValue;
    element: FormElement;
	readOnly?: boolean;
	resolveToken?: (elementId: string, token: string) => string | undefined;
	onRenderPlugin?: FormPluginRender;
	onDeleteFile?: (elementId: string, elementName: string, formFile: FormFile) => Promise<boolean>;
    onChange?: (id: string, name: string, value: TValue | ReadonlyArray<FormFile>) => void;
}

export const DynamicFormElement = React.forwardRef<HTMLDivElement, DynamicFormElementProps>((props, forwardedRef) => {
    const {
		defaultValue,
		element,
		readOnly,
		resolveToken,
		onChange,
		onDeleteFile,
		onRenderPlugin,
	} = props;
	const [inProgressFormFiles, setInProgressFormFiles] = React.useState<ReadonlyArray<string>>([]);
	const [formFiles, setFormFiles] = React.useState<ReadonlyArray<FormFile>>(getDefaultFormFileList(element, defaultValue));

    const handleBooleanFieldChange = useCallback((_e: React.ChangeEvent<HTMLDivElement>, value: boolean) => {
		const inputElement = element as FormInputElement;
        onChange?.(element.id, inputElement.name, value);
    }, [element, onChange]);

	const handleChoiceGroupFieldChange = useCallback((_e: React.ChangeEvent<HTMLDivElement>, value: any) => {
		const inputElement = element as FormInputElement;
		onChange?.(element.id, inputElement.name, value);
	}, [element, onChange]);

	const handleDropdownOptionSelect = useCallback((_e: SelectionEvents, data: OptionOnSelectData) => {
		const inputElement = element as FormInputElement;
		onChange?.(element.id, inputElement.name, data.selectedOptions);
	}, [element, onChange]);

	const handleFormFileDelete = useCallback((_e: React.MouseEvent, documentVersion: DocumentVersion) => {
		const inputElement = element as FormInputElement;
		if (onDeleteFile && inputElement) {
			setInProgressFormFiles((prev) => [...prev, documentVersion.id]);
			onDeleteFile(element.id, inputElement.name, { id: documentVersion.id, name: documentVersion.name, uri: "" }).then((success) => {
				if (success) {
					setFormFiles((prev) => prev.filter(file => file.id !== documentVersion.id));
				}
			}).finally(() => {
				setInProgressFormFiles((prev) => prev.filter(id => id !== documentVersion.id));
			});
		}
	}, [element, onDeleteFile]);

	const handleFileInputChange = useCallback((files: ReadonlyArray<FormFile>) => {
		onChange?.(element.id, (element as FormInputElement).name, files);
	}, [element, onChange]);

    const handleTextFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        onChange?.(element.id, e.target.name, newValue);
    }, [element.id, onChange]);

	const handlePluginValueChange = useCallback((value: DynamicFormInputElementValueType) => {
		onChange?.(element.id, (element as FormInputElement).name, value);
	}, [element, onChange]);

	const interpolateString = useCallback((input: Readonly<string>) => {
		let output = input;
		let match: RegExpExecArray | null;
		while ((match = tokenRegex.exec(output)) !== null) {
			if (match.index === tokenRegex.lastIndex) {
				tokenRegex.lastIndex++;
			}

			if (match.length === 2) {
				const strToReplace = match[0];
				const replacementStr = resolveToken?.(element.id, match[1]) ?? strToReplace;
				output = output.replace(strToReplace, replacementStr);
			}
		}

		return output;
	}, [element?.id, resolveToken]);

    const renderSection = useCallback((section: FormSectionElement): React.ReactNode => {
		return (
			<div ref={forwardedRef}>
				{section.title && <h2>{section.title}</h2>}
				{!StringUtils.isNullOrEmpty(section.description) && (
					<p>{section.description}</p>
				)}
			</div>
		);
	}, [forwardedRef]);

	const renderField = (fieldProps: FormInputElement) => {
        const {
			label: rawLabel,
            inputType,
            multiple,
			name,
			options = ArrayUtils.emptyV2<FormChoiceInputElementOption>(),
            required,
        } = fieldProps ?? {};

		let label = interpolateString(rawLabel);
		const normalizedInputType = inputType?.toUpperCase();
		switch (normalizedInputType) {
		case "TEXT":
			return (
				<div>
					<TextField
						defaultValue={defaultValue?.toString()}
						label={label}
						name={name}
						// minRows={fieldProps.multiple ? 3 : undefined}
						// multiline={fieldProps.multiple}
						placeholder="Enter your answer here"
						readOnly={readOnly}
						required={required}
                        onChange={handleTextFieldChange}
					/>
				</div>
			);
		case "BOOLEAN":
			return (
				<div>
					<ChoiceGroup
						defaultValue={defaultValue !== undefined ? Boolean(defaultValue) : undefined}
						label={label}
						name={name}
						options={[{
							value: true,
							text: "Yes",
						}, {
							value: false,
							text: "No",
						}]}
						readOnly={readOnly}
						required={required}
                        onChange={handleBooleanFieldChange}
					/>
				</div>
			);
		case "FILE":
			return (
				<div>
					{(multiple || formFiles.length === 0) && (
						<FileInput
							label={label}
							multiple={multiple}
							name={name}
							readOnly={readOnly}
							required={required}
							onChange={handleFileInputChange}
						/>
					)}
					{(formFiles).map((formFile, fileIndex) => (
						<DocumentDetailCard
							key={formFile.id}
							actionInProgress={inProgressFormFiles.includes(formFile.id)}
							document={{
								id: formFile.id,
								documentId: formFile.id,
								name: formFile.name ?? `File ${fileIndex + 1}`,
							}}
							onDelete={(readOnly || !onDeleteFile) ? undefined : handleFormFileDelete}
						/>
					))}
				</div>
			);
		case "CHOICE":
			const defaultValueForDropdown = defaultValue === null ? (multiple ? [] : "") : (Array.isArray(defaultValue) ? defaultValue.map(v => v.toString()) : defaultValue?.toString());
			return (options?.length > 5) ? (
                <Dropdown
					defaultValue={defaultValueForDropdown}
					disabled={readOnly}
					multiselect={multiple}
					name={name}
					onOptionSelect={handleDropdownOptionSelect}
				>
                    {options.map(option => (
                        <Option
                            key={option.value}
                            value={option.value}
                            text={option.text ?? option.value}
                        >
                            {(option.text && option.secondaryText) ? (
                                <>
                                    <Body1Strong>{option.text}</Body1Strong>
                                    <Body1>{option.secondaryText}</Body1>
                                </>
                            ) : option.text ?? option.value}
                        </Option>
                    ))}
                </Dropdown>
            ) : (
				<div>
					<ChoiceGroup
						defaultValue={defaultValue}
						label={label}
                        layout="vertical"
						multiple={multiple}
						name={name}
						options={options?.map(option => ({
							value: option.value,
							text: option.text ?? option.value,
                            secondaryText: option.secondaryText,
						})) ?? []}
						readOnly={readOnly}
						required={required}
						onChange={handleChoiceGroupFieldChange}
					/>
				</div>
			);
		// case "datetime":
		// 	return (
		// 		<div>
		// 			<DatePicker
		// 				disabled={true}
		// 				label={fieldProps.label}
		// 				placeholder="Select a date"
		// 			/>
		// 		</div>
		// 	);

		case "PLUGIN":
			return onRenderPlugin?.(fieldProps, { defaultValue, readOnly }, handlePluginValueChange);
		default:
			break;
		}
	};

	const normalizedElementType = element?.elementType?.toUpperCase();
	switch (normalizedElementType) {
		case "SECTION":
			return renderSection(element as FormSectionElement);
		case "INPUT":
			return renderField(element as FormInputElement);
        default:
            console.error(`Unknown element type: ${element.elementType}`);
            return null;
    }
});

export default DynamicFormElement;