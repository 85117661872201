import type { ReactNode } from "react";

interface Title2Props {
    children: ReactNode;
    color?: string;
    textAlignment?: "left" | "center" | "right";
}

export const Title2 = (props: Readonly<Title2Props>) => {
    const { children, color, textAlignment } = props;

    return (
        <h2
            style={{
                color,
                textAlign: textAlignment,
                fontSize: 24,
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "32px",
                letterSpacing: -0.48,
                marginTop: "unset",
                marginBottom: "unset",
            }}
        >
            {children}
        </h2>
    );
}