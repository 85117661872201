import { Button, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from "@fluentui/react-components";
import { CompleteApprovalResultDialog, DocumentPreview, FormattedAddress } from "../../common";
import { DateUtils, StringUtils } from "../../../utils";
import { selectVerificationRequestById, verificationRequestActions } from "../../../store/verifications";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useCallback, useEffect, useState } from "react";

import { Loader } from "../../ui";

interface VerificationDocumentReviewPanelProps {
    documentId: string;
    verificationId: string;
    open: boolean;
    onClose: (reason: "close" | "submit") => void;
}

const DrawerImpl = (props: Readonly<VerificationDocumentReviewPanelProps>) => {
    const { documentId, verificationId, onClose } = props;
    const dispatch = useAppDispatch();
    const verification = useAppSelector(s => !verificationId ? undefined : selectVerificationRequestById(s, verificationId));
    const document = verification?.documents.find(d => d.id === documentId);
    const [loading, setLoading] = useState<boolean>(document?.id !== documentId);
    const [approvalResultDialogOpen, setApprovalResultDialogOpen] = useState<boolean>(false);

    const renderNotFoundError = (resourceType: 'verification' | 'document') => {
        return (
            <div>
                {resourceType === 'verification' ? (
                    <h2>We're unable to find a verification for the id '{verificationId}'.</h2>
                ) : (
                    <h2>We're unable to find the requested document with id '{documentId}'.</h2>
                )}
            </div>
        );
    };

    const handleCloseBtnClick = () => {
        onClose("close");
    };

    const handleApprovalResultDialogClose = useCallback((reason: "cancel" | "submit") => {
        setApprovalResultDialogOpen(false);
        if (reason === "submit") {
            onClose("submit");
        }
    }, [onClose]);

    useEffect(() => {
        if (verificationId) {
            const action = dispatch(verificationRequestActions.getById(verificationId));
            action.finally(() => {
                setLoading(false);
            });
        }
    }, [dispatch, verificationId]);

    return (
        <>
            <Drawer
                open={true}
                position="end"
                separator={true}
                size="large"
                modalType="non-modal"
                type="overlay"
            >
                <DrawerHeader>
                    <DrawerHeaderTitle>Review {document?.name ?? "document"}</DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <Loader loading={loading}>
                        {(verification && document) ? (
                            <div style={{ display: "flex", flexDirection: "column", height: "calc(100% - 16px)", gap: 4, paddingBottom: 16 }}>
                                <div>{verification.organization?.name}</div>
                                {verification.organization?.registeredAddress && (
                                    <div><FormattedAddress address={verification.organization?.registeredAddress} multiline /></div>
                                )}
                                {document.webUrl ? (
                                    <DocumentPreview webUrl={document.webUrl} />
                                ) : (
                                    <h3>The requestor has not yet uploaded the document.</h3>
                                )}
                            </div>
                        ) : renderNotFoundError(verification ? 'document' : 'verification')}
                    </Loader>
                </DrawerBody>
                <DrawerFooter>
                    <Button onClick={handleCloseBtnClick}>Close</Button>
                    {!document?.approvalResult?.value ? (
                        <Button appearance="primary" onClick={() => setApprovalResultDialogOpen(true)}>Complete review</Button>
                    ) : (
                        <span>{StringUtils.toTitleCase(document.approvalResult.value)} on {DateUtils.formatV2(document.approvalResult.createdAt, "MM/DD/YYYY")}.{document.approvalResult.reason && (` Reason: ${document.approvalResult.reason}`)}</span>
                    )}
                </DrawerFooter>
            </Drawer>
            {(verification?.id && document?.id) && (
                <CompleteApprovalResultDialog
                    open={approvalResultDialogOpen}
                    documentId={document.id}
                    verificationId={verification.id}
                    onClose={handleApprovalResultDialogClose}
                />
            )}
        </>
    );
};

export const VerificationDocumentReviewDrawer = (props: Readonly<VerificationDocumentReviewPanelProps>) => {
    const { open } = props;

    return open ? <DrawerImpl {...props} /> : null;
};

export default VerificationDocumentReviewDrawer;