import { Button } from "@fluentui/react-components";
import { ResolveDocumentDialog } from "./ResolveDocumentDialog";
import { useState } from "react";

interface DocumentResolutionDialogTriggerProps {
    documentId: string;
    verificationId: string;
    organizationId: string;
    onClose?: (reason: "cancel" | "submit") => void;
}

export const DocumentResolutionDialogTrigger = (props: Readonly<DocumentResolutionDialogTriggerProps>) => {
    const { documentId, organizationId, verificationId, onClose } = props;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleResolveBtnClick = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = (reason: "cancel" | "submit") => {
        setDialogOpen(false);
        onClose?.(reason);
    };

    return (
        <>
            <Button
                appearance="primary"
                onClick={handleResolveBtnClick}
            >
                Resolve
            </Button>
            <ResolveDocumentDialog
                open={dialogOpen}
                documentId={documentId}
                organizationId={organizationId}
                verificationId={verificationId}
                onClose={handleDialogClose}
            />
        </>
    );
};