import type { AuthenticationProvider } from "./client/AuthenticationProvider";
import type { PublicClientApplication } from "@azure/msal-browser";

export class MsalAuthenticationProvider implements AuthenticationProvider {
    private msalInstance: PublicClientApplication;

    constructor(msalInstance: PublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async authenticate(request: Request, scopes?: ReadonlyArray<string>): Promise<void> {
        try {
            const account = this.msalInstance.getAllAccounts()[0];
            const accessToken = await this.msalInstance.acquireTokenSilent({
                account: account,
                scopes: scopes ? [...scopes] : ['https://onecredential.io/api/user_impersonation'],
            });

            request.headers.set('Authorization', `Bearer ${accessToken.accessToken}`);
        } catch (error) {
            // Handle authentication errors
            console.error('Authentication failed:', error);
            throw error;
        }
    }
}