// spell-checker: ignore backoffice

export const routePaths = {
    index: "/",
    dashboard: "/dashboard",
    account: {
        settings: "/account/settings",
    },
    backOffice: {
        root: "/backoffice",
        verifications: {
            root: "/backoffice/verifications",
            details: "/backoffice/verifications/:verificationId",
        },
    },
    credentials: {
        root: "/credentials",
        add: "/credentials/add",
    },
    setup: {
        account: "/setup/account",
    },
    organizations: {
        root: "/organizations",
        list: "/organizations/list",
        add: "/organizations/add",
        view: "/organizations/view/:organizationId",
        verify: "/organizations/verify/:organizationId",
        credential: "/organizations/credential/:organizationId",
        viewCertificate: "/organizations/view/:organizationId/certificate",
    }
};

export default routePaths;