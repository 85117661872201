import type { ApiResponse, VerificationDocument } from "../../models";

import { ApprovalResultRequestBuilder } from "../approvals/ApprovalResultRequestBuilder";
import { DocumentContentRequestBuilder } from "../documents/DocumentContentRequestBuilder";
import RequestBuilder from "../RequestBuilder";

export class VerificationDocumentRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<VerificationDocument>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public get content() {
        return new DocumentContentRequestBuilder(this._appendUrlSegment("$content"), this.client);
    }

    public get approvalResult() {
        return new ApprovalResultRequestBuilder(this._appendUrlSegment("approvalResult"), this.client);
    }

    public async delete(): Promise<ApiResponse> {
        const requestUrl = this.baseUrl;
        return await this.client.delete<void>(requestUrl);
    }
}

export default VerificationDocumentRequestBuilder;