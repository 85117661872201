interface TextButtonProps {
    style?: React.CSSProperties;
    text?: string;
    underline?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const TextButton = (props: Readonly<TextButtonProps>) => {
    const { style, text, underline, onClick } = props;

    return (
        <button
            style={{
                ...style,
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "1rem",
                padding: 0,
                textDecoration: underline ? "underline" : undefined,
            }}
            onClick={onClick}
        >
            {text}
        </button>
    );
}