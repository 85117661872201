import { ArrowDownloadRegular, PrintRegular } from "@fluentui/react-icons";
import { Flexbox, Skeleton, Title2 } from "../ui";
import { Toolbar, ToolbarButton, ToolbarDivider } from "@fluentui/react-components";

import { DateUtils } from "../../utils";
import { FormattedAddress } from "../common";
import type { Organization } from "../../client";
import QRCode from "react-qr-code";
import React from "react";
import generatePDF from "react-to-pdf";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

const borderRadius = 16;

interface DigitalCertificateProps {
    loading?: boolean;
    organization: Organization;
}

export const DigitalCertificate = (props: Readonly<DigitalCertificateProps>) => {
    const { loading, organization } = props;
    const verification = organization?.verification;
    const printableContentRef = React.useRef<HTMLDivElement>(null);
    const print = useReactToPrint({ contentRef: printableContentRef });
    const navigate = useNavigate();

    const handleDownloadBtnClick = React.useCallback(() => {
        const contentToPrint = printableContentRef.current;
        if (contentToPrint) {
            generatePDF(() => contentToPrint, {
                filename: `OneCredential Organization Verification Certificate - ${organization.lookupKey}.pdf`,
                page: {
                    orientation: "landscape",
                },
            });
        }
    }, [organization?.lookupKey]);

    const handlePrintBtnClick = React.useCallback(() => {
        print();
    }, [print]);

    if (!verification) {
        navigate(-1);
    }

    return (
        <Flexbox
            direction="column"
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: 1180,
            }}
        >
            <Toolbar style={{ justifyContent: "flex-end" }}>
                <ToolbarDivider />
                <ToolbarButton
                    aria-label="Download certificate"
                    icon={<ArrowDownloadRegular />}
                    title="Download certificate"
                    onClick={handleDownloadBtnClick}
                />
                <ToolbarButton
                    aria-label="Print certificate"
                    icon={<PrintRegular />}
                    title="Print certificate"
                    onClick={handlePrintBtnClick}
                />
            </Toolbar>
            <Flexbox
                direction="column"
                ref={printableContentRef}
                style={{
                    background: "url(/Certificate_Watermark.png) repeat",
                    boxSizing: "border-box",
                    height: 832,
                    flexGrow: 1,
                    width: 1180,
                    aspectRatio: "1.42 / 1",
                }}
            >
                <Flexbox
                    direction="column"
                    rowGap
                    horizontalAlign="center"
                    style={{
                        backgroundColor: "#0380FC",
                        borderTopLeftRadius: borderRadius,
                        borderTopRightRadius: borderRadius,
                        paddingTop: 32,
                        paddingBottom: 32,
                    }}
                    verticalAlign="center"
                >
                    <Title2 color="#FFF">Organization Verification Certificate</Title2>
                    <Skeleton loading={loading}>
                        <span style={{ color: "white" }}>Org Code: {organization?.lookupKey}</span>
                    </Skeleton>
                </Flexbox>
                <Flexbox
                    direction="column"
                    grow={1}
                    style={{
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        minHeight: 128,
                        paddingLeft: 24,
                        paddingRight: 24,
                    }}
                >
                    <Flexbox>
                        <Flexbox
                            direction="column"
                            rowGap={32}
                        >
                            <Flexbox
                                direction="column"
                            >
                                <span>It is herby certified that</span>
                                <Title2>{organization?.name}</Title2>
                                <span>has completed a verification process in accordance with the OneCredential organization verification and credentialing procedures.</span>
                            </Flexbox>
                            <Flexbox>
                                <Flexbox direction="column" rowGap={8}>
                                    <div>Org Code: <b>{organization.lookupKey}</b></div>
                                    <div>Issued on: <b>{DateUtils.formatV2(verification?.verifiedAt, "DD MMM YYYY")}</b></div>
                                    <div>Expires after: <b>{DateUtils.formatV2(verification?.expirationDate, "DD MMM YYYY")}</b></div>
                                    <div>
                                        <span style={{ verticalAlign: "top" }}>Registered address:&nbsp;</span>
                                        <b style={{ display: "inline-block" }}><FormattedAddress address={organization.registeredAddress!} multiline={true} /></b>
                                    </div>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox>
                        <Flexbox columnGap={12} verticalAlign="center">
                            <QRCode
                                style={{
                                    background: "white",
                                    padding: "12px",
                                    height: 128,
                                    width: 128,
                                }}
                                value={`https://verify.onecredential.io/${organization?.lookupKey}`}
                            />
                            <p style={{ maxWidth: 108 }}>Scan this QR code or visit <a href="https://verify.onecredential.io">https://verify.onecredential.io</a> to validate this certificate.</p>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                <Flexbox
                    style={{
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        borderBottomLeftRadius: borderRadius,
                        borderBottomRightRadius: borderRadius,
                        padding: 24,
                    }}
                >
                    <div
                        className="full-width"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        This certificate is issued by OneCredential.io and remains the property of the issuing authority. Unauthorized reproduction or alteration is strictly prohibited.
                    </div>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
}