import { createEntityAdapter, createSlice, type EntityState } from "@reduxjs/toolkit";

import type { OrganizationCredentialRequest } from "../../client";
import { RootState } from "../types";
import { credentialRequestActions } from "./actions";

const credentialRequestsEntityAdapter = createEntityAdapter<OrganizationCredentialRequest>({
    sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

interface CredentialRequestListState {
    items: Array<string>;
    nextLink?: string;
}

interface CredentialRequestStoreState extends EntityState<OrganizationCredentialRequest, string> {
    selectedId: string | null;
    list: CredentialRequestListState;
}

const initialState: CredentialRequestStoreState = credentialRequestsEntityAdapter.getInitialState({
    selectedId: null,
    list: {
        items: [],
    },
    selectedCredential: null,
});

export const credentialRequestsSlice = createSlice({
    name: "credentialRequests",
    initialState,
    reducers: {
        setSelectedCredential: (state, action) => {
            state.selectedId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(credentialRequestActions.get.fulfilled, (state, action) => {
            credentialRequestsEntityAdapter.upsertMany(state, action.payload.value);
            state.list.items = action.payload.value.map((credential) => credential.id);
            state.list.nextLink = action.payload["odata.nextLink"];
        });

        builder.addCase(credentialRequestActions.getById.fulfilled, (state, action) => {
            credentialRequestsEntityAdapter.upsertOne(state, action.payload);
        });
    },
});

export const {
    selectAll: selectAllCredentialRequests,
    selectById: selectCredentialRequestById,
} = credentialRequestsEntityAdapter.getSelectors<RootState>(
    (state) => state.credentialRequests,
);



export default credentialRequestsSlice.reducer;