import type { OrganizationCredentialRequest } from "../../../models";
import { OrganizationCredentialRequestItemCollectionBuilder } from "./OrganizationCredentialRequestItemCollectionRequestBuilder";
import RequestBuilder from "../../RequestBuilder";

export class OrganizationCredentialRequestRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<OrganizationCredentialRequest>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async submit() {
        const requestUrl = this.baseUrl + "/submit";
        const response = await this.client.post<OrganizationCredentialRequest>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public get items() {
        return new OrganizationCredentialRequestItemCollectionBuilder(this._appendUrlSegment("items"), this.client);
    }
};