import { createAsyncThunk } from "@reduxjs/toolkit";
import { serviceClient } from "../../serviceClient";

const get = createAsyncThunk(
    "credentialRequests/get",
    async(args: { organizationId: string, nextLink?: string }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId).credentialRequests
            .get(args.nextLink);
        return response.data;
    },
);

const getById = createAsyncThunk(
    "credentialRequests/getById",
    async(args: { organizationId: string, credentialRequestId: string }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId).credentialRequests
            .credentialRequest(args.credentialRequestId)
            .get();
        return response.data;
    },
);

const submit = createAsyncThunk(
    "credentialRequests/submit",
    async(args: { organizationId: string, credentialRequestId: string }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId).credentialRequests
            .credentialRequest(args.credentialRequestId)
            .submit();
        return response.data;
    },
);

export const credentialRequestActions = {
    get,
    getById,
    submit,
};