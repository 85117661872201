export class TimeSpan {
	private static _second = 1000;
	private static _minute = TimeSpan._second * 60;
	private static _hour = TimeSpan._minute * 60;
	private static _day = TimeSpan._hour * 24;

	private readonly _totalMilliseconds: number;

	constructor(totalMilliseconds: number) {
		this._totalMilliseconds = totalMilliseconds;
	}

	public get totalMilliseconds(): number {
		return this._totalMilliseconds;
	}

	public get totalSeconds(): number {
		return this._totalMilliseconds / TimeSpan._second;
	}

	public get totalMinutes(): number {
		return this._totalMilliseconds / TimeSpan._minute;
	}

	public get totalHours(): number {
		return this._totalMilliseconds / TimeSpan._hour;
	}

	public get totalDays(): number {
		return this._totalMilliseconds / TimeSpan._day;
	}

	public static create(d1: Date | number, d2: Date | number) {
		const subtrahend = new Date(d1);
		const minuend = new Date(d2);

		const elapsedTime = minuend.valueOf() - subtrahend.valueOf();
		return new TimeSpan(elapsedTime);
	}

	public static fromSeconds(seconds: number) {
		return new TimeSpan(this._second * seconds);
	}

	public static fromMinutes(minutes: number) {
		return new TimeSpan(this._minute * minutes);
	}
}

export default TimeSpan;