import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle, Dropdown, Field, Option, Textarea, type OptionOnSelectData, type SelectionEvents, type TextareaOnChangeData } from "@fluentui/react-components";
import type React from "react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { verificationRequestActions } from "../../../store/verifications";
import { ButtonWithLoader } from "../buttons";

interface FormErrors {
    reason?: string;
    result?: string;
}

interface CompleteApprovalResultDialogProps {
    open: boolean;
    verificationId: string;
    documentId: string;
    onClose: (reason: "cancel" | "submit") => void;
}

const DialogImpl = (props: CompleteApprovalResultDialogProps) => {
    const { documentId, verificationId, onClose } = props;
    const dispatch = useAppDispatch();
    const [result, setResult] = useState<string>();
    const [reason, setReason] = useState<string>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    const handleResultSelectChange = (_ev: SelectionEvents, data: OptionOnSelectData) => {
        setResult(data.optionValue);
    };

    const handleReasonChange = (_ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
        setReason(data.value);
    };

    const handleSubmitBtnClick = () => {
        if (!result) {
            setFormErrors({ result: "An approval result must be selected." });
            return;
        }

        if (result === "reject") {
            if (!reason || reason.length === 0) {
                setFormErrors({ reason: "A rejection reason must be provided." });
                return;
            } else if (reason.length < 10) {
                setFormErrors({ reason: "Please provide a more detailed rejection explanation." });
                return;
            }
        }

        setFormErrors({});

        let action;
        if (result === "approve") {
            action = dispatch(verificationRequestActions.approveDocument({ verificationId, documentId }));
        } else if (result === "reject") {
            action = dispatch(verificationRequestActions.rejectDocument({ verificationId, documentId, reason: reason! }));
        }

        if (action) {
            setInProgress(true);
            action
                .then(() => {
                    onClose("submit");
                })
                .finally(() => {
                    setInProgress(false);
                });
        }
    };

    const isSubmitEnabled = result === "approve" || (result === "reject" && reason && reason.length >= 0);

    return (
        <Dialog
            open={true}
        >
            <DialogSurface>
                <DialogTitle></DialogTitle>
                <DialogBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Field
                            validationState={formErrors.result ? "error" : undefined}
                            validationMessage={formErrors.result}
                        >
                            <Dropdown
                                placeholder="Select an approval result"
                                onOptionSelect={handleResultSelectChange}
                            >
                                <Option value="approve">Approve</Option>
                                <Option value="reject">Reject</Option>
                            </Dropdown>
                        </Field>
                        {result === "reject" && (
                            <Field
                                label="Reason for rejection"
                                validationState={formErrors.reason ? "error" : undefined}
                                validationMessage={formErrors.reason}
                            >
                                <Textarea
                                    placeholder="Reason for rejection"
                                    rows={3}
                                    onChange={handleReasonChange}
                                />
                            </Field>
                        )}
                    </div>
                    <DialogActions>
                        <ButtonWithLoader
                            appearance="primary"
                            disabled={!isSubmitEnabled}
                            loading={inProgress}
                            onClick={handleSubmitBtnClick}
                        >
                            Submit
                        </ButtonWithLoader>
                        <Button
                            disabled={inProgress}
                            onClick={() => onClose("cancel")}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export const CompleteApprovalResultDialog = (props: CompleteApprovalResultDialogProps) => {
    const { documentId, open } = props;
    const [refreshKey, setRefreshKey] = useState<number>(0);

    useEffect(() => {
        if (open) {
            setRefreshKey((prev) => prev + 1);
        }
    }, [documentId, open]);

    return open ? <DialogImpl key={refreshKey} {...props} /> : null;
};