import type { AddOrganizationRequest, Organization } from "../../../../client";
import { Flexbox, FlowStep } from "../../../ui";
import { isPayloadAction, organizationActions } from "../../../../store";
import { useCallback, useEffect, useState } from "react";

import type { FlowNavigationDirection } from "../../../ui/flow/types";
import { OrganizationDetailsCard } from "../../cards";
import { OrganizationOwnersCard } from "../../cards/OrganizationOwnersCard";
import type { OrganizationSetupFormValues } from "../../types";
import type { OrganizationSetupStepProps } from "./types";
import { StringUtils } from "../../../../utils";
import { useAppDispatch } from "../../../../hooks";

export const AddOrganizationReviewFlowStep = (props: Readonly<OrganizationSetupStepProps>) => {
    const { organization, values, onNavigate, onValuesChange, ...stepProps } = props;
    const dispatch = useAppDispatch();
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const submit = useCallback((values?: OrganizationSetupFormValues) => {
        return new Promise<string>((resolve, reject) => {
            if (!values) {
                reject(new Error("Values are not provided."));
                return;
            }

            let identifiers: Record<string, string> = {};
            if (!StringUtils.isNullOrWhitespace(values.usTaxpayerId)) {
                identifiers["usTaxpayerId"] = values.usTaxpayerId;
            };

            const baseRequest = {
                name: values.organizationName,
                registeredAddress: {
                    streetAddress: values.streetAddress,
                    locality: values.locality,
                    region: values.region,
                    postalCode: values.postalCode,
                    countryCode: values.countryCode,
                },
                identifiers: identifiers,
            };

            let action: Promise<unknown>;
            if (StringUtils.isNullOrWhitespace(values.organizationId)) {
                const addOrganizationRequest: AddOrganizationRequest = {
                    ...baseRequest,
                    name: values.organizationName!,
                };
                action = dispatch(organizationActions.add(addOrganizationRequest));
            } else {
                const patchOrganizationRequest: Partial<Organization> = {
                    ...baseRequest,
                };
                action = dispatch(organizationActions.update({ organizationId: values.organizationId, request: patchOrganizationRequest }));
            }

            action
                .then((result) => {
                    if (isPayloadAction<Organization>(result)) {
                        if (result.error) {
                            reject(result.error);
                        } else {
                            if (result.payload.id) {
                                onValuesChange?.(prev => ({
                                    ...prev,
                                    organizationId: result.payload.id,
                                }));
                            }

                            resolve(values?.organizationId ?? result.payload.id);
                        }
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }, [dispatch, onValuesChange]);

    const handleStepNavigation = useCallback((option: FlowNavigationDirection) => {
        if (option !== "forward") {
            onNavigate?.(option);
            return;
        }

        setSubmitInProgress(true);
        submit(values).then(() => {
            onNavigate?.(option);
        }).finally(() => {
            setSubmitInProgress(false);
        });
    }, [submit, onNavigate, values]);

    useEffect(() => {
        if (StringUtils.isNullOrWhitespace(values?.organizationId)) {
            onNavigate?.("backward");
        }
    }, [values?.organizationId, onNavigate]);

    return (
        <FlowStep
            {...stepProps}
            title="Let's review your organization's details"
            description="Please review the information you have provided. If you need to make change, simply click on the section you want to edit."
            actionsProps={{
                actionInProgress: submitInProgress,
            }}
            onNavigate={handleStepNavigation}
        >
            <Flexbox direction="column" rowGap>
                <OrganizationDetailsCard
                    organization={organization}
                />
                <OrganizationOwnersCard
                    organization={organization}
                />
            </Flexbox>
        </FlowStep>
    );
};