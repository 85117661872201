import type { AddOrganizationRequest, Organization } from "../../client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { organizationsSlice } from "./slice";
import { serviceClient } from "../../serviceClient";

const add = createAsyncThunk(
    "organizations/add",
    async(request: AddOrganizationRequest) => {
        return (await serviceClient.organizations.add(request)).data;
    },
);

const get = createAsyncThunk(
    "organizations/get",
    async(nextLink?: string) => {
        return (await serviceClient.organizations.get(nextLink)).data;
    },
);

const getById = createAsyncThunk(
    "organizations/getById",
    async(organizationId: string) => {
        return (await serviceClient.organizations.organization(organizationId).get()).data;
    },
);

const update = createAsyncThunk(
    "organizations/update",
    async(args: { organizationId: string, request: Partial<Organization> }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId)
            .patch(args.request);

        if (response.statusCode >= 400) {
            throw new Error("Update failed");
        }

        return response;
    },
);

const remove = createAsyncThunk(
    "organizations/delete",
    async(organizationId: string) => {
        return await serviceClient.organizations.organization(organizationId).delete();
    },
);

const createVerificationRequest = createAsyncThunk(
    "organizations/createVerificationRequest",
    async(args: { organizationId: string }) => {
        const createResponse = await serviceClient.organizations
            .organization(args.organizationId).verificationRequests
            .create();

        const verificationRequestId = createResponse.data.id;
        return serviceClient.organizations
            .organization(args.organizationId).verificationRequests
            .verificationRequest(verificationRequestId)
            .get();
    },
);

const submitVerificationRequest = createAsyncThunk(
    "organizations/submitVerificationRequest",
    async(args: { organizationId: string, requestId: string }) => {
        await serviceClient.organizations
            .organization(args.organizationId).verificationRequests
            .verificationRequest(args.requestId)
            .submit();

        return serviceClient.organizations
            .organization(args.organizationId).verificationRequests
            .verificationRequest(args.requestId)
            .get();
    },
);

const getCredentials = createAsyncThunk(
    "organizations/getCredentials",
    async(args: { organizationId: string }) => {
        return (await serviceClient.organizations.organization(args.organizationId).credentials.get()).data;
    },
);

const getOpenCredentialRequest = createAsyncThunk(
    "organizations/getOpenCredentialRequest",
    async(args: { organizationId: string }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId).credentialRequests
            .get();
        return response.data;
    },
);

const addOpenCredentialRequestItem = createAsyncThunk(
    "organizations/addOpenCredentialRequestItem",
    async(args: { organizationId: string, credentialId: string }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId).credentialRequest.items
            .post({ credentialId: args.credentialId });
        return response.data;
    },
);

const getOpenCredentialRequestItem = createAsyncThunk(
    "organizations/getOpenCredentialRequestItem",
    async(args: { organizationId: string, requestItemId: string }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId).credentialRequest
            .items.item(args.requestItemId)
            .get();
        return response.data;
    },
);

const uploadVerificationDocument = createAsyncThunk(
    "organizations/uploadVerificationDocument",
    async(args: { organizationId: string, requestId: string, file: File }) => {
        const response = await serviceClient.organizations
            .organization(args.organizationId).verificationRequests
            .verificationRequest(args.requestId).documents
            .submit({ name: args.file.name }, args.file)

        return response.data;
    },
);

const deleteVerificationDocument = createAsyncThunk(
    "organizations/deleteVerificationDocument",
    async(args: { organizationId: string, requestId: string, documentId: string }) => {
        return await serviceClient.organizations
            .organization(args.organizationId).verificationRequests
            .verificationRequest(args.requestId).documents
            .document(args.documentId)
            .delete();
    },
);

export const setSelectedOrganization = organizationsSlice.actions.setSelectedOrganization;

export const organizationActions = {
    add,
    get,
    getById,
    update,
    remove,
    setSelectedOrganization,
    getCredentials,
    createVerificationRequest,
    submitVerificationRequest,
    uploadVerificationDocument,
    deleteVerificationDocument,
    getOpenCredentialRequest,
    addOpenCredentialRequestItem,
    getOpenCredentialRequestItem,
};