import { Textarea, type SelectionEvents, type TextareaOnChangeData } from "@fluentui/react-components";
import type { Address } from "../../../client";
import { CountrySelect, Field, LocationAutocomplete, type SuggestedLocation } from "../../common";
import React, { useState } from "react";
import type { FormErrorValues } from "../../common/types";
import { Flexbox, TextField } from "../../ui";

interface OrganizationAddressFormProps {
    autoComplete?: boolean;
    errors?: FormErrorValues<Address>;
    readOnlyCountry?: boolean;
    values?: Partial<Address>;
    onChange?: (address: Partial<Address>) => void;
}

export const OrganizationAddressForm = (props: Readonly<OrganizationAddressFormProps>) => {
    const { autoComplete = true, errors, readOnlyCountry, values, onChange } = props;
    const addressValuesRef = React.useRef(values);
    const [selectedPlacesId, setSelectedPlacesId] = useState<string | undefined>();

    const handleLocationAutocompleteSelect = React.useCallback((_ev: SelectionEvents, data: SuggestedLocation) => {
        if (!data) {
            return;
        }

        if (data.address) {
            addressValuesRef.current = {
                ...addressValuesRef.current,
                ...data.address,
            };
            onChange?.({
                ...addressValuesRef.current,
            });
            setSelectedPlacesId(data.externalProviderIssuedId);
        }
    }, [onChange]);

    const handleCountrySelectChange = React.useCallback((_ev: React.ChangeEvent<HTMLSelectElement>, newValue?: string) => {
        addressValuesRef.current = {
            ...addressValuesRef.current,
            countryCode: newValue,
        };
        onChange?.({
            ...addressValuesRef.current,
        });
    }, [onChange]);

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = (event.currentTarget ?? event.target) as HTMLInputElement;
        if (target) {
            const inputName = target.name;
            const inputValue = target.value;

            addressValuesRef.current = {
                ...addressValuesRef.current,
                [inputName]: inputValue,
            };

            onChange?.({
                ...addressValuesRef.current,
            });
        }
    };

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
        const target = (event.currentTarget ?? event.target) as HTMLTextAreaElement;
        if (target && target.name) {
            const inputName = target.name;
            const inputValue = data.value;

            addressValuesRef.current = {
                ...addressValuesRef.current,
                [inputName]: inputValue,
            };

            onChange?.({
                ...addressValuesRef.current,
            });
        }
    };

    return (
        <Flexbox direction="column" rowGap={8}>
            {autoComplete ? (
                <Field
                    label="Street address"
                    name="streetAddress"
                    statusText={errors?.streetAddress}
                >
                    <LocationAutocomplete
                        onSelect={handleLocationAutocompleteSelect}
                    />
                </Field>
            ) : (
                <>
                    <Field
                        label="Country"
                        required
                        name="countryCode"
                        statusText={errors?.countryCode}
                    >
                        <CountrySelect
                            defaultValue={values?.countryCode}
                            disabled={readOnlyCountry}
                            onChange={handleCountrySelectChange}
                        />
                    </Field>
                    <Field
                        label="Street address"
                        required
                        name="streetAddress"
                        statusText={errors?.streetAddress}
                    >
                        <Textarea
                            autoComplete="street-address"
                            defaultValue={values?.streetAddress}
                            placeholder="123 Main St."
                            rows={4}
                            resize="vertical"
                            onChange={handleTextAreaChange}
                        />
                    </Field>
                </>
            )}
            <React.Fragment key={selectedPlacesId}>
                <Field
                    label="City / Town / Locality"
                    required
                    name="locality"
                    statusText={errors?.locality}
                >
                    <TextField
                        autoComplete="address-level2"
                        defaultValue={values?.locality}
                        placeholder="New York"
                        onChange={handleTextFieldChange}
                    />
                </Field>
                <div style={{ display: "flex", gap: 8, flexDirection: "row" }}>
                    <Field
                        label="State / Province / Region"
                        required
                        name="region"
                        statusText={errors?.region}
                    >
                        <TextField
                            autoComplete="address-level1"
                            defaultValue={values?.region}
                            placeholder="NY"
                            onChange={handleTextFieldChange}
                        />
                    </Field>
                    <Field
                        label="Postal code"
                        required
                        name="postalCode"
                        statusText={errors?.postalCode}
                    >
                        <TextField
                            autoComplete="postal-code"
                            defaultValue={values?.postalCode}
                            placeholder="98005"
                            onChange={handleTextFieldChange}
                        />
                    </Field>
                </div>
            </React.Fragment>
        </Flexbox>
    );
};

export default OrganizationAddressForm;