import type { ApiResponse } from "../../models";
import RequestBuilder from "../RequestBuilder";

export class DocumentContentRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<Blob>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async put(content: Blob, contentType?: string): Promise<ApiResponse> {
        const requestUrl = this.baseUrl;
        return await this.client.put<void>(requestUrl, content, { headers: { "Content-Type": contentType ?? content.type } });
    }
}