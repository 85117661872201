import type { Address } from "../../../client";
import React from "react";

interface FormattedAddressProps {
    address: Address;
    multiline?: boolean;
}

export const FormattedAddress: React.FC<FormattedAddressProps> = (props: Readonly<FormattedAddressProps>) => {
    const { address, multiline = true } = props;
    const formattedAddress = React.useMemo(() => {
        const separator = multiline ? "\n" : ", ";
        let formatted = "";
        if (address.streetAddress) {
            formatted += address.streetAddress;
        }

        if (address.locality) {
            formatted += separator + address.locality;
        }

        if (address.region) {
            formatted += ", " + address.region;
        }

        if (address.postalCode) {
            formatted += " " + address.postalCode;
        }

        if (address.countryCode) {
            formatted += separator + address.countryCode;
        }

        const lines = formatted.split('\n');
        return lines.map((line, index) => <span key={index}>{line}{index < lines.length - 1 ? <br /> : null}</span>);
    }, [multiline, address.streetAddress, address.locality, address.region, address.postalCode, address.countryCode]);

    return (<>{formattedAddress}</>);
}

export default FormattedAddress;