import { AccountSetupPage, AddOrganizationPage, CredentialOrganizationPage, OrganizationCertificatePage, OrganizationsPage, UserAccountSettingsPage } from "../pages";
import { BackOfficeRootPage, VerificationDetailsPage, VerificationsListPage } from "../pages/backoffice";
import { FullscreenLayout, MainLayout, ModalLayout } from "../layouts";
import { Navigate, Route, Routes } from "react-router-dom";

import DashboardPage from "../pages/DashboardPage";
import OrganizationDetailPage from "../pages/organization/OrganizationDetailPage";
import { VerifyOrganizationPage } from "../pages/organization/VerifyOrganizationPage";
import routePaths from "./routePaths";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<FullscreenLayout />}>
                <Route path={routePaths.setup.account} element={<AccountSetupPage />} />
            </Route>
            <Route element={<ModalLayout />}>
                <Route path={routePaths.organizations.add} element={<AddOrganizationPage />} />
                <Route path={routePaths.organizations.verify} element={<VerifyOrganizationPage />} />
                <Route path={routePaths.organizations.credential} element={<CredentialOrganizationPage />} />
            </Route>
            <Route element={<MainLayout />}>
                <Route path={routePaths.account.settings} element={<UserAccountSettingsPage />} />
                <Route path={routePaths.dashboard} element={<DashboardPage />} />
                <Route path={routePaths.organizations.list} element={<OrganizationsPage />} />
                <Route path={routePaths.organizations.viewCertificate} element={<OrganizationCertificatePage />} />
                <Route path={routePaths.organizations.view} element={<OrganizationDetailPage />} />
                <Route path={routePaths.organizations.root} index element={<Navigate to={routePaths.organizations.list} replace={true} />} />
                <Route path={routePaths.backOffice.root} element={<BackOfficeRootPage />} />
                <Route path={routePaths.backOffice.verifications.root} element={<VerificationsListPage />} />
                <Route path={routePaths.backOffice.verifications.details} element={<VerificationDetailsPage />} />
            </Route>
            <Route index path={routePaths.index} element={<Navigate to={routePaths.dashboard} replace={true} />} />
        </Routes>
    )
};

export default AppRoutes;