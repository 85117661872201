import { useCallback, useEffect, useState, type SetStateAction } from "react";
import type { Organization } from "../../../../client";
import type { OrganizationVerificationFormValues } from "../../types";
import { OrganizationVerificationDocUploadFlowStep } from "./OrganizationVerificationDocUploadFlowStep";
import OrganizationVerificationStartFlowStep from "./OrganizationVerificationStartFlowStep";
import { useAppDispatch } from "hooks";
import { organizationActions } from "store";
import { Navigate } from "react-router-dom";
import routePaths from "router/routePaths";
import { OrganizationVerificationReviewFlowStep } from "./OrganizationVerificationReviewFlowStep";
import OrganizationVerificationPaymentFlowStep from "./OrganizationVerificationPaymentFlowStep";
import OrganizationVerificationCompletedFlowStep from "./OrganizationVerificationCompletedFlowStep";
import { Flow, type FlowStepItem, type FlowStepSection } from "components/ui/flow";
import { HandWaveFilled, ShieldKeyholeFilled } from "@fluentui/react-icons";

const flowItems: ReadonlyArray<FlowStepItem> = [{
    title: "Introduction",
    icon: <HandWaveFilled />,
    steps: [],
    state: "completed",
} satisfies FlowStepSection, {
    title: "Verification",
    icon: <ShieldKeyholeFilled />,
    collapsed: true,
    steps: [{
        name: "Introduction",
        title: "Verify your organization",
        element: OrganizationVerificationStartFlowStep,
    }, {
        name: "Documentation",
        title: "Where is organization registered?",
        description: "We'll use this information to look up your organization's details in public records.",
        element: OrganizationVerificationDocUploadFlowStep,
    }, {
        name: "Review",
        title: "Review your organization's details",
        element: OrganizationVerificationReviewFlowStep
    }, {
        name: "Payment",
        title: "Pay the verification fee",
        element: OrganizationVerificationPaymentFlowStep,
    }, {
        name: "Completed",
        title: "Verification request submitted",
        element: OrganizationVerificationCompletedFlowStep
    }],
    state: "active",
} satisfies FlowStepSection];

interface OrganizationVerificationFlowProps {
    organization: Organization;
}

export const OrganizationVerificationFlow = (props: Readonly<OrganizationVerificationFlowProps>) => {
    const { organization } = props;
    const openVerificationRequest = organization.verificationRequests?.find(request => request.state?.toUpperCase() === "OPEN");
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    const handleValuesChange = useCallback((values: SetStateAction<OrganizationVerificationFormValues>) => {
        console.log(values);
    }, []);

    useEffect(() => {
        let action;
        if (organization.id) {
            action = dispatch(organizationActions.getById(organization.id));
            action.finally(() => {
                setLoading(false);
            });
        }
    }, [dispatch, organization?.id]);

    useEffect(() => {
        if (openVerificationRequest) {
            // setFilteredSteps(steps.length > 1 ? steps.slice(1) : steps);
        }
    }, [openVerificationRequest]);

    if (!organization.id) {
        return <Navigate to={routePaths.organizations.list} />;
    }

    return (
        <Flow<OrganizationVerificationFormValues>
            loading={loading}
            propsToPass={{
                organization: organization,
                verificationRequest: openVerificationRequest,
            }}
            items={flowItems}
            onValuesChange={handleValuesChange}
        />
    );
};

export default OrganizationVerificationFlow;