import type { PropsWithChildren } from "react";
import React from "react";
import classNames from "classnames";

const defaultGap = 12;
const defaultRowGap = 12;
const defaultColumnGap = 12;

function getValue<TValue>(value: true | TValue | undefined, defaultValue: TValue): TValue | undefined {
    if (value === undefined || value === null) {
        return undefined;
    } else if (value === true) {
        return defaultValue;
    } else {
        return value;
    }
}

interface FlexboxProps extends PropsWithChildren {
    className?: string;
    columnGap?: number | string | true;
    direction?: "row" | "row-reverse" | "column" | "column-reverse";
    fullHeight?: true;
    fullWidth?: true;
    gap?: number | string | true;
    grow?: number | true;
    horizontalAlign?: "start" | "center" | "end" | "space-between" | "space-around";
    rowGap?: number | string | true;
    shrink?: number | true;
    style?: Omit<React.CSSProperties, "display" | "flexDirection" | "gap" | "justifyContent" | "alignItems">;
    verticalAlign?: "start" | "center" | "end" | "space-between" | "space-around";
    wrap?: "wrap" | "nowrap" | "wrap-reverse";
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const Flexbox = React.forwardRef<HTMLDivElement, Readonly<FlexboxProps>>((props, forwardedRef) => {
    const {
        className,
        children,
        columnGap,
        direction = "row",
        fullHeight,
        fullWidth,
        gap,
        grow,
        horizontalAlign,
        rowGap,
        shrink,
        style,
        verticalAlign,
        wrap,
        onClick,
    } = props;

    let alignItems;
    let justifyContent;
    if (direction === "row" || direction === "row-reverse") {
        alignItems = verticalAlign;
        justifyContent = horizontalAlign;
    } else {
        alignItems = horizontalAlign;
        justifyContent = verticalAlign;
    }

    let innerGap = getValue(gap, defaultGap);
    let innerRowGap = getValue(rowGap, defaultRowGap);
    let innerColumnGap = getValue(columnGap, defaultColumnGap);
    if (gap && (rowGap || columnGap)) {
        innerGap = undefined;
        innerRowGap ??= (gap === true ? defaultGap : gap);
        innerColumnGap ??= (gap === true ? defaultGap : gap);
    }

    return (
        <div
            className={classNames("flex", direction, {
                "full-height": fullHeight,
                "full-width": fullWidth,
            }, className)}
            ref={forwardedRef}
            style={{
                ...style,
                alignItems,
                justifyContent,
                flexGrow: typeof grow === "number" ? grow : grow ? 1 : undefined,
                flexShrink: typeof shrink === "number" ? shrink : shrink ? 1 : undefined,
                gap: innerGap,
                columnGap: innerColumnGap,
                rowGap: innerRowGap,
                flexWrap: wrap,
            }}
            onClick={onClick}
        >
            {children}
        </div>
    );
});

export default Flexbox;