import type { CheckoutSession, CreateCheckoutSessionRequest } from "../../models";

import RequestBuilder from "../RequestBuilder";

export class ShoppingRequestBuilder extends RequestBuilder {
    public async createCheckoutSession(request: CreateCheckoutSessionRequest): Promise<CheckoutSession> {
        const requestUrl = this._appendUrlSegment("checkout", "create");
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });
        const session = await response.json();
        return session;
    }
}

export default ShoppingRequestBuilder;