import { useCallback, useEffect, useState } from "react";
import type { EntitySearchResultItem, SearchGlobalEntitiesRequest } from "../../../../client/models/EntitySearchResultItem";
import { useAppDispatch } from "../../../../hooks";
import { isPayloadAction, organizationActions } from "../../../../store";
import { Button, Caption1, Radio, RadioGroup, Text, type RadioGroupOnChangeData } from "@fluentui/react-components";
import { serviceClient } from "../../../../serviceClient";
import { FormattedAddress } from "../../../common";
import type { OrganizationSetupStepProps } from "./types";
import { FlowStep } from "../../../ui/flow/FlowStep";
import { type AddOrganizationRequest, type Organization, type PatchOrganizationRequest } from "../../../../client";
import { StringUtils } from "../../../../utils";
import { isRequestFailedError } from "../../../../client/RequestFailedError";
import { Box, Flexbox, type FlowNavigationDirection } from "../../../ui";

export const AddOrganizationEntityMatchFlowStep = (props: Readonly<OrganizationSetupStepProps>) => {
    const { values, onValuesChange, onNavigate } = props;
    const [searchInProgress, setSearchInProgress] = useState<boolean>(true);
    const [searchResults, setSearchResults] = useState<ReadonlyArray<EntitySearchResultItem>>([]);
    const [selectedEntityId, setSelectedEntityId] = useState<string>("");
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined | null>(undefined);
    const dispatch = useAppDispatch();

    const handleSubmit = useCallback(() => {
        const baseRequest: PatchOrganizationRequest = {
            registeredAddress: {
                countryCode: values?.countryCode,
                region: values?.region,
                postalCode: values?.postalCode,
                locality: values?.locality,
                streetAddress: values?.streetAddress,
            },
            identifiers: {
                openCorporates: selectedEntityId,
            },
        };

        let action: Promise<unknown>;
        if (values?.organizationId) {
            action = dispatch(organizationActions.update({ organizationId: values.organizationId, request: baseRequest }));
        } else {
            const createRequest: AddOrganizationRequest = {
                ...baseRequest,
                name: values?.organizationName ?? "",
            };
            action = dispatch(organizationActions.add(createRequest));
        }

        setSubmitInProgress(true);
        action
            .then((result) => {
                if (isPayloadAction(result) && isRequestFailedError(result.error)) {
                    if (result.error.code === "duplicate_organization_identifier") {
                        setError("The selected match is already associated with another account. Please contact support for assistance.");
                    } else {
                        setError(result.error.message);
                    }
                } else if (isPayloadAction(result)) {
                    onValuesChange?.((prev) => ({
                        ...prev,
                        organizationId: values?.organizationId ?? (result.payload as Organization)?.id,
                    }));
                    onNavigate?.("forward");
                }
            })
            .finally(() => {
                setSubmitInProgress(false);
            });
    }, [dispatch, onNavigate, onValuesChange, selectedEntityId, values?.countryCode, values?.locality, values?.organizationId, values?.organizationName, values?.postalCode, values?.region, values?.streetAddress]);

    const handleNavigation = useCallback((option: FlowNavigationDirection) => {
        if (option === "forward") {
            handleSubmit();
            return;
        }

        onNavigate?.(option);
    }, [handleSubmit, onNavigate]);

    const handleSkipClick = () => {
        const organizationId = values?.organizationId;
        if (organizationId) {
            onNavigate?.("forward");
            return;
        }

        setSubmitInProgress(true);
        dispatch(organizationActions.add({
            name: values?.organizationName ?? "",
            registeredAddress: {
                streetAddress: values?.streetAddress,
                locality: values?.locality,
                region: values?.region,
                postalCode: values?.postalCode,
                countryCode: values?.countryCode,
            },
        })).then((result) => {
            if (isPayloadAction<Organization>(result) && !result.error) {
                onValuesChange?.((prev) => ({ ...prev, organizationId: result.payload.id }));
                onNavigate?.("forward");
            }
        }).finally(() => {
            setSubmitInProgress(false);
        });
    }

    const handleRadioGroupChange = useCallback((_ev: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
        setSelectedEntityId(data.value);
    }, []);

    useEffect(() => {
        if (selectedEntityId) {
            onValuesChange?.(prev => ({ ...prev, entityId: selectedEntityId }));
        }
    }, [onValuesChange, selectedEntityId]);

    useEffect(() => {
        const search = (request: SearchGlobalEntitiesRequest) => {
            return serviceClient.search.searchEntities(request);
        };

        if (values?.organizationName) {
            const request: SearchGlobalEntitiesRequest = {
                searchText: values.organizationName,
                // filters: {
                //     countryCode: values?.addressCountryCode,
                // }
            };

            search(request)
                .then((response) => {
                    setSearchResults(response.data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setSearchInProgress(false);
                });
        } else {
            setSearchInProgress(false);
        }
    }, [values?.organizationName]);

    return (
        <FlowStep
            loading={searchInProgress}
            title="Select your organization"
            description="We found the following from public records. Choose the one that is current and matches your organization's details exactly. If your organization is not listed or the details are incorrect, select My organization is not listed."
            actionsProps={{
                actionInProgress: submitInProgress,
                disablePrimaryAction: !selectedEntityId,
            }}
            onNavigate={handleNavigation}
        >
            <Flexbox direction="column" rowGap={16}>
                <Text>{searchResults.length} results</Text>
                {!StringUtils.isNullOrWhitespace(error) && (
                    <span style={{ color: "red" }}>{error}</span>
                )}
                <RadioGroup
                    value={selectedEntityId}
                    onChange={handleRadioGroupChange}
                >
                    {searchResults.map((result) => (
                        <Box key={result.id} fullWidth style={{ marginBottom: 8 }}>
                            <Radio
                                label={
                                    <div style={{ display: "flex", flexDirection: "column", rowGap: 4, }}>
                                        <Text weight="bold">{result.name}</Text>
                                        {result.registeredAddress && (
                                            <Caption1><FormattedAddress address={result.registeredAddress} /></Caption1>
                                        )}
                                    </div>
                                }
                                value={result.id}
                            />
                        </Box>
                    ))}
                </RadioGroup>
                <Button appearance="transparent" onClick={handleSkipClick}>My organization isn't listed</Button>
            </Flexbox>
        </FlowStep>
    );
};