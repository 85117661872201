import type { AddOrganizationCredentialRequestItemRequest, CollectionResponse, OrganizationCredentialRequest } from "../../../models";

import type { ODataCollectionQueryOptions } from "../../../models/ODataCollectionQueryOptions";
import { OrganizationCredentialRequestRequestBuilder } from "./OrganizationCredentialRequestRequestBuilder";
import RequestBuilder from "../../RequestBuilder";

export class OrganizationCredentialRequestCollectionRequestBuilder extends RequestBuilder {
    public async get(nextLinkOrQueryOptions?: string | ODataCollectionQueryOptions) {
        const requestUrl = typeof(nextLinkOrQueryOptions) === "string" ? nextLinkOrQueryOptions : this.baseUrl;
        const response = await this.client.get<CollectionResponse<OrganizationCredentialRequest>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public async post(request: AddOrganizationCredentialRequestItemRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.post<OrganizationCredentialRequest>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }

    public credentialRequest(credentialRequestId: string) {
        return new OrganizationCredentialRequestRequestBuilder(this._appendUrlSegment(credentialRequestId), this.client);
    }
}