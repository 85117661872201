import { Body1Strong, Breadcrumb, BreadcrumbButton, BreadcrumbDivider, BreadcrumbItem, Button, Card, Title1, Title3 } from "@fluentui/react-components";
import { Box, Flexbox, Loader } from "../../components";
import React, { useCallback, useEffect, useState } from "react";
import { selectVerificationRequestById, verificationRequestActions } from "../../store/verifications";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { Document48Regular } from "@fluentui/react-icons";
import { OrganizationDetailsCard } from "../../components/organization/cards";
import { OrganizationOwnersCard } from "../../components/organization/cards/OrganizationOwnersCard";
import { Page } from "../../layouts";
import type { RootState } from "../../store";
import { StringUtils } from "../../utils";
import { VerificationDocumentReviewDrawer } from "../../components";
import classNames from "classnames";
import routePaths from "../../router/routePaths";
import { useParams } from "react-router-dom";

const selectVerification = (state: RootState, requestId?: string) => {
    if (!requestId) {
        return undefined;
    }

    return selectVerificationRequestById(state, requestId);
};

export const VerificationDetailsPage = () => {
    const { verificationId } = useParams<{ verificationId: string }>();
    const verification = useAppSelector(s => selectVerification(s, verificationId));
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(!verification);
    const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>();

    const handleReviewBtnClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        const sender = e.currentTarget ?? e.target;
        if (!sender) {
            console.warn("No sender found for the event.");
            return;
        }

        const documentId = sender.getAttribute("data-document-id");
        if (!documentId) {
            console.warn("No document id found in the sender.");
            return;
        }

        setSelectedDocumentId(documentId);
    }, []);

    const getDocumentStatusText = useCallback((status: string) => {
        switch (status) {
            case "pending":
                return "Needs review";
            default:
                return status;
        }
    }, []);

    useEffect(() => {
        if (verificationId) {
            const action = dispatch(verificationRequestActions.getById(verificationId));
            action.finally(() => {
                setLoading(false);
            });
        }
    }, [dispatch, verificationId]);

    return (
        <Page disableHorizontalGutters>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbButton href={routePaths.backOffice.verifications.root}>Verifications</BreadcrumbButton>
                    </BreadcrumbItem>
                    <BreadcrumbDivider />
                    <BreadcrumbItem>
                        <BreadcrumbButton href={window.location.href} current>{verification?.organization?.name ?? "..."}</BreadcrumbButton>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Loader loading={loading}>
                    <Flexbox
                        className={classNames({
                            "center": !Boolean(selectedDocumentId),
                        })}
                        direction="column"
                        style={{ maxWidth: Boolean(selectedDocumentId) ? "calc(100% - 989px)" : 684 }}
                        rowGap={16}
                    >
                        <Title1>{verification?.organization?.name}</Title1>
                        <Body1Strong>Org Code: {verification?.organization?.lookupKey}</Body1Strong>
                        <OrganizationDetailsCard
                            loading={loading}
                            organization={verification?.organization}
                        />
                        <OrganizationOwnersCard
                            loading={loading}
                            organization={verification?.organization}
                        />
                        <Box>
                            <div>
                                <Title3>Documents</Title3>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: 12,
                                    }}
                                >
                                    {verification?.documents?.map((doc) => (
                                        <Card key={doc.id}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    columnGap: 16,
                                                }}
                                            >
                                                <div>
                                                    <Document48Regular />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", rowGap: 8 }}>
                                                    <div>
                                                        <div>{doc.name}</div>
                                                        {(doc.uploadStatus !== "completed") ? (
                                                            <div>Waiting for upload</div>
                                                        ) : (
                                                            <div>
                                                                {doc.approvalResult?.value ? StringUtils.toTitleCase(doc.approvalResult.value) : getDocumentStatusText(doc.verificationStatus)}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {doc.uploadStatus === "completed" && (
                                                        <div>
                                                            <Button
                                                                appearance={doc.approvalResult ? "secondary" : "primary"}
                                                                data-document-id={doc.id}
                                                                onClick={handleReviewBtnClick}
                                                            >
                                                                {doc.approvalResult ? "View" : "Review"}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        </Box>
                    </Flexbox>
                </Loader>
                {(selectedDocumentId && verification?.id) && (
                    <VerificationDocumentReviewDrawer
                        documentId={selectedDocumentId}
                        verificationId={verification.id}
                        open={Boolean(selectedDocumentId)}
                        onClose={() => setSelectedDocumentId(undefined)}
                    />
                )}
            </div>
        </Page>
    );
};

export default VerificationDetailsPage;