import { TimeSpan } from "./TimeSpan";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export type DateUnit = "millisecond" | "second" | "minute" | "hour" | "day" | "week" | "month" | "year";
const orderedDateUnits: DateUnit[] = ["millisecond", "second", "minute", "hour", "day", "month", "year"];
type DateType = Date | number | string;
type NullableDateType = DateType | null | undefined;

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export type RelativeDateOptions = {
	unit?: DateUnit;
	min?: number;
	max?: number;
	format?: string;
};

export class DateUtils {
	public static addDays(dateObjectOrValue: DateType, days: number): Date {
		let dateObject: number;
		if (typeof dateObjectOrValue === "string") {
			dateObject = Date.parse(dateObjectOrValue);
		} else if (typeof dateObjectOrValue === "object") {
			dateObject = dateObjectOrValue.getTime();
		} else {
			dateObject = dateObjectOrValue;
		}

		return new Date(dateObject + (days * DAY));
	}

	public static format(input: NullableDateType, format: string, relative = false): string | null | undefined {
		if (input === null || input === undefined) {
			return input;
		}

		if (relative) {
			if (DateUtils.isToday(input)) {
				return "today";
			} else if (DateUtils.isYesterday(input)) {
				return "yesterday";
			} else if (DateUtils.isTomorrow(input)) {
				return "tomorrow";
			}
		}

		dayjs.extend(advancedFormat);
		dayjs.extend(utc);
		dayjs.extend(timezone);
		const inputDate: dayjs.Dayjs = dayjs(input);
		return  inputDate.format(format).replace("PM", "pm").replace("AM", "am");
	}

	public static formatV2(input: NullableDateType, format: string, timeZone?: string, keepLocal?: boolean): string | null | undefined {
		if (input === null || input === undefined) {
			return input;
		}

		dayjs.extend(advancedFormat);
		dayjs.extend(utc);
		dayjs.extend(timezone);
		const inputDate: dayjs.Dayjs = dayjs(input);
		let dateToFormat;
		if (timeZone) {
			dateToFormat = inputDate.tz(timeZone, keepLocal);
		} else {
			dateToFormat = inputDate;
		}

		return  dateToFormat.format(format).replace("PM", "pm").replace("AM", "am");
	}

	/**
	 * Compare the two dates and return
	 * -1 if date1 is earlier than date2,
	 * 1 if date1 is later than date2 or
	 * 0 if date1 is equal to date2.
	 *
	 * @static
	 * @param {DateType} date1
	 * @param {DateType} date2
	 * @return {*}  {number}
	 * @memberof DateUtils
	 */
	public static compare(date1: DateType, date2: DateType): number {
		const date1Inner = dayjs(date1);
		const date2Inner = dayjs(date2);

		const diff = date1Inner.diff(date2Inner);
		if (diff > 0) {
			return 1;
		} else if (diff < 0) {
			return -1;
		} else {
			return 0;
		}
	}

	public static difference(date1: DateType, date2: DateType): TimeSpan {
		const start = dayjs(date1);
		const diff = start.diff(date2, "milliseconds", true);
		return new TimeSpan(Math.abs(diff));
	}

	/**
	 * Returns the human readable
	 * difference between date1 and date2.
	 *
	 * @static
	 * @param {DateType} date1
	 * @param {DateType} date2
	 * @return {*}  {string}
	 * @memberof DateUtils
	 */
	public static relativeDifference(date1: DateType, date2: DateType, unitOrOptions?: DateUnit | RelativeDateOptions): string | null {
		const startDate = dayjs(date1);

		let format = "MM/DD/YYYY h:mm a";
		let max = Number.MAX_SAFE_INTEGER;
		let float = false;
		let unit: DateUnit | undefined = undefined;
		if (typeof unitOrOptions === "object" && unitOrOptions !== null) {
			unit = unitOrOptions.unit;
			max = unitOrOptions.max ?? -1;
			float = Boolean(unitOrOptions.max) || Boolean(unitOrOptions.min);
			format = unitOrOptions.format ?? format;
		} else {
			unit = unitOrOptions;
		}

		const diff = Math.floor(startDate.diff(date2, unit, float));

		if (diff === 0) {
			const currentDateUnitIndex = orderedDateUnits.indexOf(unit ?? "hour");
			const smallerUnit = orderedDateUnits[currentDateUnitIndex - 1];
			const smallerUnitDiff = Math.floor(startDate.diff(date2, smallerUnit, float));
			return `${smallerUnitDiff} ${smallerUnit}${smallerUnitDiff === 1 ? "" : "s"} ago`;
		} else if (diff > max) {
			return DateUtils.format(date2, format) ?? null;
		} else if (diff === 1) {
			return unit === "day" ? "yesterday" : `${diff} ${unit} ago`;
		} else {
			return `${diff} ${unit}${diff === 1 ? "" : "s"} ago`;
		}
	}

	/**
	 * Determines if date1 is earlier than date2.
	 *
	 * @export
	 * @param {(Date | number | string)} date1
	 * @param {(Date | number | string)} date2
	 * @return {*}  {boolean}
	 */
	public static isEarlier(date1: DateType, date2: DateType): boolean {
		return DateUtils.compare(date1, date2) < 0;
	}

	/**
	 * Determines if date1 is later than date2.
	 *
	 * @export
	 * @param {(Date | number | string)} date1
	 * @param {(Date | number | string)} date2
	 * @return {*}  {boolean}
	 */
	public static isLater(date1: DateType, date2: DateType): boolean {
		return DateUtils.compare(date1, date2) > 0;
	}

	public static isValid(value: DateType): boolean {
		let dateValue: Date;
		if (typeof value === "string" || typeof value === "number") {
			dateValue = new Date(value);
		} else {
			dateValue = value;
		}

		return dateValue && !isNaN(dateValue.valueOf());
	}

	public static isToday(value: DateType): boolean {
		dayjs.extend(isToday);
		const d = dayjs(value);
		return d.isToday();
	}

	public static isYesterday(value: DateType): boolean {
		dayjs.extend(isYesterday);
		const d = dayjs(value);
		return d.isYesterday();
	}

	public static isTomorrow(value: DateType): boolean {
		dayjs.extend(isTomorrow);
		const d = dayjs(value);
		return d.isTomorrow();
	}

	public static getElapsedTime(startDate: Date | number, endDate: Date | number): number {
		const d1 = new Date(startDate);
		const d2 = new Date(endDate);

		return d2.valueOf() - d1.valueOf();
	}

	public static parse(input: string): Date {
		return dayjs(input).toDate();
	}
}