import { Card, Checkbox, type CheckboxOnChangeData } from "@fluentui/react-components";
import type { Credential } from "../../../client";
import { useAppSelector } from "../../../hooks";
import { selectCredentialById } from "../../../store";
import { useState } from "react";

interface CredentialCardProps {
    credentialId: string;
    disabled?: boolean;
    selected?: boolean;
    onSelectChange?: (credential: Credential, selected: boolean) => void;
}

export const CredentialCard = (props: Readonly<CredentialCardProps>) => {
    const { credentialId, disabled, selected, onSelectChange } = props;
    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(selected ?? false);
    const credential = useAppSelector(s => selectCredentialById(s, credentialId));

    const handleCheckboxChange = (_ev: React.ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        setCheckboxChecked(data.checked === true);
        onSelectChange?.(credential, data.checked === true);
    };

    return (
        <Card
            key={credential.id}
            className="hides-descendants"
            floatingAction={
                <Checkbox
                    checked={checkboxChecked}
                    disabled={disabled}
                    onChange={handleCheckboxChange}
                />
            }
        >
            <div
                style={{
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    justifyContent: "space-between",
                    rowGap: 4,
                    minHeight: 148,
                    width: 148,
                }}
            >
                <div
                    style={{
                        alignItems: "flex-start",
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        rowGap: 4,
                        width: "100%",
                    }}
                >
                    <b>{credential.name}</b>
                    <div
                        style={{
                            flexGrow: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <span>{credential.description}</span>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default CredentialCard;