import { Tag as FuiTag } from "@fluentui/react-components";
import type React from "react";

interface TagProps extends React.PropsWithChildren {
    shape?: "pill" | "square";
    secondaryText?: string;
}

export const Tag = (props: Readonly<TagProps>) => {
    const { children, secondaryText, shape = "square" } = props;
    return (
        <FuiTag
            shape={shape === "pill" ? "circular" : undefined}
            secondaryText={secondaryText}
        >
            {children}
        </FuiTag>
    );
};