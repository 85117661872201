import type { ErrorResponse } from "./models/ErrorResponse";

export class RequestFailedError extends Error {
    private _errorResponse: ErrorResponse;

    constructor(errorResponse: ErrorResponse) {
        super(errorResponse.error.message, {
            cause: "RequestFailed",
        });

        this._errorResponse = errorResponse;
    }

    public get code(): string {
        return this._errorResponse.error.code;
    }

    public get message(): string {
        return this._errorResponse.error.message;
    }

    public get statusCode(): number {
        return this._errorResponse.statusCode ?? -1;
    }

    public get status(): number {
        return this._errorResponse.statusCode ?? -1;
    }
}

export function isRequestFailedError(error: unknown): error is RequestFailedError {
    return (error instanceof RequestFailedError || (typeof error === "object" && error && "code" in error && "message" in error)) ?? false;
}

export default RequestFailedError;