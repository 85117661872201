import { Body1, Button, Title3 } from "@fluentui/react-components";
import { Box, Flexbox } from "./ui";

export const ReadMoreAboutCredentialing = () => {
    return (
        <Box>
            <Flexbox
                direction="column"
                horizontalAlign="start"
                rowGap
            >
                <Title3>Everything you need for credentialing here</Title3>
                <Body1 wrap>To ensure a quick and seamless process, review all documents you will need to complete your application.</Body1>
                <div>
                    <Button appearance="transparent">Learn more</Button>
                </div>
            </Flexbox>
        </Box>
    )
};

export default ReadMoreAboutCredentialing;