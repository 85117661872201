import { ClipboardCheckmarkRegular, CopyRegular } from '@fluentui/react-icons';
import React, { ReactNode, useMemo, useState } from 'react';

import { Tooltip } from '@fluentui/react-components';
import classNames from 'classnames';
import { useToaster } from '../../../../hooks';

interface CopyButtonProps {
    className?: string;
    style?: React.CSSProperties;
    success?: boolean;
    title?: string;
    onClick?: (e: React.MouseEvent<Element>) => void;
}

export const CopyButton = (props: Readonly<CopyButtonProps>) => {
    const { className, style, success, title, onClick } = props;
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseOver = () => {
        setShowTooltip(true);
    };

    const handleMouseOut = () => {
        setShowTooltip(false);
    };

    return (
        <Tooltip
            content={success ? "Copied" : title ?? ""}
            relationship="description"
            visible={success || showTooltip}
        >
            <div
                className={className}
                style={{
                    ...style,
                    position: "relative",
                    marginLeft: 2,
                    marginRight: 2,
                    minHeight: 14,
                    maxHeight: 24,
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <div
                    style={{
                        transition: "opacity 0.3s ease",
                        opacity: success ? 1 : 0,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                    }}
                >
                    <ClipboardCheckmarkRegular
                        style={{
                            height: "100%",
                            aspectRatio: "1",
                            width: "auto",
                        }}
                        color="green"
                    />
                </div>
                <div
                    style={{
                        transition: "opacity 0.3s ease",
                        opacity: success ? 0 : 1,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                    }}
                >
                    <CopyRegular
                        style={{
                            cursor: "pointer",
                            height: "100%",
                            aspectRatio: "1",
                            width: "auto",
                        }}
                        onClick={onClick}
                    />
                </div>
            </div>
        </Tooltip>
    );
}

interface CopyableProps {
    children: ReactNode;
}

export const Copyable: React.FC<CopyableProps> = ({ children }) => {
    const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null);
    const [success, setSuccess] = React.useState<boolean | undefined>(undefined);
    const toaster = useToaster();

    const copyableContent = useMemo(() => {
        return rootElement?.innerText ?? "";
    }, [rootElement]);

    const handleCopyClick = async (e: React.MouseEvent<Element>) => {
        e.stopPropagation();
        try {
            await navigator.clipboard.writeText(copyableContent);
            setSuccess(true);
            setTimeout(() => setSuccess(undefined), 1200);
        } catch (error) {
            toaster.push("Failed to copy to clipboard", { type: "error" });
        }
    };

    return (
        <div className="hides-descendants" style={{ position: 'relative', display: "flex", alignItems: "stretch" }}>
            <div
                ref={setRootElement}
                style={{ display: 'inline-block' }}
            >
                {children}
            </div>
            <CopyButton
                className={classNames({
                    "hidden-descendant": success === undefined,
                })}
                success={success}
                title={success ? "Copied" : `Copy '${copyableContent}'`}
                onClick={handleCopyClick}
            />
        </div>
    );
};

export default Copyable;