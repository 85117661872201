import type { CollectionResponse, CreateDocumentRequest, VerificationDocument } from "../../models";

import { RequestBuilder } from "../RequestBuilder";
import { VerificationDocumentRequestBuilder } from "./VerificationDocumentRequestBuilder";

export class VerificationDocumentCollectionRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<CollectionResponse<VerificationDocument>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public document(verificationDocumentId: string) {
        return new VerificationDocumentRequestBuilder(this._appendUrlSegment(verificationDocumentId), this.client);
    }

    public async post(request: CreateDocumentRequest) {
        const requestUrl = this.baseUrl;
        const response = await this.client.post<VerificationDocument>(requestUrl, request);
        return this.getResponseObjectOrThrow(response);
    }

    public async submit(request: CreateDocumentRequest, file: Blob) {
        const requestUrl = this.baseUrl;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("body", JSON.stringify(request));
        const response = await this.client.post<VerificationDocument>(requestUrl, formData);
        return this.getResponseObjectOrThrow(response);
    }
}

export default VerificationDocumentCollectionRequestBuilder;