import { Add28Regular, Delete28Regular } from "@fluentui/react-icons";
import { Box, Flexbox, type FlowNavigationDirection } from "../../../ui";
import type { OrganizationOwnerFormValues, OrganizationSetupFormValues } from "../../types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { AddOrganizationOwnerRequest } from "../../../../client";
import { Button } from "@fluentui/react-components";
import { FlowStep } from "../../../ui/flow/FlowStep";
import { OrganizationOwnerForm } from "../../forms/OrganizationOwnerForm";
import { StringUtils } from "../../../../utils";
import { organizationActions } from "../../../../store";
import { serviceClient } from "../../../../serviceClient";
import { useAppDispatch } from "../../../../hooks";
import type { OrganizationSetupStepProps } from "./types";

type OwnerErrors = Partial<Record<string, Partial<Record<keyof OrganizationOwnerFormValues, string>>>>;

export const AddOrganizationOwnersFlowStep = (props: Readonly<OrganizationSetupStepProps>) => {
    const dispatch = useAppDispatch();
    const { values, onNavigate, onValuesChange, ...stepProps } = props;
    const emptyObj = useRef<Record<string, OrganizationOwnerFormValues>>({});
    const owners = values?.owners ?? emptyObj.current;
    const [error, setError] = useState<string | undefined>();
    const [actionInProgress, setActionInProgress] = useState<boolean>(false);
    const [ownerErrors, setOwnerErrors] = useState<OwnerErrors>({});

    const ownershipSum = useMemo(() => Object.values(owners).reduce((acc, owner) => {
        return acc + (owner.ownership ?? 0);
    }, 0), [owners]);

    const validate = useCallback((owners: Record<string, OrganizationOwnerFormValues>) => {
        const ownersErrorCollection: OwnerErrors = {};
        Object.keys(owners).forEach((key) => {
            const owner = owners[key];
            const ownerErrors: Partial<Record<keyof OrganizationOwnerFormValues, string>> = {};
            if (StringUtils.isNullOrWhitespace(owner.givenName)) {
                ownerErrors.givenName = "First name is required";
            }

            if (StringUtils.isNullOrWhitespace(owner.surname)) {
                ownerErrors.surname = "Last name is required";
            }

            if (StringUtils.isNullOrWhitespace(owner.emailAddress)) {
                ownerErrors.emailAddress = "Email address is required";
            }

            if (!owner.ownership) {
                ownerErrors.ownership = "Ownership percentage is required";
            }

            ownersErrorCollection[key] = ownerErrors;
        });

        return ownersErrorCollection;
    }, []);

    const submit = useCallback((values?: Partial<OrganizationSetupFormValues>) => {
        setOwnerErrors({});
        return new Promise<void>((resolve, reject) => {
            const organizationId = values?.organizationId;
            if (StringUtils.isNullOrWhitespace(organizationId)) {
                reject("Organization ID is required");
                return;
            }

            const owners = Object.values(values?.owners ?? {});
            if (owners.length === 0) {
                reject("At least one owner is required");
                return;
            }

            if (ownershipSum !== 1) {
                reject("Ownership must sum to 100%");
                return;
            }

            const ownerErrors = validate(values?.owners ?? {});
            if (Object.values(ownerErrors).some((errors) => Object.keys(errors ?? {}).length > 0)) {
                setOwnerErrors(ownerErrors);
                reject("One or more owners have errors");
                return;
            }

            const request: Array<AddOrganizationOwnerRequest> = owners.map((owner) => ({
                givenName: owner.givenName ?? "",
                surname: owner.surname ?? "",
                emailAddress: owner.emailAddress ?? "",
                ownership: owner.ownership ?? 0,
            }));

            setActionInProgress(true);

            serviceClient.organizations
                .organization(organizationId).owners
                .put(request).then(() => {
                    dispatch(organizationActions.getById(organizationId))
                        .finally(() => {
                            setActionInProgress(false);
                            resolve();
                        });
                }).catch(() => {
                    setActionInProgress(false);
                });
        });
    }, [dispatch, ownershipSum, validate]);

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        setError(undefined);
        if (direction !== "forward") {
            onNavigate?.(direction);
            return;
        }

        submit(values).then(() => {
            onNavigate?.(direction);
        }, (reason) => {
            setError(reason);
        }).catch((err) => {
            setError(err);
        });

    }, [onNavigate, submit, values]);

    const handleAddOwnerBtnClick = ({ ownership }: { ownership?: number }) => {
        setError(undefined);
        onValuesChange?.((prev) => {
            const owners = prev.owners ?? {};
            const newOwnerKey = new Date(Date.now()).toISOString();
            return {
                ...prev,
                owners: {
                    [newOwnerKey]: {
                        ownership,
                    },
                    ...owners,
                },
            };
        });
    };

    const handleRemoveOwnerBtnClick = (ownerKey: string) => {
        onValuesChange?.((prev) => {
            const owners = prev.owners ?? {};
            const { [ownerKey]: _, ...restOwners } = owners;
            return {
                ...prev,
                owners: restOwners,
            };
        });
    };

    const handleOrganizationOwnerFormChange = (ownerKey: string, values: OrganizationOwnerFormValues) => {
        onValuesChange?.((prev) => {
            const owners = prev.owners ?? {};
            const nextValues = {
                ...prev,
                owners: {
                    ...owners,
                    [ownerKey]: values,
                },
            };

            return nextValues;
        });
    };

    useEffect(() => {
        if (StringUtils.isNullOrWhitespace(values?.organizationId)) {
            onNavigate?.("backward");
        }
    }, [values?.organizationId, onNavigate]);

    return (
        <FlowStep
            {...stepProps}
            actionsProps={{
                ...stepProps.actionsProps,
                actionInProgress,
                disablePrimaryAction: ownershipSum !== 1,
            }}
            title="Tell us about the organization's ownership"
            description="Add each of the organization's owners until the ownership total has reached 100%."
            onNavigate={handleStepNavigation}
        >
            <Flexbox direction="column" rowGap>
                {error && (<span style={{ color: "red" }}>{error}</span>)}
                <span>The current sum of all owners' percentage is <b style={{ color: (ownershipSum !== 1) ? "red" : undefined }}>{(ownershipSum * 100).toPrecision(4)}%</b>. To proceed, the to sum must equal 100%.</span>
                <Button
                    disabled={actionInProgress}
                    icon={<Add28Regular />}
                    onClick={() => handleAddOwnerBtnClick({ ownership: 1 - ownershipSum })}
                >
                    Add another owner
                </Button>
                {Object.keys(owners).map((ownerKey) => (
                    <Box className="hides-descendants" key={ownerKey}>
                        <Flexbox direction="column" rowGap>
                            <OrganizationOwnerForm
                                defaultValues={owners[ownerKey]}
                                errors={ownerErrors[ownerKey]}
                                onChange={(values) => handleOrganizationOwnerFormChange(ownerKey, values)}
                            />
                            <Button
                                className="hidden-descendant"
                                disabled={actionInProgress}
                                icon={<Delete28Regular />}
                                onClick={() => handleRemoveOwnerBtnClick(ownerKey)}
                            >
                                Remove
                            </Button>
                        </Flexbox>
                    </Box>
                ))}
            </Flexbox>
        </FlowStep>
    );
};

export default AddOrganizationOwnersFlowStep;