import type { Organization } from "client";
import { Flow, type FlowStepItem } from "components/ui";
import { RequestCredentialBrowseFlowStep } from "./RequestCredentialBrowseFlowStep";
import { RequestCredentialIntroFlowStep } from "./RequestCredentialIntroFlowStep";
import { RequestCredentialEvidenceFlowStep } from "./RequestCredentialEvidenceFlowStep";
import { RequestCredentialNotarizationFlowStep } from "./RequestCredentialNotarizationFlowStep";
import { CheckmarkStarburstFilled, DocumentRibbonFilled, HandWaveFilled, ShieldKeyholeFilled } from "@fluentui/react-icons";

const flowItems: ReadonlyArray<FlowStepItem> = [{
    title: "Introduction",
    icon: <HandWaveFilled />,
    steps: [],
    state: "completed",
}, {
    title: "Verification",
        icon: <ShieldKeyholeFilled />,
    collapsed: true,
    steps: [],
    state: "completed",
}, {
    title: "Credentialing",
    icon: <CheckmarkStarburstFilled />,
    collapsed: true,
    steps: [{
        name: "intro",
        element: RequestCredentialIntroFlowStep,
    }, {
        name: "browse",
        element: RequestCredentialBrowseFlowStep,
    }, {
        name: "evidence",
        element: RequestCredentialEvidenceFlowStep,
    }],
    state: "active",
}, {
    title: "Notarization",
    icon: <DocumentRibbonFilled />,
    collapsed: true,
    steps: [{
        name: "notarization",
        element: RequestCredentialNotarizationFlowStep,
    }],
}];

interface RequestCredentialFlowProps {
    organization: Organization;
}

export const RequestCredentialFlow = (props: Readonly<RequestCredentialFlowProps>) => {
    const { organization } = props;

    if (!organization) {
        return null;
    }

    return (
        <Flow
            items={flowItems}
            propsToPass={{
                organization,
            }}
        />
    );
};

export default RequestCredentialFlow;