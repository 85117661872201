import { Box, Flexbox } from "../../ui";

import { DetailText } from "../../common/data/DetailText";
import { FormattedAddress } from "../../common/data/FormattedAddress";
import type { Organization } from "../../../client";
import { Title3 } from "@fluentui/react-components";

interface OrganizationDetailsCardProps {
    loading?: boolean;
    hideName?: boolean;
    organization?: Partial<Organization>;
}

export const OrganizationDetailsCard = (props: Readonly<OrganizationDetailsCardProps>) => {
    const { hideName, organization, loading } = props;

    return (
        <Flexbox direction="column" rowGap={12}>
            <Title3>Organization information</Title3>
            <Box style={{ minWidth: 284, }}>
                <Flexbox direction="column" rowGap={8}>
                    {hideName !== true && (
                        <DetailText label="Legal name" loading={loading} value={organization?.name ?? ""} />
                    )}
                    <DetailText
                        copyable={true}
                        label="Registered address"
                        loading={loading}
                        value={organization?.registeredAddress ? <FormattedAddress address={organization.registeredAddress} multiline={false} /> : " - "}
                    />
                </Flexbox>
            </Box>
        </Flexbox>
    )
};