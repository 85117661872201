import type { OrganizationsStoreState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { organizationActions } from "./actions";
import { organizationsAdapter } from "./adapter";

const initialState = organizationsAdapter.getInitialState<OrganizationsStoreState>({
    selectedId: null,
    list: {
        ids: [],
    },
});

export const organizationsSlice = createSlice({
    name: "organizations",
    initialState,
    reducers: {
        setSelectedOrganization: (state, action) => {
            state.selectedId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(organizationActions.add.fulfilled, (state, action) => {
            organizationsAdapter.addOne(state, action.payload);
        });

        builder.addCase(organizationActions.get.fulfilled, (state, action) => {
            const incomingOrganizations = action.payload.value.map((org) => {
                const existing = state.entities[org.id];
                if (existing?.verificationRequests) {
                    return {
                        ...org,
                        verificationRequests: existing.verificationRequests,
                    };
                }
                return org;
            });
            organizationsAdapter.upsertMany(state, incomingOrganizations);
            state.list.ids = incomingOrganizations.map((org) => org.id);
            state.list.nextLink = action.payload["odata.nextLink"];
        });

        builder.addCase(organizationActions.getById.fulfilled, (state, action) => {
            const organization = action.payload;
            organizationsAdapter.upsertOne(state, organization);
        });

        builder.addCase(organizationActions.update.fulfilled, (state, action) => {
            const { organizationId, request } = action.meta.arg;
            const { id, ...changes } = request;
            organizationsAdapter.updateOne(state, {
                id: organizationId,
                changes,
            });
        });

        builder.addCase(organizationActions.remove.fulfilled, (state, action) => {
            organizationsAdapter.removeOne(state, action.meta.arg);
        });

        builder.addCase(organizationActions.createVerificationRequest.fulfilled, (state, action) => {
            const { organizationId } = action.meta.arg;
            const organization = state.entities[organizationId];
            organizationsAdapter.updateOne(state, {
                id: organizationId,
                changes: {
                    verificationRequests: [
                        ...organization.verificationRequests ?? [],
                        {
                            ...action.payload.data,
                            id: action.payload.data.id,
                        },
                    ],
                },
            });
        });

        builder.addCase(organizationActions.submitVerificationRequest.fulfilled, (state, action) => {
            const { organizationId } = action.meta.arg;
            const organization = state.entities[organizationId];
            organizationsAdapter.updateOne(state, {
                id: organizationId,
                changes: {
                    verificationRequests: [
                        ...organization.verificationRequests ?? [],
                        {
                            submittedAt: new Date().toISOString(),
                            ...action.payload.data,
                            id: action.payload.data.id,
                        },
                    ],
                },
            });
        });

        builder.addCase(organizationActions.getCredentials.fulfilled, (state, action) => {
            const { organizationId } = action.meta.arg;
            organizationsAdapter.upsertOne(state, {
                id: organizationId,
                credentials: [...action.payload.value],
            });
        });

        builder.addCase(organizationActions.uploadVerificationDocument.fulfilled, (state, action) => {
            const { organizationId, requestId } = action.meta.arg;
            const organization = state.entities[organizationId];
            const verificationRequest = organization?.verificationRequests?.find((req) => req.id === requestId);
            organizationsAdapter.updateOne(state, {
                id: organizationId,
                changes: {
                    verificationRequests: [
                        ...organization.verificationRequests ?? [],
                        {
                            ...verificationRequest,
                            id: requestId,
                            documents: [
                                ...verificationRequest?.documents ?? [],
                                action.payload,
                            ],
                        }
                    ],
                },
            });
        });

        builder.addCase(organizationActions.deleteVerificationDocument.fulfilled, (state, action) => {
            const { organizationId, requestId, documentId } = action.meta.arg;
            const organization = state.entities[organizationId];
            const verificationRequest = organization?.verificationRequests?.find((req) => req.id === requestId);

            organizationsAdapter.updateOne(state, {
                id: organizationId,
                changes: {
                    verificationRequests: [
                        ...organization.verificationRequests ?? [],
                        {
                            ...verificationRequest,
                            id: requestId,
                            documents: verificationRequest?.documents?.filter((doc) => doc.id !== documentId),
                        },
                    ],
                },
            });
        });

        builder.addCase(organizationActions.getOpenCredentialRequest.fulfilled, (state, action) => {
            const { organizationId } = action.meta.arg;
            organizationsAdapter.upsertOne(state, {
                id: organizationId,
                credentialRequests: [...action.payload.value],
            });
        });

        builder.addCase(organizationActions.addOpenCredentialRequestItem.fulfilled, (state, action) => {
            const { organizationId } = action.meta.arg;
            const organization = state.entities[organizationId];

            if (organization?.credentialRequests) {
                organizationsAdapter.updateOne(state, {
                    id: organizationId,
                    changes: {
                        credentialRequests: state.entities[organizationId]?.credentialRequests?.map((request) => {
                            if (request.id === action.payload.id) {
                                return {
                                    ...request,
                                    requestItems: [
                                        ...request.requestItems.filter((item) => item.id !== action.payload.id),
                                        action.payload,
                                    ],
                                };
                            } else {
                                return request;
                            }
                        }),
                    },
                });
            }
        });

        builder.addCase(organizationActions.getOpenCredentialRequestItem.fulfilled, (state, action) => {
            const { organizationId } = action.meta.arg;
            organizationsAdapter.updateOne(state, {
                id: organizationId,
                changes: {
                    credentialRequests: state.entities[organizationId]?.credentialRequests?.map((request) => {
                        if (request.id === action.payload.id) {
                            return {
                                ...request,
                                requestItems: [
                                    ...request.requestItems.filter((item) => item.id !== action.payload.id),
                                    action.payload,
                                ],
                            };
                        } else {
                            return request;
                        }
                    }),
                },
            });
        });
    },
});
