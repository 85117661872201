import type ErrorResponse from "./ErrorResponse";

export interface ApiResponse {
	error?: ErrorResponse;
	headers: Record<string, string>;
	statusCode: number;
}

export interface ApiObjectResponse<T> extends ApiResponse {
	data: T;
}

export const isApiObjectResponse = <T>(response: ApiResponse): response is ApiObjectResponse<T> => {
	return (response as ApiObjectResponse<T>).data !== undefined;
};