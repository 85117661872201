import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { credentialActions, selectAllCredentials } from "../../../store/credentials";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useCallback, useEffect, useState } from "react";

import { ButtonWithLoader } from "../../common";
import { type Credential } from "../../../client";
import CredentialCard from "../cards/CredentialCard";
import { Dismiss20Filled } from "@fluentui/react-icons";
import { organizationActions, selectOrganizationById } from "../../../store";
import { Loader } from "../../ui";

interface AddCredentialDialogProps {
    open: boolean;
    organizationId: string;
    onDismiss?: (reason: "cancel" | "submit") => void;
}

const InnerDialog = (props: Readonly<AddCredentialDialogProps>) => {
    const { open, organizationId, onDismiss } = props;
    const organization = useAppSelector(s => selectOrganizationById(s, organizationId));
    const credentials = useAppSelector(selectAllCredentials);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(credentials.length === 0);
    const [selectedCredentials, setSelectedCredentials] = useState<string[]>([]);
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
    const [requestError, setRequestError] = useState<string | null>(null);

    const handleCredentialSelectChange = useCallback((credential: Credential, selected: boolean) => {
        setSelectedCredentials((prevSelectedCredentials) => {
            return selected ? [...prevSelectedCredentials, credential.id] : prevSelectedCredentials.filter(id => id !== credential.id);
        });
    }, []);

    const handleRequestBtnClick = useCallback(() => {
        if (selectedCredentials.length === 0) {
            return;
        }

        setRequestInProgress(true);
        const request = {
            organizationId,
            credentialId: selectedCredentials[0],
        };
        const action = dispatch(organizationActions.addOpenCredentialRequestItem(request));
        action
            .then((something) => {
                if ("error" in something && something.error !== null && "message" in something.error) {
                    setRequestError(something.error.message ?? null);
                } else {
                    setRequestError(null);
                    onDismiss?.("submit");
                }
            })
            .finally(() => {
                setRequestInProgress(false);
            });

    }, [dispatch, onDismiss, organizationId, selectedCredentials]);

    const handleDismissBtnClick = useCallback(() => {
        onDismiss?.("cancel");
    }, [onDismiss]);

    useEffect(() => {
        if (open) {
            const action = dispatch(credentialActions.get());
            action.finally(() => {
                setLoading(false);
            });
        }
    }, [dispatch, open]);

    useEffect(() => {
        if (open && organizationId) {
            dispatch(organizationActions.getCredentials({ organizationId }));
        }
    }, [dispatch, open, organizationId]);

    return (
        <Dialog
            open={open}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={<Button appearance="transparent" icon={<Dismiss20Filled />} onClick={handleDismissBtnClick} />}
                    >
                        Add credential
                    </DialogTitle>
                    <DialogContent>
                        <Loader
                            loading={loading}
                            verticalAlignment="center"
                        >
                        <div
                            style={{
                                display: "flex",
                                columnGap: 8,
                                flexWrap: "wrap",
                                rowGap: 12,
                            }}
                        >
                            {requestError && (
                                <div style={{ color: "red" }}>{requestError}</div>
                            )}
                        {credentials.map((credential) => {
                            return (
                                <CredentialCard
                                    key={credential.id}
                                    credentialId={credential.id}
                                    disabled={organization.credentials?.some((orgCredential) => orgCredential.credentialId === credential.id)}
                                    selected={organization.credentials?.some((orgCredential) => orgCredential.credentialId === credential.id)}
                                    onSelectChange={handleCredentialSelectChange}
                                />
                            );
                        })}
                        </div>
                        </Loader>
                    </DialogContent>
                    <DialogActions>
                        <ButtonWithLoader
                            appearance="primary"
                            disabled={selectedCredentials.length === 0}
                            loading={requestInProgress}
                            onClick={handleRequestBtnClick}
                        >
                            Request
                        </ButtonWithLoader>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
};

export const AddCredentialDialog: React.FC<AddCredentialDialogProps> = (props: Readonly<AddCredentialDialogProps>) => {
    const { open } = props;

    return open ? <InnerDialog {...props} /> : null;
};

export default AddCredentialDialog;