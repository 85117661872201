import type { CollectionResponse, OrganizationCredential } from "../../../models";

import RequestBuilder from "../../RequestBuilder";

export class OrganizationCredentialCollectionRequestBuilder extends RequestBuilder {
    public async get() {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<CollectionResponse<OrganizationCredential>>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }
}