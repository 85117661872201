export class ArrayUtils {
    private static readonly _emptyArray: ReadonlyArray<any> = [];

    public static get empty() {
        return this._emptyArray;
    }

    public static emptyV2<T>(): ReadonlyArray<T> {
        return this._emptyArray;
    }
}