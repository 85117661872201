import { BreadcrumbButton, BreadcrumbDivider, BreadcrumbItem, Breadcrumb as FuiBreadcrumb } from "@fluentui/react-components";
import React, { useCallback } from "react";

import { useNavigate } from "react-router-dom";

interface BreadcrumbProps {
    items: ReadonlyArray<{ current?: boolean, href?: string, text: string }>;
}

export const Breadcrumb = (props: Readonly<BreadcrumbProps>) => {
    const { items } = props;
    const navigate = useNavigate();

    const handleBreadcrumbButtonClick = useCallback((e: React.MouseEvent<Element>) => {
        e.preventDefault();
        const target = e.target;
        if (target && "href" in target && typeof target.href === "string") {
            const relativePath = target.href.replace(window.location.origin, "");
            navigate(relativePath);
        }
    }, [navigate]);

    return (
        <FuiBreadcrumb>
            {items.map((itemProps, itemIndex) => (
                <React.Fragment key={itemProps.text}>
                    <BreadcrumbItem>
                        <BreadcrumbButton
                            as="a"
                            current={itemProps.current}
                            href={itemProps.href}
                            onClick={handleBreadcrumbButtonClick}
                        >
                            {itemProps.text}
                        </BreadcrumbButton>
                    </BreadcrumbItem>
                    {itemIndex < (items.length - 1) && (
                        <BreadcrumbDivider />
                    )}
                </React.Fragment>
            ))}
        </FuiBreadcrumb>
    )
};