import { useCallback, useEffect, useMemo, useState, type PropsWithChildren } from "react";

import { Flexbox, Loader } from "../ui";
import React from "react";
import { serviceClient } from "../../serviceClient";
import type { ApiObjectResponse, FormElement, FormInputElement, FormOutcome, FormRule, FormVersion } from "../../client";
import DynamicFormElement from "./DynamicFormElement";
import type { FormFile, FormPluginRender } from "./types";
import { ArrayUtils } from "../../utils";
import { useToaster } from "../../hooks";

interface SectionContainerProps extends PropsWithChildren {
    sectionsEnabled: boolean;
}

const SectionContainer = React.forwardRef<HTMLDivElement, SectionContainerProps>((props, forwardedRef) => {
    const { sectionsEnabled, children } = props;

    return sectionsEnabled ? (
        <Flexbox direction="column" ref={forwardedRef} rowGap>
            {children}
        </Flexbox>
    ) : children;
});

interface DynamicFormProps {
    defaultValues?: Record<string, unknown>;
    formId: string;
    readOnly?: boolean;
    versionId?: string;
    resolveToken?: (elementId: string, token: string) => string | undefined;
    onDeleteFile?: (elementId: string, elementName: string, formFile: FormFile) => Promise<boolean>;
    onRenderPlugin?: FormPluginRender;
    onValuesChange?: (values: Record<string, unknown>) => void;
    onOutcomeChange?: (formId: string, outcome: FormOutcome) => void;
}

export const DynamicForm = React.forwardRef<HTMLDivElement, Readonly<DynamicFormProps>>((props, forwardedRef) => {
    const {
        defaultValues,
        formId,
        versionId,
        readOnly,
        resolveToken,
        onDeleteFile,
        onRenderPlugin,
        onValuesChange,
        onOutcomeChange,
    } = props;
    const [rules, setRules] = useState<ReadonlyArray<FormRule>>([]);
    const [elements, setElements] = useState<ReadonlyArray<FormElement>>([]);
    const [formValues, setFormValues] = useState<Record<string, unknown>>(defaultValues ?? {});
    const [formOutcome, setFormOutcome] = useState<FormOutcome>({ status: "pending" });
    const [loading, setLoading] = useState(false);
    const toaster = useToaster();

    const { rootElements, sectionsEnabled } = useMemo(() => {
        let rootElements: ReadonlyArray<FormElement>;
        let sectionsEnabled: boolean;
        if (elements.some(element => element.elementType?.toUpperCase() === "SECTION")) {
            rootElements = elements.filter(element => element.elementType?.toUpperCase() === "SECTION").sort((a, b) => a.position - b.position);
            sectionsEnabled = true;
        } else {
            rootElements = elements.toSorted((a, b) => a.position - b.position);
            sectionsEnabled = false;
        }

        return { rootElements, sectionsEnabled };
    }, [elements]);

    const handleFormFileDelete = useCallback((elementId: string, elementName: string, formFile: FormFile) => {
        if (!onDeleteFile) {
            return Promise.resolve(false);
        }

        return new Promise<boolean>((resolve) => {
            onDeleteFile(elementId, elementName, formFile).then((result) => {
                if (result === true) {
                    setFormValues((prev) => ({
                        ...prev,
                        [elementName]: (prev[elementName] as FormFile[]).filter(file => file.id !== formFile.id),
                    }));
                }
                resolve(result);
            });
        });

    }, [onDeleteFile]);

    const handleFormInputElementChange = useCallback((_id: string, name: string, value: unknown) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const shouldRenderElement = useCallback((element: FormElement) => {
        const applicableRules = rules.filter(rule => rule.targetElementId === element.id);
        if (applicableRules.length === 0) {
            return true;
        }

        const result = applicableRules.every(rule => {
            const sourceElement = elements.find(e => e.id === rule.sourceElementId);
            if (!sourceElement || rule.value === null || rule.value === undefined) {
                return false;
            }

            const sourceValue = formValues[(sourceElement as FormInputElement)?.name] as any;
            switch (rule.operator) {
                case "eq":
                    return sourceValue === rule.value;
                case "ne":
                    return sourceValue !== rule.value;
                case "gt":
                    return sourceValue > rule.value;
                case "lt":
                    return sourceValue < rule.value;
                case "gte":
                    return sourceValue >= rule.value;
                case "lte":
                    return sourceValue <= rule.value;
                case "contains":
                    return typeof sourceValue === "string" ? sourceValue.includes(rule.value) : Array.isArray(sourceValue) && sourceValue.includes(rule.value);
                case "notContains":
                    return typeof sourceValue === "string" ? !sourceValue.includes(rule.value) : Array.isArray(sourceValue) && !sourceValue.includes(rule.value);
                default:
                    return false;
            }
        });

        return result;
    }, [elements, formValues, rules]);

    useEffect(() => {
        const getFormVersion = async (formId: string, versionId?: string) => {
            const formVersionCollectionBuilder = serviceClient.forms.form(formId).versions;
            let response: ApiObjectResponse<FormVersion>;
            if (versionId) {
                response = await formVersionCollectionBuilder.version(versionId).get();
            } else {
                response = await formVersionCollectionBuilder.published.get();
            }

            if (response.statusCode === 200) {
                setRules(response.data.rules ?? ArrayUtils.empty);
                setElements(response.data.elements ?? ArrayUtils.empty);
            }
        };

        if (formId) {
            setLoading(true);
            getFormVersion(formId, versionId).catch((error) => {
                toaster.push((error as any)?.message, { type: "error", title: "An error occurred while loading the form" });
            }).finally(() => setLoading(false));
        }
    }, [formId, toaster, versionId]);

    const renderFormElement = (element: FormElement) => {
        const show = shouldRenderElement(element);

        return show ? (
            <DynamicFormElement
                key={element.id}
                defaultValue={defaultValues?.[(element as FormInputElement)?.name] as any}
                element={element}
                readOnly={readOnly}
                resolveToken={resolveToken}
                onChange={handleFormInputElementChange}
                onDeleteFile={onDeleteFile ? handleFormFileDelete : undefined}
                onRenderPlugin={onRenderPlugin}
            />
        ) : null;
    };

    useEffect(() => {
        onOutcomeChange?.(formId, formOutcome);
    }, [formId, formOutcome, onOutcomeChange]);

    useEffect(() => {
        onValuesChange?.(formValues);
    }, [formValues, onValuesChange]);

    useEffect(() => {
        function isInHiddenSection(el: FormElement) {
            if (!sectionsEnabled) {
                return false;
            }

            const elIndex = elements.findIndex(e => e.id === el.id);
            if (elIndex < 0) {
                return false;
            }

            // Find the closest preceding section
            for (let i = elIndex - 1; i >= 0; i--) {
                if (elements[i].elementType?.toUpperCase() === "SECTION") {
                    return !shouldRenderElement(elements[i]);
                }
            }
            return false;
        }

        const requiredInputs = elements.filter(
            (el): el is FormInputElement => el.elementType?.toUpperCase() === "INPUT" && !!(el as FormInputElement).required
        );

        const visibleRequiredInputs = requiredInputs.filter(
            (el) => shouldRenderElement(el) && !isInHiddenSection(el)
        );

        const allRequiredFilled = visibleRequiredInputs.every(
            (el) => {
                const inputElement = el as FormInputElement;
                if (!inputElement) {
                    return false;
                }

                const fieldValue = formValues[inputElement.name];
                return fieldValue !== undefined && fieldValue !== null && fieldValue !== "" && (Array.isArray(fieldValue) ? fieldValue.length > 0 : true);
            }
        );

        setFormOutcome(prev => ({ ...prev, status: allRequiredFilled ? "acceptable" : "pending" }));
    }, [elements, sectionsEnabled, formValues, shouldRenderElement]);

    return (
        <Flexbox
            direction="column"
            ref={forwardedRef}
            rowGap
        >
            <Loader loading={loading}>
                {rootElements.map((rootElement) => {
                    if (sectionsEnabled) {
                        let sectionStartPos = 0;
                        let sectionEndPos = 0;
                        let sectionElements: ReadonlyArray<FormElement> = [];
                        if (rootElement.elementType?.toUpperCase() === "SECTION") {
                            sectionStartPos = elements.findIndex(element => element.id === rootElement.id);
                            sectionEndPos = elements.findIndex((element, index) => index > sectionStartPos && element.elementType?.toUpperCase() === "SECTION");
                            if (sectionEndPos === -1) {
                                sectionEndPos = elements.length;
                            }
                            sectionElements = elements.slice(sectionStartPos + 1, sectionEndPos);
                        }

                        const show = shouldRenderElement(rootElement);

                        return show ? (
                            <SectionContainer
                                key={rootElement.id}
                                sectionsEnabled={sectionsEnabled}
                            >
                                {sectionElements.map((childElement) => renderFormElement(childElement))}
                            </SectionContainer>
                        ) : null;
                    } else {
                        return renderFormElement(rootElement);
                    }
                })}
            </Loader>
        </Flexbox>
    );
});

export default DynamicForm;