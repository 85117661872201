import { Flexbox, FlowStep, type FlowNavigationDirection } from "../../../ui";
import { credentialActions, isPayloadAction, organizationActions, selectAllCredentials } from "../../../../store";
import {
    useAppDispatch,
    useAppSelector,
    useToaster,
} from "../../../../hooks";
import { useCallback, useEffect, useState } from "react";
import { type Credential, type OrganizationCredentialRequestItem } from "../../../../client";
import { CredentialCompactCard } from "../../cards";
import type React from "react";
import type { RequestCredentialFlowStepProps } from "./types";
import { isRequestFailedError } from "../../../../client/RequestFailedError";

export const RequestCredentialBrowseFlowStep: React.FC<RequestCredentialFlowStepProps> = (props: Readonly<RequestCredentialFlowStepProps>) => {
    const { organization, onNavigate, ...stepProps } = props;
    const credentials = useAppSelector(selectAllCredentials);
    const dispatch = useAppDispatch();
    const toaster = useToaster();
    const [, setFetchInProgress] = useState(credentials.length === 0);
    const existingCredentials = organization?.credentials;
    const [selectedIds, setSelectedIds] = useState<ReadonlyArray<string>>([]);
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const canContinue = organization?.credentialRequests?.some((r) => r.state?.toUpperCase() === "OPEN" && r.requestItems.length > 0) || selectedIds.length > 0;

    const submit = useCallback(async (ids: ReadonlyArray<string>) => {
        if (!organization?.id) {
            throw new Error("Organization ID is required to submit credential requests.");
        }

        for (const credentialId of ids) {
            const actionResult = await dispatch(organizationActions.addOpenCredentialRequestItem({ organizationId: organization.id, credentialId }));

            if (!isPayloadAction<OrganizationCredentialRequestItem>(actionResult)) {
                throw new Error("Something went wrong while submitting credential requests");
            }

            if (actionResult.error) {
                if (isRequestFailedError(actionResult.error) && actionResult.error.code === "duplicate_resource") {
                    console.warn("Credential request already exists for this organization and credential");
                    dispatch(organizationActions.getOpenCredentialRequest({ organizationId: organization.id }));
                } else {
                    throw actionResult.error;
                }
            }
        }
    }, [dispatch, organization?.id]);

    const handleCredentialSelectionChange = useCallback((credential: Credential, selected: boolean) => {
        if (selected) {
            setSelectedIds((ids) => [...ids, credential.id]);
        } else {
            setSelectedIds((ids) => ids.filter((id) => id !== credential.id));
        }
    }, []);

    const handleStepNavigation = useCallback((direction: FlowNavigationDirection) => {
        if (direction !== "forward") {
            onNavigate?.(direction);
            return;
        }

        setSubmitInProgress(true);
        submit(selectedIds).then(() => {
            onNavigate?.(direction);
        }).catch((error) => {
            toaster.push(error.message, { type: "error", title: "Something went wrong while requesting the credential." });
        }).finally(() => {
            setSubmitInProgress(false);
        });
    }, [onNavigate, selectedIds, submit, toaster]);

    useEffect(() => {
        const getCredentialsAction = dispatch(credentialActions.get());
        const getOrganizationAction = dispatch(organizationActions.getById(organization?.id));
        Promise.all([getCredentialsAction, getOrganizationAction]).catch(() => {
            toaster.push("Something went wrong while loading credentials", { type: "error" });
        }).finally(() => {
            setFetchInProgress(false);
        });
    }, [dispatch, organization?.id, toaster]);

    return (
        <FlowStep
            {...stepProps}
            actionsProps={{
                ...stepProps.actionsProps,
                actionInProgress: submitInProgress,
                disablePrimaryAction: !canContinue,
            }}
            title="Which credential are you applying for?"
            description="Select the credentials that your organization qualifies for. Additional documentation maybe required support the credential request."
            onNavigate={handleStepNavigation}
        >
            <Flexbox direction="column" rowGap>
                {credentials.map((credential) => (
                    <CredentialCompactCard
                        key={credential.id}
                        credential={credential}
                        defaultSelected={existingCredentials?.some((cr) => cr.credentialId === credential.id)}
                        disabled={existingCredentials?.some((cr) => cr.credentialId === credential.id)}
                        onSelectChange={!existingCredentials ? undefined : handleCredentialSelectionChange}
                    />
                ))}
            </Flexbox>
        </FlowStep>
    );
};