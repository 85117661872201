import { Button, Card, CardFooter, CardHeader, CardPreview, Text } from "@fluentui/react-components";

import { Delete20Regular } from "@fluentui/react-icons";
import { DocumentResolutionDialogTrigger } from "../verification/modals/DocumentResolutionDialogTrigger";
import DocumentVerificationTag from "../common/data/DocumentVerificationTag";
import type { VerificationDocument } from "../../client";

interface DocumentPreviewCardProps {
    document: VerificationDocument;
    organizationId: string;
    requestId: string;
    onDelete?: (doc: VerificationDocument) => void;
}

export const DocumentPreviewCard = (props: Readonly<DocumentPreviewCardProps>) => {
    const { document, organizationId, requestId, onDelete } = props;

    const handleDeleteBtnClick = () => {
        onDelete?.(document);
    };

    return (
        <Card key={document.id}>
            <CardHeader
                action={onDelete ? <Button
                    appearance="transparent"
                    icon={<Delete20Regular />}
                    aria-label="Delete document"
                    onClick={handleDeleteBtnClick}
                /> : undefined}
                header={<Text weight="semibold">{document.name}</Text>}
            />
            <CardPreview>

            </CardPreview>
            <CardFooter>
                <div
                    style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <DocumentVerificationTag status={document.verificationStatus} />
                    {document.approvalResult?.value === "rejected" && (
                        <DocumentResolutionDialogTrigger
                            documentId={document.id}
                            organizationId={organizationId}
                            verificationId={requestId}
                        />
                    )}
                </div>
            </CardFooter>
        </Card>
    );
}

export default DocumentPreviewCard;