import { Button } from "../controls/buttons/Button/Button";
import type { FlowStepActionsProps } from "./types";
import { useCallback } from "react";

export const FlowStepActions = (props: Readonly<FlowStepActionsProps>) => {
    const {
        actionInProgress,
        disableSecondaryAction,
        disablePrimaryAction,
        hideSecondaryAction,
        hidePrimaryAction,
        secondaryActionText,
        primaryActionText,
        onNavigate,
    } = props;

    const handleBackBtnClick = useCallback(() => {
        onNavigate?.("backward");
    }, [onNavigate]);

    const handleNextBtnClick = useCallback(() => {
        onNavigate?.("forward");
    }, [onNavigate]);

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 12, }}>
            <div>&nbsp;</div>
            <div
                style={{
                    display: "flex",
                    columnGap: 8,
                }}
            >
                {!hideSecondaryAction && (
                    <Button
                        disabled={disableSecondaryAction || actionInProgress}
                        size="large"
                        text={secondaryActionText ?? "Back"}
                        onClick={handleBackBtnClick}
                    />
                )}
                {!hidePrimaryAction && (
                    <Button
                        disabled={disablePrimaryAction}
                        loading={actionInProgress}
                        size="large"
                        text={primaryActionText ?? "Continue"}
                        variant="primary"
                        onClick={handleNextBtnClick}
                    />
                )}
            </div>
        </div>
    );
};