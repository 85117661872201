import { useEffect, useState } from "react";

interface DocumentPreviewProps {
    contentType?: string;
    disableScrolling?: boolean;
    name?: string;
    style?: React.CSSProperties;
    webUrl: string;
}

const isImageType = (contentType?: string) => contentType?.startsWith("image/") === true;

const updateIframeSize = (el: HTMLIFrameElement) => {
    try {
        if (el.contentWindow) {
            const scrollHeight = el.contentWindow.document.body.scrollHeight;
            const scrollWidth = el.contentWindow.document.body.scrollWidth;
            el.style.height = `${scrollHeight}px`;
            el.style.width = `${scrollWidth}px`;
        }
    } catch (e) {
        // Cross-origin error
    }
};

export const DocumentPreview = (props: Readonly<DocumentPreviewProps>) => {
    const { contentType, disableScrolling, name, style, webUrl } = props;
    const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

    useEffect(() => {
        if (iframeRef) {
            iframeRef.onload = () => {
                updateIframeSize(iframeRef);
            };
        }

        return () => {
            if (iframeRef) {
                iframeRef.onload = null;
            }
        };
    }, [iframeRef]);

    useEffect(() => {
        if (webUrl) {
            // Load the document from the contentUri
        }
    }, [webUrl]);

    return (
        <div style={{ flexGrow: 1, height: "100%", width: "100%", ...style }}>
            {isImageType(contentType) ? (
                <img src={webUrl} alt="Document" />
            ) : (
                <iframe
                    ref={setIframeRef}
                    title={name}
                    src={webUrl}
                    scrolling={disableScrolling === true ? "no" : undefined}
                    style={{
                        border: "none",
                        height: "100%",
                        width: "100%",
                        overflowX: disableScrolling === true ? "hidden" : undefined,
                        overflowY: disableScrolling === true ? "hidden" : undefined,
                    }}
                />
            )}
        </div>
    );
};

export default DocumentPreview;