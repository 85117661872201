import "./wdyr"; // wdyr must be imported before react-dom
import './index.css';
import "./global";

import * as Sentry from "@sentry/react";

import App from './App';
import AppRoutes from './router/AppRoutes';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { msalInstance } from './msal';
import posthog from 'posthog-js';
import reportWebVitals from './reportWebVitals';
import { store } from "./store";

if (window.sentryDsn && window.sentryDsn.length > 0) {
  Sentry.init({
    dsn: window.sentryDsn,
    environment: window.sentryEnvironment ?? "development",
  });
}

if (window.postHogToken && window.postHogToken.length > 0) {
  posthog.init(window.postHogToken, {
    api_host: "https://us.i.onecredential.io",
    ui_host: 'https://us.posthog.com',
    person_profiles: "identified_only",
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App>
          <AppRoutes />
        </App>
      </MsalProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
