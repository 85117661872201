import { Box, Button, Flexbox } from "../../ui";
import { Subtitle1, Title3 } from "@fluentui/react-components";
import { organizationActions, selectOrganizationById } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useCallback, useMemo } from "react";

import { DetailText } from "../../common";
import { DocumentPreviewCard } from "../../documents";
import { StringUtils } from "../../../utils";
import type { VerificationDocument } from "../../../client";
import routePaths from "../../../router/routePaths";
import { useNavigate } from "react-router-dom";

interface OrganizationVerificationRequestCardProps {
    loading?: boolean;
    organizationId: string;
}

export const OrganizationVerificationRequestCard = (props: Readonly<OrganizationVerificationRequestCardProps>) => {
    const { loading, organizationId } = props;
    const organization = useAppSelector(state => selectOrganizationById(state, organizationId));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const openVerificationRequest = useMemo(() => {
        return organization?.verificationRequests?.find(request => request.state?.toUpperCase() === "OPEN");
    }, [organization?.verificationRequests]);
    const openVerificationRequestId = openVerificationRequest?.id;

    const verificationStatusText = useMemo(() => {
        const normalizedStatus = openVerificationRequest?.status?.toUpperCase();
        switch (normalizedStatus) {
            case "INCOMPLETE":
                return "In progress";
            case "COMPLETED":
            case "APPROVED":
                return "Verified";
            case "PENDING":
                return "Pending review";
            case "PAYMENTREQUIRED":
                return "Payment required";
            case "PROCESSINGPAYMENT":
            case "AWAITINGPAYMENT":
                return "Processing payment";
            default:
                return "Not verified";
        }
    }, [openVerificationRequest?.status]);

    const verificationButtonText = useMemo(() => {
        const normalizedStatus = openVerificationRequest?.status?.toUpperCase();
        switch (normalizedStatus) {
            case "COMPLETED":
            case "PENDING":
            case "PROCESSINGPAYMENT":
            case "AWAITINGPAYMENT":
                return undefined;
            case "PAYMENTREQUIRED":
                return "Complete payment";
            case "INCOMPLETE":
                return "Continue verification";
            default:
                return "Get verified";
        }
    }, [openVerificationRequest?.status]);

    const handleVerifyBtnClick = useCallback(() => {
        if (!StringUtils.isNullOrEmpty(organizationId)) {
            navigate(routePaths.organizations.verify.replace(":organizationId", organizationId));
        }
    }, [navigate, organizationId]);

    const handleDeleteDocument = useCallback((doc: VerificationDocument) => {
        dispatch(organizationActions.deleteVerificationDocument({ organizationId: organizationId!, requestId: openVerificationRequestId!, documentId: doc.id }));
    }, [dispatch, openVerificationRequestId, organizationId]);

    return (
        <Flexbox direction="column" rowGap>
            <Title3>Verification</Title3>
            <Box>
                <div style={{ display: "flex", flexDirection: "column", rowGap: 8 }}>
                    <DetailText
                        label="Status"
                        loading={loading}
                        value={(
                            <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
                                <Subtitle1>
                                    {verificationStatusText}
                                </Subtitle1>
                                {verificationButtonText && (
                                    <Button variant="primary" onClick={handleVerifyBtnClick}>{verificationButtonText}</Button>
                                )}
                            </div>
                        )}
                    />
                    <DetailText
                        label="Documents"
                        loading={loading}
                        value={(openVerificationRequest?.documents?.length && openVerificationRequest.documents.length > 0) ? (
                            <Flexbox direction="column" rowGap>
                                {openVerificationRequest.documents.map((doc) => (
                                    <DocumentPreviewCard
                                        key={doc.id}
                                        document={doc}
                                        organizationId={organizationId}
                                        requestId={openVerificationRequest.id}
                                        onDelete={(doc.approvalResult?.value?.toUpperCase() === "APPROVED" || openVerificationRequest.submittedAt) ? undefined : handleDeleteDocument}
                                    />
                                ))}
                            </Flexbox>
                        ) : (
                            "No documents uploaded"
                        )}
                    />
                </div>
            </Box>
        </Flexbox>
    )
}