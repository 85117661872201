import { Page, useModalLayout } from "../../layouts";
import { useCallback, useEffect } from "react";

import AddOrganizationFlow from "../../components/organization/flows/AddOrganizationFlow/AddOrganizationFlow";
import routePaths from "../../router/routePaths";
import { useNavigate } from "react-router-dom";

export const AddOrganizationPage = () => {
    const { subscribe } = useModalLayout();
    const navigate = useNavigate();

    const handleExit = useCallback(() => {
        navigate(routePaths.dashboard);
    }, [navigate]);

    useEffect(() => {
        const unsubscribe = subscribe(() => {
            handleExit();
        });

        return unsubscribe;
    }, [handleExit, subscribe]);

    return (
        <Page>
            <AddOrganizationFlow
                onExit={handleExit}
            />
        </Page>
    );
};