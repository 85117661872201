import { createAsyncThunk } from "@reduxjs/toolkit";
import { serviceClient } from "../../serviceClient";

const get = createAsyncThunk(
    "forms/get",
    async(nextLink?: string) => {
        const response = await serviceClient.forms.get(nextLink);
        return response.data;
    },
);

const getById = createAsyncThunk(
    "forms/getById",
    async(formId: string) => {
        const response = await serviceClient.forms.form(formId).get();
        return response.data;
    },
);

const getPublishedVersion = createAsyncThunk(
    "forms/getPublishedVersion",
    async(formId: string) => {
        const response = await serviceClient.forms
            .form(formId).versions.published
            .get();
        return response.data;
    },
);

export const formActions = {
    get,
    getById,
    getPublishedVersion,
};