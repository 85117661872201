import React, { useEffect } from "react";

interface UseScriptOptions {
    removeOnUnmount?: boolean;
}

export type UseScriptStatus = "loading" | "ready" | "error" | "unknown";

function setScriptStatus(element: HTMLElement, status: UseScriptStatus) {
    element.setAttribute("data-status", status);
}

export function useScript(src: Readonly<string>, options?: Readonly<UseScriptOptions>) {
    const [status, setStatus] = React.useState<UseScriptStatus>("loading");
    const optionsRef = React.useRef(options ?? {});

    useEffect(() => {
        let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);

        const domStatus = script?.getAttribute("data-status");
        if (domStatus) {
            setStatus(domStatus as UseScriptStatus);
            return;
        }

        if (!script) {
            script = document.createElement("script");
            script.src = src;
            script.async = true;
            setScriptStatus(script, "loading");
            document.body.appendChild(script);

            const handleScriptLoad = (e: unknown) => {
                console.log("Script loaded", e);
                script?.setAttribute("data-status", "ready");
                setStatus("ready");
                removeEventListeners();
            };

            const handleScriptError = (e: unknown) => {
                console.error("Script error", e);
                script?.setAttribute("data-status", "error");
                setStatus("error");
                removeEventListeners();
            };

            const removeEventListeners = () => {
                script?.removeEventListener("load", handleScriptLoad);
                script?.removeEventListener("error", handleScriptError);
            };

            script.addEventListener("load", handleScriptLoad);
            script.addEventListener("error", handleScriptError);

            const removeOnUnmount = optionsRef.current.removeOnUnmount;

            return () => {
                if (removeOnUnmount === true) {
                    script?.remove();
                    removeEventListeners();
                }
            };
        } else {
            setStatus("unknown");
        }
    }, [src]);

    return status;
}