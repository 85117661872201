import { Body2, Subtitle2Stronger } from "@fluentui/react-components";

export const VerificationStepsListItem = (props: {description: string, details: string}) => {
    const { description, details } = props;

    return (
        <li>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <Subtitle2Stronger>{description}</Subtitle2Stronger>
                <Body2>{details}</Body2>
            </div>
        </li>
    );
}