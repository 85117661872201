import { Button as FuiButton, Body1Strong, type SelectionEvents } from "@fluentui/react-components";
import { Box, Button, Flexbox, Tag } from "../../ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector, useToaster } from "../../../hooks";
import { credentialActions, organizationActions, selectOrganizationById } from "../../../store";
import { ChevronDown28Regular, ChevronUp28Regular } from "@fluentui/react-icons";
import { Collapse } from "@fluentui/react-motion-components-preview";
import { type FormInputElement, type FormOutcome, type Organization, type OrganizationCredentialRequestItem } from "client/models";
import DynamicForm from "../../form/DynamicForm";
import OwnerTagSelection from "../../form/OwnerSelection";
import { ArrayUtils } from "../../../utils";
import type { DynamicFormInputElementValueType, FormFile } from "../../form/types";
import { serviceClient } from "../../../serviceClient";
import RequestFailedError from "../../../client/RequestFailedError";

type OrganizationKey = keyof Organization;

interface CredentialRequestEvidenceCompactCardProps {
    defaultValues?: Record<string, any>;
    disabled?: boolean;
    expand?: boolean;
    loading?: boolean;
    organizationId: string;
    requestId: string;
    requestItem: OrganizationCredentialRequestItem;
    selected?: boolean;
}

export const CredentialRequestEvidenceCompactCard = (props: Readonly<CredentialRequestEvidenceCompactCardProps>) => {
    const {
        defaultValues,
        requestId,
        requestItem,
        loading,
        organizationId,
    } = props;
    const { credential, credentialId } = requestItem;
    const dispatch = useAppDispatch();
    const toaster = useToaster();
    const credentialPropCount = credential ? Object.keys(credential).length : 0;
    const hasForm = Boolean(requestItem.formId && requestItem.formVersionId);
    const [, setFetchInProgress] = useState(false);
    const [expand, setExpand] = useState(props.expand ?? false);
    const [expanded, setExpanded] = useState(props.expand ?? false);
    const organization = useAppSelector(s => selectOrganizationById(s, organizationId));
    const valuesRef = useRef<Record<string, any>>(defaultValues ?? {});
    const [formOutcome, setFormOutcome] = useState<FormOutcome>({ status: "pending" });
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

    const readOnly = useMemo(() => {
        return requestItem.isDraft === false;
    }, [requestItem?.isDraft]);

    const submit = useCallback(async (draft: boolean) => {
        if (valuesRef.current) {
            for (const key in valuesRef.current) {
                const fieldValue = valuesRef.current[key];
                if (Array.isArray(fieldValue) && fieldValue.length > 0 && (fieldValue[0] as FormFile)?.id) {
                    const formFiles: FormFile[] = fieldValue as FormFile[];
                    for (let i = 0; i < formFiles.length; i++) {
                        const formFile = formFiles[i];
                        if (formFile.content) {
                            const file = formFile.content;
                            const response = await serviceClient.organizations
                                .organization(organizationId).credentialRequests
                                .credentialRequest(requestId).items
                                .item(requestItem.id).documents
                                .submit({ name: file.name ?? "" }, file);
                            if (response.statusCode < 400) {
                                const result = response.data;
                                const formFile: FormFile = {
                                    id: result.id,
                                    name: result.name,
                                    uri: `https://api.onecredential.io/organizations/${organizationId}/credentialRequests/${requestId}/items/${requestItem.id}/documents/${result.id}`,
                                };
                                valuesRef.current[key] = [...(valuesRef.current[key] as File[]).slice(0, i), formFile, ...(valuesRef.current[key] as File[]).slice(i + 1)];
                            } else {
                                throw new Error(`Failed to upload form file ${formFile.name}`);
                            }
                        }
                    }
                }
            }
        }

        await serviceClient.organizations
            .organization(organizationId).credentialRequests
            .credentialRequest(requestId).items
            .item(requestItem.id).response
            .put({ draft, values: valuesRef.current });

        try {
            await dispatch(organizationActions.getById(organizationId));
        } catch {
            console.error("Failed to refresh organization data after credential request submission");
        }
    }, [dispatch, organizationId, requestId, requestItem?.id]);

    const handleSaveClick = useCallback(async () => {
        const draft = formOutcome?.status !== "acceptable";
        setSaveInProgress(true);
        try {
            await submit(draft);
        } catch (error) {
            toaster.push((error as any)?.message, { type: "error", title: `There was a problem ${draft ? "saving" : "submitting"} your credential request` });
        } finally {
            setSaveInProgress(false);
        }
    }, [formOutcome?.status, submit, toaster]);

    const handleFormOutcomeChange = useCallback((_formId: string, outcome: FormOutcome) => {
        setFormOutcome(outcome);
    }, []);

    const handleCollapseMotionStart = (_ev: null, data: { direction: "enter" | "exit"; }) => {
        setExpanded(prev => {
            if (data.direction === "enter") {
                return true;
            } else {
                return prev;
            }
        });
    };

    const handleCollapseMotionFinished = (_ev: null, data: { direction: "enter" | "exit"; }) => {
        setExpanded(data.direction === "enter");
    };

    const deleteFormFile = useCallback(async (organizationId: string, requestId: string, requestItemId: string, documentId: string) => {
        const credentialRequestItemRequestBuilder = serviceClient.organizations
            .organization(organizationId).credentialRequests
            .credentialRequest(requestId).items
            .item(requestItemId);
        try {
            await credentialRequestItemRequestBuilder.documents
                .document(documentId)
                .delete();
        } catch (error) {
            if (error instanceof RequestFailedError && error.statusCode === 404) {
                // File is already deleted, proceed to remove from form values.
            } else {
                throw error;
            }
        }
    }, []);

    const handleDeleteFile = useCallback((_elementId: string, _name: string, formFile: FormFile) => {
        setSaveInProgress(true);
        const fileName = formFile.name;
        return new Promise<boolean>(async (resolve) => {
            try {
                await deleteFormFile(organizationId, requestId, requestItem.id, formFile.id);
                resolve(true);
            } catch (error) {
                toaster.push((error as any)?.message, { type: "error", title: `There was a problem deleting ${fileName ?? "the file"}` });
                setSaveInProgress(false);
                resolve(false);
                return;
            }

            if (!valuesRef.current) {
                return true;
            }

            await new Promise(resolve => setTimeout(resolve, 300));

            const credentialRequestItemRequestBuilder = serviceClient.organizations
                .organization(organizationId).credentialRequests
                .credentialRequest(requestId).items
                .item(requestItem.id);

            try {
                await credentialRequestItemRequestBuilder.response
                    .put({
                        draft: true,
                        values: valuesRef.current,
                    });
                toaster.push(`${fileName ?? "File"} deleted successfully`, { type: "success" });
                return true;
            } catch (error) {
                toaster.push((error as any)?.message, { type: "error", title: "There was a problem saving your changes" });
                return false;
            } finally {
                setSaveInProgress(false);
            }
        });
    }, [deleteFormFile, organizationId, requestId, requestItem.id, toaster]);

    const handleRenderPlugin = useCallback((element: FormInputElement, formProps?: { defaultValue?: unknown, readOnly?: boolean }, onChange?: (value: DynamicFormInputElementValueType) => void) => {
        const handleChange = (_e: SelectionEvents, value: string[]) => {
            onChange?.(value);
        };

        if (element.pluginId?.toUpperCase() === "OWNERTAGGING" || element.pluginId?.toUpperCase() === "OWNERSELECTION") {
            const ethnicity = valuesRef.current?.["ethnicity"];
            const label = `Please select all owners that are ${ethnicity}`;
            return (
                <OwnerTagSelection
                    defaultValue={formProps?.defaultValue as any}
                    label={label}
                    name={element.name}
                    owners={organization?.owners ?? ArrayUtils.empty}
                    readOnly={formProps?.readOnly}
                    required={element.required}
                    onChange={handleChange}
                />
            );
        } else {
            return null;
        }
    }, [organization?.owners]);

    const handleDynamicFormValuesChange = useCallback((values: Record<string, any>) => {
        valuesRef.current = values;
    }, []);

    const resolveToken = useCallback((_elementId: string, token: string) => {
        const tokenParts = token?.split(".");
        if (!tokenParts || tokenParts.length < 1) {
            return undefined;
        }

        const resource = tokenParts[0];
        if (resource === "organization") {
            const property = tokenParts.length > 1 ? tokenParts[1] as OrganizationKey : undefined;
            if (organization && property && typeof (organization[property]) === "string") {
                return organization[property];
            }
        }

        return undefined;
    }, [organization]);

    useEffect(() => {
        let action;
        if (!loading && credentialId && credentialPropCount < 2) {
            setFetchInProgress(true);
            action = dispatch(credentialActions.getById(credentialId));
            action.finally(() => {
                setFetchInProgress(false);
            });
        }
    }, [credentialId, credentialPropCount, loading, dispatch]);

    return (
        <Box>
            <Flexbox
                fullWidth
                verticalAlign="start"
            >
                <Flexbox
                    direction="column"
                    fullWidth
                    rowGap={expanded ? true : undefined}
                >
                    <Flexbox
                        fullWidth
                        verticalAlign="start"
                    >
                        <Flexbox direction="column" fullWidth>
                            <Body1Strong>{credential?.name}</Body1Strong>
                            {(!expanded && !loading) && (
                                <Tag>{formOutcome?.status === "acceptable" ? "Completed" : "Incomplete"}</Tag>
                            )}
                        </Flexbox>
                        <FuiButton
                            aria-description={expand ? "Collapse application" : "Expand application"}
                            aria-expanded={expand}
                            appearance="transparent"
                            icon={expand ? <ChevronUp28Regular /> : <ChevronDown28Regular />}
                            style={{
                                visibility: hasForm ? "visible" : "hidden",
                            }}
                            title={expand ? "Hide application" : "Show application"}
                            onClick={() => setExpand((prev) => !prev)}
                        />
                    </Flexbox>
                    {(hasForm) && (
                        <Collapse
                            visible={expand}
                            unmountOnExit={false}
                            onMotionStart={handleCollapseMotionStart}
                            onMotionFinish={handleCollapseMotionFinished}
                        >
                            <Flexbox direction="column" rowGap>
                                {(requestItem.formId && requestItem.formVersionId) && (
                                    <>
                                        <DynamicForm
                                            defaultValues={defaultValues}
                                            formId={requestItem.formId}
                                            readOnly={readOnly || saveInProgress}
                                            resolveToken={resolveToken}
                                            versionId={requestItem.formVersionId}
                                            onDeleteFile={handleDeleteFile}
                                            onRenderPlugin={handleRenderPlugin}
                                            onOutcomeChange={handleFormOutcomeChange}
                                            onValuesChange={handleDynamicFormValuesChange}
                                        />
                                        {!readOnly && (
                                            <Button
                                                loading={saveInProgress}
                                                text={formOutcome?.status === "acceptable" ? "Submit" : "Save"}
                                                onClick={handleSaveClick}
                                            />
                                        )}
                                    </>
                                )}
                            </Flexbox>
                        </Collapse>
                    )}
                </Flexbox>
            </Flexbox>
        </Box>
    )
};

export default CredentialRequestEvidenceCompactCard;