import React, { useEffect, type PropsWithChildren } from 'react';
import { Button, Toaster } from '@fluentui/react-components';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useAppDispatch, useAppSelector } from './hooks';
import posthog from 'posthog-js';
import { AccountSetupGuard } from './components/AccountSetupGuard';
import { Flexbox, ThemeProvider } from './components/ui';
import type { AccountInfo } from '@azure/msal-browser';
import { meActions } from './store';

export const App: React.FC<PropsWithChildren> = ({ children }) => {
	const { instance: msal, inProgress, accounts } = useMsal();
	const activeAccount = useAccount();
	const me = useAppSelector(s => s.me.me);
	const dispatch = useAppDispatch();

	const objectIdClaim = activeAccount?.idTokenClaims?.oid;

	const handleSignInClick = (account?: AccountInfo) => {
		msal.loginRedirect({
			...loginRequest,
			account,
			prompt: "login",
		}).catch((error) => {
			console.error(error);
		});
	};

	useEffect(() => {
		const callbackId = msal.addEventCallback((message) => {
			if (message.eventType === "msal:loginSuccess" && message.payload !== null && "account" in message.payload && message.payload.account) {
				msal.setActiveAccount(message.payload.account);
			}
		}, [
			"msal:loginSuccess",
		]);

		return () => {
			if (callbackId) {
				msal.removeEventCallback(callbackId);
			}
		}
	}, [msal]);

	useEffect(() => {
		const activeAccount = msal.getActiveAccount();
		const allAccounts = msal.getAllAccounts();
		if (!activeAccount?.idToken && allAccounts.length > 0) {
			msal.setActiveAccount(allAccounts[0]);
		}
	}, [msal]);

	useEffect(() => {
		if (inProgress === "none" && objectIdClaim && !me?.id) {
			dispatch(meActions.get());
		}
	}, [dispatch, inProgress, objectIdClaim, me?.id]);

	useEffect(() => {
		if (posthog && me?.id) {
			posthog.identify(me.id, {
				email: me.emailAddress,
				name: me.preferredName ?? me.givenName,
			});
		}
	}, [me?.id, me?.emailAddress, me?.preferredName, me?.givenName]);

	return (
		<ThemeProvider>
			<BrowserRouter>
				<Toaster />
				<AuthenticatedTemplate>
					{activeAccount ? (
						children
					) : null}
					<AccountSetupGuard />
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
						}}
					>
						<div>OneCredential</div>
						<Flexbox direction="column" rowGap>
						{accounts.length > 1 ? (
							<>
								<p>Choose an account</p>
								{accounts.map((account) => (
									<Button
										key={account.localAccountId}
										appearance="subtle"
										onClick={() => handleSignInClick(account)}
									>
										{account.username}
									</Button>
								))}
							</>
						) : (
							<>
								<p>Click Sign in to get started</p>
								<Button onClick={() => handleSignInClick()}>Sign in</Button>
							</>
						)}
						</Flexbox>
					</div>
				</UnauthenticatedTemplate>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;

App.whyDidYouRender = true;