import { createAsyncThunk } from "@reduxjs/toolkit";
import { serviceClient } from "../../serviceClient";

const get = createAsyncThunk(
    "verificationRequests/get",
    async() => {
        return await serviceClient.verificationRequests.get();
    },
);

const getById = createAsyncThunk(
    "verificationRequests/getById",
    async(verificationId: string) => {
        return await serviceClient.verificationRequests.verificationRequest(verificationId).get();
    },
);

const approveDocument = createAsyncThunk(
    "verificationRequests/approve",
    async(args: { verificationId: string, documentId: string }) => {
        return (await serviceClient.verificationRequests
            .verificationRequest(args.verificationId).documents
            .document(args.documentId).approvalResult
            .put({ value: "approve" })).data;
    },
);

const rejectDocument = createAsyncThunk(
    "verificationRequests/reject",
    async(args: { verificationId: string, documentId: string, reason: string }) => {
        return (await serviceClient.verificationRequests
            .verificationRequest(args.verificationId).documents
            .document(args.documentId).approvalResult
            .put({ value: "reject", reason: args.reason })).data;
    }
);

export const verificationRequestActions = {
    get,
    getById,
    approveDocument,
    rejectDocument,
};