import { useCallback, useState, type SetStateAction } from "react";
import { AddOrganizationEntityMatchFlowStep } from "./AddOrganizationEntityMatchFlowStep";
import { type FlowStepItem, type FlowStepSection } from "../../../ui/flow";
import type { OrganizationOwnerFormValues, OrganizationSetupFormValues, OrganizationVerificationFormValues } from "../../types";
import { AddOrganizationDetailsFlowStep } from "./AddOrganizationDetailsFlowStep";
import { AddOrganizationCountryFlowStep } from "./AddOrganizationCountryFlowStep";
import { AddOrganizationReviewFlowStep } from "./AddOrganizationReviewFlowStep";
import { AddOrganizationCompletedFlowStep } from "./AddOrganizationCompletedFlowStep";
import { AddOrganizationOwnersFlowStep } from "./AddOrganizationOwnersFlowStep";
import { useAppSelector } from "../../../../hooks";
import { selectOrganizationById } from "../../../../store";
import { Flow } from "../../../ui";
import { HandWaveFilled, ShieldKeyholeFilled } from "@fluentui/react-icons";
import { AddOrganizationIntroFlowStep } from "./AddOrganizationIntroFlowStep";

const flowItems: ReadonlyArray<FlowStepItem> = [{
    title: "Introduction",
    collapsed: true,
    icon: <HandWaveFilled />,
    steps: [{
        name: "Introduction",
        element: AddOrganizationIntroFlowStep,
        actionsProps: {
            secondaryActionText: "Cancel",
        },
    },{
        name: "Country",
        element: AddOrganizationCountryFlowStep,
    }, {
        name: "Details",
        element: AddOrganizationDetailsFlowStep,
    }, {
        name: "Public records",
        element: AddOrganizationEntityMatchFlowStep,
    }, {
        name: "Ownership",
        element: AddOrganizationOwnersFlowStep,
    }, {
        name: "Review",
        element: AddOrganizationReviewFlowStep,
    }, {
        name: "-",
        element: AddOrganizationCompletedFlowStep,
    }],
    state: "active",
} satisfies FlowStepSection, {
    title: "Verification",
    icon: <ShieldKeyholeFilled />,
    steps: [],
}];

interface AddOrganizationFlowProps {
    onExit?: () => void;
}

export const AddOrganizationFlow = (props: Readonly<AddOrganizationFlowProps>) => {
    const { onExit } = props;
    const [values, setValues] = useState<OrganizationVerificationFormValues>({});
    const organization = useAppSelector(state => values?.organizationId ? selectOrganizationById(state, values.organizationId) : null);

    const handleValuesChange = useCallback((values: SetStateAction<OrganizationSetupFormValues>) => {
        setValues((prev) => {
            let nextValues: OrganizationSetupFormValues;
            if (typeof values === "function") {
                nextValues = values(prev);
            } else {
                nextValues = values;
            }

            const nextOwners: Record<string, OrganizationOwnerFormValues> = {};
            for (const [clientSideKey, clientSideObject] of Object.entries(nextValues.owners ?? {})) {
                const matchingOwner = organization?.owners?.find(owner => owner.emailAddress === clientSideObject.emailAddress);
                if (matchingOwner?.id) {
                    nextOwners[matchingOwner.id] = {
                        ...matchingOwner,
                        ...clientSideObject
                    };
                } else {
                    nextOwners[clientSideKey] = clientSideObject;
                }
            }

            return {
                ...nextValues,
                owners: nextOwners,
            };
        });
    }, [organization?.owners]);

    return (
        <Flow<OrganizationSetupFormValues>
            propsToPass={{
                organization: organization,
            }}
            items={flowItems}
            onExit={onExit}
            onValuesChange={handleValuesChange}
        />
    );
};

export default AddOrganizationFlow;