import { Box, Flexbox } from "./ui";
import { Button, Title1 } from "@fluentui/react-components";
import { CheckmarkCircle32Regular, Document32Regular, Shield32Regular } from "@fluentui/react-icons";

import { routePaths } from "../router/routePaths";
import { useNavigate } from "react-router-dom";

const Highlight = ({ text, icon }: { text: string, icon: React.ReactNode }) => {
    return (
        <Flexbox
            direction="column"
            grow
            shrink={0}
            horizontalAlign="center"
            rowGap={4}
            verticalAlign="start"
            style={{
                border: "1px solid #F2F2F2",
                borderRadius: 12,
                boxSizing: "border-box",
                flexBasis: 0,
                padding: 16,
                rowGap: 16
            }}
        >
            <Flexbox
                style={{
                    aspectRatio: "1/1",
                    backgroundColor: "#EBEBEB",
                    borderRadius: 12,
                    boxSizing: "border-box",
                    padding: 12,
                }}
                horizontalAlign="center"
            >
                {icon}
            </Flexbox>
            <span style={{ textAlign: "center" }}>{text}</span>
        </Flexbox>
    )
}

export const OrganizationListEmptyState = () => {
    const navigate = useNavigate();

    const handleAddOrganizationBtnClick = () => {
        navigate(routePaths.organizations.add);
    };

    return (
        <Box>
            <Flexbox
                direction="column"
                style={{
                    boxSizing: "border-box",
                    minWidth: "fit-content",
                    width: 612,
                }}
                rowGap={32}
            >
                <Flexbox direction="column" rowGap={12} horizontalAlign="center">
                    <span>Empowering businesses with secure, transparent, and efficient credentialing solutions</span>
                    <Title1>Get verified with OneCredential</Title1>
                </Flexbox>
                <Flexbox
                    columnGap={10}
                    horizontalAlign="space-between"
                >
                    <Highlight text="Get your organization verified" icon={<Shield32Regular />} />
                    <Highlight text="Apply for credentials" icon={<CheckmarkCircle32Regular />} />
                    <Highlight text="Store your credentials in one place" icon={<Document32Regular />} />
                </Flexbox>
                <Box>
                    <Flexbox>
                        <p>Verification reassures buyers and suppliers that they are partnering with a credible and reliable organization. To proceed with credentialing, your organization needs to be verified. Once verified, you can apply for as many credentials as needed.</p>
                    </Flexbox>
                </Box>
                <Button appearance="primary" onClick={handleAddOrganizationBtnClick}>Let's start</Button>
            </Flexbox>
        </Box>
    );
};

export default OrganizationListEmptyState;