import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FlowStep } from "../../../ui/flow/FlowStep";
import { FileInput } from "../../../common";
import type { AcceptableDocument, DocumentVersion, VerificationDocument } from "../../../../client";
import { DocumentDetailCard } from "../../../common/data/DocumentDetailCard";
import { useAppDispatch, useAppSelector, useToaster } from "../../../../hooks";
import { acceptableDocumentActions, isPayloadAction, organizationActions, selectAllAcceptableDocuments } from "../../../../store";
import { Flexbox, TextButton, Title2, type FlowNavigationDirection } from "../../../ui";
import type { VerificationStepProps } from "./types";
import { ArrayUtils } from "../../../../utils";
import { isDocumentApproved, isDocumentPendingOrApproved } from "../../../../data";
import type { FormFile } from "../../../form/types";
import { AcceptableDocumentDialog } from "../../../common/modals/AccetableDocumentDialog";

export const OrganizationVerificationDocUploadFlowStep = (props: Readonly<VerificationStepProps>) => {
    const { organization, verificationRequest, onNavigate, railRef, ...wizardStepProps } = props;
    const selectedFilesRef = useRef<ReadonlyArray<FormFile>>([]);
    const [file1InProgress, setFile1InProgress] = useState(false);
    const [file2InProgress, setFile2InProgress] = useState(false);
    const [fileInputStatusText, setFileInputStatusText] = useState<string | undefined>(undefined);
    const [fileActions, setFileActions] = useState<Record<string, { inProgress: boolean }>>({});
    const dispatch = useAppDispatch();
    const toaster = useToaster();
    const [selectedAcceptableDocument, setSelectedAcceptableDocument] = useState<AcceptableDocument | undefined>();

    const acceptableDocuments = useAppSelector(selectAllAcceptableDocuments);

    const pendingOrVerifiedDocuments: ReadonlyArray<VerificationDocument> = useMemo(() => {
        return verificationRequest?.documents?.filter((doc) => isDocumentPendingOrApproved(doc)) ?? ArrayUtils.empty;
    }, [verificationRequest?.documents]);

    const submit = useCallback((files: ReadonlyArray<File>) => {
        return new Promise<ReadonlyArray<VerificationDocument | null>>((resolve, reject) => {
            if (pendingOrVerifiedDocuments.length >= 2) {
                resolve(pendingOrVerifiedDocuments);
                return;
            } else if ((pendingOrVerifiedDocuments.length + files.length) !== 2) {
                reject(new Error("Please select two files to upload."));
                return;
            } else if (!verificationRequest?.id) {
                reject(new Error("Verification request not found."));
                return;
            }

            let file1Result: VerificationDocument | null = null;
            let file2Result: VerificationDocument | null = null;

            setFile1InProgress(true);
            setFile2InProgress(true);

            const file1 = files[0];
            const file2 = files[1];

            const file1Action = dispatch(organizationActions.uploadVerificationDocument({ organizationId: organization.id, requestId: verificationRequest.id, file: file1 }));
            file1Action.then((result) => {
                if (isPayloadAction<VerificationDocument>(result)) {
                    if (result.error) {
                        toaster.push((result.error as any)?.message, { type: "error", title: `A problem occurred while uploading ${file1.name}` });
                    } else {
                        file1Result = result.payload;
                        if (file2Result) {
                            resolve([file1Result, file2Result]);
                        }
                    }
                }
            }).finally(() => setFile1InProgress(false));

            const file2Action = dispatch(organizationActions.uploadVerificationDocument({ organizationId: organization.id, requestId: verificationRequest.id, file: file2 }));
            file2Action.then((result) => {
                if (isPayloadAction<VerificationDocument>(result) && !result.error) {
                    if (result.error) {
                        toaster.push((result.error as any)?.message, { type: "error", title: `A problem occurred while uploading ${file2.name}` });
                    } else {
                        file2Result = result.payload;
                        if (file1Result) {
                            resolve([file1Result, file2Result]);
                        }
                    }
                }
            }).finally(() => setFile2InProgress(false));
        });
    }, [dispatch, organization.id, verificationRequest?.id, pendingOrVerifiedDocuments, toaster]);

    const handleFileDetailCardDelete = (_e: unknown, file: DocumentVersion) => {
        setFileActions((prev) => ({
            ...prev,
            [file.id]: { inProgress: true },
        }));

        if (!verificationRequest?.id) {
            console.warn("Verification request not found. Cannot delete document.");
            return;
        }

        const action = dispatch(organizationActions.deleteVerificationDocument({ organizationId: organization.id, requestId: verificationRequest.id, documentId: file.id }));
        action.then((result) => {
            if (isPayloadAction(result) && !result.error) {
                setFileActions((prev) => {
                    const { [file.id]: _, ...rest } = prev;
                    return rest;
                });
            }
        });
    }

    const handleStepNavigation = useCallback((option: FlowNavigationDirection) => {
        if (option === "forward") {
            const pendingFilesToUpload = selectedFilesRef.current.map(formFile => formFile.content).filter(file => file !== undefined);
            submit(pendingFilesToUpload).then(() => {
                onNavigate?.(option);
            }).catch((reason) => {
                setFileInputStatusText(reason.message);
            });
            return;
        }

        onNavigate?.(option);
    }, [submit, onNavigate]);

    const handleFileInputChange = useCallback((files: ReadonlyArray<FormFile>) => {
        selectedFilesRef.current = files;
        setFileInputStatusText(undefined);
    }, []);

    const handleAcceptableDocumentClick = useCallback((acceptableDocument: AcceptableDocument) => {
        setSelectedAcceptableDocument(acceptableDocument);
    }, []);

    useEffect(() => {
        dispatch(acceptableDocumentActions.get());
    }, [dispatch]);

    useEffect(() => {
        if (!verificationRequest?.id) {
            console.warn("Verification request not found. Navigating back to the previous step.");
            onNavigate?.("backward");
        }
    }, [verificationRequest?.id, onNavigate]);

    return (
        <FlowStep
            {...wizardStepProps}
            title="Upload verification documents"
            description="To complete verification, you will need to upload two documents to verify the authenticity of your organization. Our system will securely review them for accuracy and authenticity."
            actionsProps={{
                actionInProgress: file1InProgress || file2InProgress,
            }}
            onNavigate={handleStepNavigation}
        >
            <div>
                <Flexbox direction="column" rowGap>
                    <Title2>Please provide at least two of the following documents:</Title2>
                    <Flexbox wrap="wrap" columnGap={8} verticalAlign="center">
                        {acceptableDocuments.length > 0 && (
                            acceptableDocuments.map((doc, docIndex) => (
                                <React.Fragment key={doc.id}>
                                    <TextButton
                                        text={doc.name}
                                        underline
                                        onClick={() => handleAcceptableDocumentClick(doc)}
                                    />
                                    {docIndex < acceptableDocuments.length - 1 && <span>•</span>}
                                </React.Fragment>
                            ))
                        )}
                    </Flexbox>
                    {(pendingOrVerifiedDocuments.length < 2) && (
                        <FileInput
                            maxFiles={2 - pendingOrVerifiedDocuments.length}
                            multiple={true}
                            inProgress={[file1InProgress, file2InProgress]}
                            status={fileInputStatusText ? "error" : undefined}
                            statusText={fileInputStatusText}
                            onChange={handleFileInputChange}
                        />
                    )}
                    {pendingOrVerifiedDocuments.map((doc) => (
                        <DocumentDetailCard
                            key={doc.id}
                            actionInProgress={fileActions.hasOwnProperty(doc.id) && fileActions[doc.id].inProgress}
                            document={{
                                ...doc,
                                documentId: doc.id,
                            }}
                            onDelete={isDocumentApproved(doc) ? undefined : handleFileDetailCardDelete}
                        />
                    ))}
                </Flexbox>
                <AcceptableDocumentDialog
                    open={!!selectedAcceptableDocument}
                    acceptableDocumentId={selectedAcceptableDocument?.id}
                    onClose={() => setSelectedAcceptableDocument(undefined)}
                />
            </div>
        </FlowStep>
    );
};