import { acceptableDocumentEntityAdapter } from "./adapter";
import { createSlice, type EntityState } from "@reduxjs/toolkit";

import type { AcceptableDocument } from "../../client";
import { acceptableDocumentActions } from "./actions";

interface AcceptableDocumentListState {
    items: Array<string>;
    nextLink?: string;
}

interface AcceptableDocumentStoreState extends EntityState<AcceptableDocument, string> {
    selectedId: string | null;
    list: AcceptableDocumentListState;
}

const initialState: AcceptableDocumentStoreState = acceptableDocumentEntityAdapter.getInitialState({
    selectedId: null,
    list: {
        items: [],
    },
    selectedDocument: null,
});

export const acceptableDocumentsSlice = createSlice({
    name: "acceptableDocuments",
    initialState,
    reducers: {
        setSelectedAcceptableDocument: (state, action) => {
            state.selectedId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(acceptableDocumentActions.get.fulfilled, (state, action) => {
            acceptableDocumentEntityAdapter.upsertMany(state, action.payload.value);
            state.list.items = action.payload.value.map((document) => document.id);
            state.list.nextLink = action.payload["odata.nextLink"];
        });
    },
});



