import { Page, useModalLayout } from "../../layouts";
import { organizationActions, selectSelectedOrganization } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "../../components";
import OrganizationVerificationFlow from "../../components/organization/flows/OrganizationVerificationFlow/OrganizationVerificationFlow";
import { StringUtils } from "../../utils";
import { routePaths } from "../../router/routePaths";

export const VerifyOrganizationPage = () => {
    const { organizationId } = useParams<{ organizationId: string }>();
    const organization = useAppSelector(selectSelectedOrganization);
    const { subscribe } = useModalLayout();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleExitBtnClick = useCallback(() => {
        if (StringUtils.isNullOrWhitespace(organizationId)) {
            navigate(routePaths.dashboard);
        } else {
            navigate(routePaths.organizations.view.replace(":organizationId", organizationId));
        }
    }, [navigate, organizationId]);

    useEffect(() => {
        if (organizationId) {
            dispatch(organizationActions.setSelectedOrganization(organizationId));
            dispatch(organizationActions.get(organizationId));
        }
    }, [dispatch, organizationId]);

    useEffect(() => {
        const unsubscribe = subscribe(() => {
            handleExitBtnClick();
        });

        return unsubscribe;
    }, [handleExitBtnClick, subscribe]);

    return (
        <Page>
            <Loader loading={!organization}>
                <OrganizationVerificationFlow
                    organization={organization!}
                />
            </Loader>
        </Page>
    );
};