import { Body1, Button, Title3 } from "@fluentui/react-components";
import { useCallback, useEffect } from "react";

import { Flexbox } from "components/ui";
import { FlowStep } from "components/ui/flow/FlowStep";
import type { VerificationStepProps } from "./types";
import { organizationActions } from "store";
import routePaths from "router/routePaths";
import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router-dom";

export const OrganizationVerificationCompletedFlowStep = (props: Readonly<VerificationStepProps>) => {
    const { organization } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleGoToDetailsBtnClick = useCallback(() => {
        navigate(routePaths.organizations.view.replace(":organizationId", organization.id));
    }, [navigate, organization.id]);

    useEffect(() => {
        if (organization?.id) {
            dispatch(organizationActions.getById(organization.id));
        }
    }, [dispatch, organization?.id]);

    return (
        <FlowStep
            {...props}
            actionsProps={{
                hidePrimaryAction: true,
                hideSecondaryAction: true,
            }}
            title=""
        >
            <Flexbox direction="column" horizontalAlign="center" rowGap>
                <Title3>Your verification request has been submitted</Title3>
                <Body1>You will be contacted in 1-3 business days with next steps or requests for additional information.</Body1>
                <Button appearance="primary" onClick={handleGoToDetailsBtnClick}>Go to details</Button>
            </Flexbox>
        </FlowStep>
    )
};

export default OrganizationVerificationCompletedFlowStep;