import { Button, Flexbox, TextField } from "../../ui";
import { Dialog, DialogActions, DialogContent, DialogSurface, DialogTitle, Field } from "@fluentui/react-components";
import { isPayloadAction, organizationActions } from "../../../store";
import { useCallback, useState } from "react";

import type { DialogCloseReason } from "@components/ui/types";
import type { Organization } from "../../../client";
import { useAppDispatch } from "../../../hooks";

interface DeleteOrganizationConfirmationDialogProps {
    open: boolean;
    organization: Organization;
    onClose?: (reason: DialogCloseReason) => void;
}

export const DeleteOrganizationConfirmationDialog = (props: Readonly<DeleteOrganizationConfirmationDialogProps>) => {
    const dispatch = useAppDispatch();
    const { open, organization, onClose } = props;
    const [confirmationText, setConfirmationText] = useState("");
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const handleDeleteOrganization = useCallback(() => {
        setDeleteInProgress(true);
        const action = dispatch(organizationActions.remove(organization.id));
        action.then((result) => {
            if (isPayloadAction(result) && !result.error) {
                onClose?.("completed");
            }
        }).finally(() => {
            setDeleteInProgress(false);
        });
    }, [dispatch, onClose, organization?.id]);

    const handleTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (target) {
            const inputValue = target.value;
            setConfirmationText(inputValue);
        }
    }, []);

    const actionConfirmed = confirmationText === "DELETE";

    return (
        <Dialog
            open={open}
        >
            <DialogSurface>
                <DialogTitle>
                    Delete {organization.name ?? "organization"}
                </DialogTitle>
                <DialogContent>
                    <Flexbox direction="column" rowGap>
                        <p>This action cannot be undone. This will permanently delete {organization.name ? `the organization ${organization.name}` : "this organization"}.</p>
                        <Field label={<span>Please type <b>DELETE</b> to confirm:</span>}>
                            <TextField
                                disabled={deleteInProgress}
                                required={true}
                                onChange={handleTextFieldChange}
                            />
                        </Field>
                        <div>&nbsp;</div>
                    </Flexbox>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={deleteInProgress}
                        text="Cancel"
                        onClick={() => onClose?.("cancel")}
                    />
                    <Button
                        disabled={!actionConfirmed}
                        loading={deleteInProgress}
                        text="Delete"
                        variant="danger"
                        onClick={handleDeleteOrganization}
                    />
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};

export default DeleteOrganizationConfirmationDialog;