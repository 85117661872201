import { type ApiObjectResponse, type OrganizationVerificationRequest } from "../../models";
import RequestBuilder from "../RequestBuilder";
import { VerificationDocumentCollectionRequestBuilder } from "./VerificationDocumentCollectionRequestBuilder";

export class VerificationRequestRequestBuilder extends RequestBuilder {
    public async get(): Promise<ApiObjectResponse<OrganizationVerificationRequest>> {
        const requestUrl = this.baseUrl;
        const response = await this.client.get<OrganizationVerificationRequest>(requestUrl);
        return this.getResponseObjectOrThrow(response);
    }

    public get documents() {
        return new VerificationDocumentCollectionRequestBuilder(this._appendUrlSegment("documents"), this.client);
    }
}